import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import jwtDecode from "jwt-decode";
import { setToken, setRedirectionOnExpire } from "../../services/Staff";
import { NotFound } from "../NotFound";
import AuthContext, { AuthStatus } from "../../services/authService/context";
import { Panel } from "../Admin/Panel";
import PartnersPanel from "../Partners/Panel";
import { PrivateRoute } from "../../services/authService";
import { SignOut } from "../SignOut";
import { ForgotPassword } from "../ForgotPassword";
import { PartnersSignIn } from "../Partners/PartnersSignIn";
import { SignIn } from "../Admin/SignIn";

function useQuey() {
  return new URLSearchParams(window.location.search);
}

export const Root = () => {
  const subdomain = window.location.hostname.split(".")[0];
  const isPartners = subdomain === "partners";
  const [authStatus, setAuthStatus] = useState(AuthStatus.Uninitialized);
  const [currentUser, setUser] = useState(null);
  let query = useQuey();
  let redirect = query.get("showLandingPage");
  useEffect(() => {
    validateSession();
  }, []);

  const validateSession = () => {
    setRedirectionOnExpire();
    let token = localStorage.getItem("token");
    if (token) {
      const decoded = jwtDecode(token);
      if (Date.now() <= decoded.exp) {
        setAuthStatus(AuthStatus.Unauthenticated);
      } else {
        setAuthStatus(AuthStatus.Authenticated);
        setToken(token);
        setUser(decoded);
      }
    } else {
      setAuthStatus(AuthStatus.Unauthenticated);
    }
  };

  /*  const RenderPanel = (props) => {
    if(currentUser === null) return null;
    let { role } = currentUser;
    switch (role) {
      case 0: return <Panel />
    }
  }
 */

  const partnersRoutes = (
    <>
      <Route exact path="/" component={PartnersSignIn} />
      <Route path="/cerrar-sesion" component={SignOut} />
      <PrivateRoute
        path="/panel"
        component={PartnersPanel}
        authStatus={authStatus}
      />
    </>
  );

  const adminRoutes = (
    <>
      <Route exact path="/" component={SignIn} />
      <Route exact path="/partners" component={PartnersSignIn} />
      <Route exact path="/recuperar-contrasena" component={ForgotPassword} />
      <Route path="/cerrar-sesion" component={SignOut} />
      <PrivateRoute path="/panel" component={Panel} authStatus={authStatus} />
    </>
  );

  return (
    <div className="root">
      {authStatus === AuthStatus.Uninitialized ? (
        <div>Cargando</div>
      ) : (
        <AuthContext.Provider
          value={{
            currentUser: currentUser,
            authStatus: authStatus,
            isPartners: isPartners,
          }}
        >
          <Router>
            <Switch>
              {!redirect && authStatus === 2 ? (
                <Redirect exact from="/" to="/panel" />
              ) : null}
              {/* <Route exact path="/" component={ LandingPage }/> */}
              {isPartners ? partnersRoutes : adminRoutes}
              <Route component={NotFound} />
            </Switch>
          </Router>
        </AuthContext.Provider>
      )}
    </div>
  );
};
