import styled from "styled-components";

export const LinksModal = styled.div`
  transition: all 0.1s ease-in-out;
  width: 400px;
  position: fixed;
  bottom: 50px;
  right: 50px;
  background: #316E94;
  border-radius: 10px;
  opacity: ${({show}) => show ? '1' :  '0'};
  visibility: ${({show}) => show ? 'visible' :  'hidden'};
  transform: translateY(${({show}) => show ? '0px' :  '40px'});
  
`;
