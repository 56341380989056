import React, { useState, useEffect, useCallback, useContext } from "react";
import { GetProcedures } from "services/Procedures";
import { Panel } from "components/Panel";
import { ProceduresList } from "components/ProceduresList";
import { Padding } from "components/Padding";
import { GetOnHoldRenters } from "services/Procedures";
import Dialog from "components/Dialog";
import { CreateProcedure } from "./CreateProcedure";
import { NotificationContext } from "services/NotificationService";
import { ProceduresToolbar } from "components/ProceduresToolbar";

export const Procedures = (props) => {
  const { createNotification } = useContext(NotificationContext);
  const [showCreateProcedure, setCreateProcedure] = useState(null);
  const [procedures, setProcedures] = useState([]);
  const [proceduresFiltered, setFilterResult] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    status: 1,
    type: "",
  });
  const [loading, setLoading] = useState(true);

  const getProcedures = useCallback(async () => {
    setLoading(true);
    let res = null;
    if (filters.status === -1) {
      res = await GetOnHoldRenters();
      const pendingProcedures = res?.data?.map((p) => {
        return {
          ...p,
          uncreated: true,
          _id: p._id,
          createdAt: p?.createdAt,
          renter: {
            user: p?.user,
          },
          type: "SIN CREAR",
          status: -1,
        };
      });
      setProcedures(pendingProcedures);
      setFilterResult(pendingProcedures);
    } else {
      res = await GetProcedures(`?status=${filters.status}`);
      setProcedures(res?.data);
      setFilterResult(res?.data);
    }
    setLoading(false);
  }, [filters.status]);

  useEffect(() => {
    getProcedures();
  }, [filters.status, getProcedures]);

  useEffect(() => {
    if (filters.search.length < 3) setFilterResult(procedures);

    if (filters.search.length > 3) {
      setFilterResult(
        procedures.filter(
          (p) =>
            p.renter.user.firstName?.includes(filters.search) ||
            p.renter.user.phone?.includes(filters.search) ||
            p.renter.user.email?.includes(filters.search) ||
            p.renter.user.lastName?.includes(filters.search)
        )
      );
    }
  }, [filters, procedures]);

  return (
    <Padding padding="16px 30px">
      <div className="columns">
        <div className="column is-12">
          <Panel>
            <h1 className="title is-size-5">Investigaciones</h1>
            <ProceduresToolbar
              filters={filters}
              setFilters={setFilters}
              loading={loading}
              setLoading={setLoading}
            />
            <ProceduresList
              procedures={proceduresFiltered}
              createNewProcedure={(renter) => setCreateProcedure(renter)}
            />
          </Panel>
        </div>
        <Dialog
          isActive={showCreateProcedure != null}
          close={() => setCreateProcedure(null)}
        >
          {showCreateProcedure != null && (
            <CreateProcedure
              close={() => {
                createNotification({ message: "Investigación creada" });
                setCreateProcedure(null);
              }}
              renter={showCreateProcedure}
            />
          )}
        </Dialog>
      </div>
    </Padding>
  );
};
