/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import { getProceduresThisYear } from "services/Dashboard";
import {
  VerticalBarSeries,
  XAxis,
  YAxis,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  GradientDefs,
} from "react-vis";
import { MONTHS } from "utils/date";
export const ProceduresThisYear = () => {
  const [procedures, setProcedures] = useState([]);
  const [hoveredPoint, setHoveredPoint] = useState();
  const [isHover, setHover] = useState(false);
  const [clicsSelected, setClicsSelected] = useState([]);

  const [year, setYear] = useState("2024");

  const getProcedures = useCallback(async () => {
    const { data } = await getProceduresThisYear(year);
    const dates = [];
    for (let index = 1; index <= 12; index++) {
      dates.push({
        _id: {
          month: index,
        },
        count: 0,
      });
    }

    for (let index = 0; index <= dates.length; index++) {
      const itemIndex = data.findIndex(
        (d) => d?._id?.month === dates[index]?._id?.month
      );
      if (itemIndex !== -1) {
        dates[index] = data[itemIndex];
      }
    }

    dates.sort((a, b) => a?._id?.month - b?._id?.month);
    setProcedures(
      dates.map((p) => {
        return {
          x: `${MONTHS[p?._id?.month]}`,
          y: p?.count,
        };
      })
    );
  }, [year]);

  useEffect(() => {
    getProcedures();
  }, [year]);
  return (
    <div>
      <h1 className="title is-size-5 is-flex">
        <div className="">Investigaciones por mes del año </div>
        <div className="">
          <select
            name="year"
            id="year"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          >
            {["2022", "2023", "2024"].map((y) => (
              <option value={y} key={y}>
                {y}
              </option>
            ))}
          </select>
        </div>
      </h1>
      <FlexibleWidthXYPlot xType="ordinal" stackBy="y" height={300}>
        <GradientDefs>
          <linearGradient
            id="procedures-by-year-grandient"
            x1="0"
            x2="1"
            y1="0"
            y2="1"
          >
            <stop offset="0%" stopColor="#3bbec8" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#66d7d1" stopOpacity={0.9} />
          </linearGradient>
        </GradientDefs>
        <HorizontalGridLines style={{ strokeWidth: 1, stroke: "#66d7d140" }} />
        <XAxis
          tickFormat={(value, i) => {
            return value;
          }}
        />
        <YAxis
          tickFormat={(value, i) => {
            return value;
          }}
        />
        <VerticalBarSeries
          barWidth={0.7}
          data={procedures}
          color={"url(#procedures-by-year-grandient)"}
          onSeriesClick={(event) => {
            setClicsSelected(hoveredPoint?.clicks ?? []);
          }}
          onSeriesMouseOver={() => {
            setHover(true);
          }}
          onSeriesMouseOut={() => {
            setHover(false);
          }}
          onNearestXY={(_, { index }) => {
            const hoveredLine = procedures[index];
            setHoveredPoint(hoveredLine);
          }}
        />

        {/* {hoveredPoint && isHover && (
          <Hint value={hoveredPoint}>
            <div className="tag is-white">
              {hoveredPoint.y}
            </div>
          </Hint>
        )} */}
      </FlexibleWidthXYPlot>
    </div>
  );
};
