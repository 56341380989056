import React from 'react'
import Dialog from '../Dialog';

export const ConfirmationDialogContext = React.createContext();

export const ConfirmationDialog = ({ title, description, agreeFunction, close}) => {
  return (
    <Dialog isActive={true} close={null}>
       <div className="notification is-white" style={{minWidth:'500px'}}>
        <p className="title is-3 has-text-centered">
          { title }
        </p>
        <br/>
        <div className="notification is-white has-text-centered py-0">
        { description }
        </div>
        <br/>
          <div className="has-text-right">
            <button className="button is-light" onClick={close}>Cancelar</button>
            &nbsp;
            <button className="button is-primary" onClick={() => {
              agreeFunction.call();
              close();
            }}>Aceptar</button>
          </div>
      </div>
    </Dialog>
  )
}
