import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FIELDS_TEMPLATES, FIELD_TYPES, reorderItems } from "../utils";
import { DropwDownMenu } from "components/DropDownMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa3,
  faCalendar,
  faCircleDot,
  faFile,
  faHandPointer,
  faObjectGroup,
} from "@fortawesome/free-solid-svg-icons";
import { TextField } from "../InputForms/textField";

export const FormSection = ({ selectedSection }) => {
  const [fields, setFields] = useState([]);
  const [groups, setGroups] = useState([
    {
      id: "group_1",
      fields: [],
    },
    {
      id: "group_2",
      fields: [],
    },
  ]);
  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderItems(
      fields,
      result.source.index,
      result.destination.index
    );

    setFields(items);
  };

  const addField = (fieldType) => {
    const fieldTemplate = FIELDS_TEMPLATES[fieldType];
    setFields([
      ...fields,
      {
        ...fieldTemplate,
        id: `${fieldTemplate?.id}_${fields.length + 1}`,
        draggableId: `${Date.now()}`,
      },
    ]);
  };

  const updateFieldData = (newFieldData, index) => {
    const fieldsCopy = fields;
    fieldsCopy[index] = newFieldData;
    setFields(fieldsCopy);
  };
  const renderField = (
    item,
    index,
    dragHandleProps,
    isDragging,
    providedStyle,
    innerRef,
    draggableProps
  ) => {
    const updateFieldDataClosure = (fieldData) =>
      updateFieldData(fieldData, index);

    switch (item.type) {
      case FIELD_TYPES.TEXT:
        return (
          <TextField
            field={item}
            dragHandleProps={dragHandleProps}
            innerRef={innerRef}
            isDragging={isDragging}
            providedStyle={providedStyle}
            draggableProps={draggableProps}
            updateField={updateFieldDataClosure}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div>
      <>
        <h1 className="title is-size-4">{selectedSection?.name}</h1>
        <div className="navbar-end">
          <button className="button is-primary ml-2 is-small">
            <span>Agregar grupo</span>
            <span className="icon">
              <FontAwesomeIcon icon={faObjectGroup} />
            </span>
          </button>
          <DropwDownMenu
            buttonText="Agregar Campo"
            buttonClass="is-primary ml-2 is-small"
            alignment="is-right"
            icon={
              <span className="icon">
                <i className="fas fa-th-list"></i>
              </span>
            }
          >
            <a
              href="#!"
              onClick={() => addField("text")}
              className="dropdown-item"
            >
              <span className="icon">
                <i className="fas fa-font"></i>
              </span>
              <span>Texto</span>
            </a>
            <a
              href="#!"
              onClick={() => addField("number")}
              className="dropdown-item"
            >
              <span className="icon">
                <FontAwesomeIcon icon={fa3} />
              </span>
              <span>Número</span>
            </a>
            <a href="#!" onClick={() => addField("")} className="dropdown-item">
              <span className="icon">
                <FontAwesomeIcon icon={faHandPointer} />
              </span>
              <span>Lista de Selección</span>
            </a>
            <a href="#!" onClick={() => addField("")} className="dropdown-item">
              <span className="icon">
                <FontAwesomeIcon icon={faCircleDot} />
              </span>
              <span>Selección Múltiple</span>
            </a>
            <a href="#!" onClick={() => addField("")} className="dropdown-item">
              <span className="icon">
                <FontAwesomeIcon icon={faCalendar} />
              </span>
              <span>Fecha</span>
            </a>
            <a href="#!" onClick={() => addField("")} className="dropdown-item">
              <span className="icon">
                <FontAwesomeIcon icon={faFile} />
              </span>
              <span>Archivos</span>
            </a>
          </DropwDownMenu>
        </div>
        <div className="mt-4">
          {/* 
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((item, index) => (
                    <Draggable
                      key={item.draggableId}
                      draggableId={item.draggableId}
                      index={index}
                    >
                      {(provided, snapshot) =>
                        renderField(
                          item,
                          index,
                          provided.dragHandleProps,
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          provided.innerRef,
                          provided.draggableProps
                        )
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext> */}

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {fields.map((item, index) => (
                    <Draggable
                      key={item.draggableId}
                      draggableId={item.draggableId}
                      index={index}
                    >
                      {(provided, snapshot) =>
                        renderField(
                          item,
                          index,
                          provided.dragHandleProps,
                          snapshot.isDragging,
                          provided.draggableProps.style,
                          provided.innerRef,
                          provided.draggableProps
                        )
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </>
    </div>
  );
};
