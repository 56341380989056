import React, { useState, useEffect, useRef, useContext } from "react";
import { NotificationContext } from "../../services/NotificationService";
import {
  AddReferenceComment,
  InsertCallHistory,
  UpdateReference,
} from "../../services/Procedures";
import { CallHistoryList } from "../CallHistoryList";
import Dialog from "../Dialog";
import { InfoItem } from "../InfoItem";
import { InvestigationContext } from "../ProcedureDetail";
import { RegisterCallForm } from "../RegisterCallForm";

export const PersonalReferenceItem = ({
  reference,
  item,
  updateReferenceData,
}) => {
  const { updatePersonalReference, renter } = useContext(InvestigationContext);
  const [comments, setComments] = useState(
    reference?.comments ?? "Sin comentarios"
  );
  const [loading, setLoading] = useState();
  const [updatingComments, setUpdatingComments] = useState(false);
  const [time, setTime] = useState(0);
  const [onCall, Call] = useState(false);
  const [startAt, setStartAt] = useState(null);

  const [history, setHistory] = useState(reference.history ?? []);
  const { createNotification } = useContext(NotificationContext);

  const [registerCall, setRegisterCall] = useState(false);

  const countRef = useRef(time);
  countRef.current = time;

  useEffect(() => {
    let interval;
    if (onCall) {
      interval = setInterval(() => {
        setTime((currCount) => currCount + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [onCall]);

  const call = () => {
    Call(true);
    setStartAt(Date.now());
  };

  const stopCall = (callData) => {
    setRegisterCall(false);
    addToHistory(callData);
    Call(false);
    setTime(0);
  };

  const addToHistory = async (callData) => {
    let itemHistoy = {
      duration: time,
      startAt,
      notes: "",
      ...callData,
    };

    await InsertCallHistory(reference._id, itemHistoy);
    setHistory((prev) => prev.concat(itemHistoy));

    let newHistory = history;
    newHistory.push(itemHistoy);

    setTimeout(
      () => updatePersonalReference({ ...reference, history: newHistory }),
      1000
    );
  };

  const removeCall = (call) => {
    const newHistory = history;
    const index = newHistory.map((c) => c.id).indexOf(call.id);
    newHistory.splice(index, 1);
    setHistory(newHistory);
  };

  const updateComments = async () => {
    setUpdatingComments(true);
    const { data } = await AddReferenceComment(reference._id, comments);
    createNotification({
      message: data,
    });
    setUpdatingComments(false);
  };

  const updateField = async (field, value) => {
    if (typeof value !== Boolean && !value) return;
    setLoading(field);
    await UpdateReference(reference._id, {
      [field]: value,
    });
    updateReferenceData(reference, parseInt(item.split(" ")[1] - 1));
    setLoading("");
  };

  return (
    <div>
      <div className="notification">
        <p className="has-text-weight-bold is-size-5">{item}</p>
        <br />
        <div className="columns is-multiline">
          <div className="column is-4">
            <InfoItem
              field="Nombre"
              value={reference.name}
              disabled={false}
              onBlur={(e) => updateField("name", e.target.value)}
            />
            <InfoItem
              field="Teléfono"
              value={reference.phone}
              disabled={false}
              onBlur={(e) => updateField("phone", e.target.value)}
            />
            <InfoItem
              field="Relación"
              value={reference.relationship}
              disabled={false}
              onBlur={(e) => updateField("relationship", e.target.value)}
            />
            <InfoItem
              field="Correo electrónico"
              value={reference.email}
              disabled={false}
              onBlur={(e) => updateField("email", e.target.value)}
            />
            <InfoItem
              field="Ocupación"
              value={reference.job}
              disabled={false}
              onBlur={(e) => updateField("job", e.target.value)}
            />
          </div>
          <div className="column">
            <p className="has-text-weight-bold">Historial de llamadas</p>
            <div className="navbar is-transparent">
              <div className="navbar-start">
                <div className="navbar-item">
                  <p className="">
                    Para registrar una llamada da click en el botón "Registrar
                    llamada"
                  </p>
                </div>
              </div>
              <div className="navbar-end">
                <div className="buttons">
                  <button
                    className={`button is-primary`}
                    onClick={() => setRegisterCall(true)}
                  >
                    Registrar llamada
                  </button>
                </div>
              </div>
            </div>

            {history.length !== 0 && (
              <div className="notification is-white">
                <CallHistoryList
                  calls={history}
                  reference={reference._id}
                  remove={removeCall}
                />
              </div>
            )}
            <p className="has-text-weight-bold">Comentarios</p>
            <textarea
              className={`textarea ${updatingComments ? "is-loading" : ""}`}
              maxLength={50}
              value={comments}
              onChange={(e) => setComments(e.target.value)}
              onBlur={updateComments}
            ></textarea>
          </div>
        </div>
        <br />

        <Dialog isActive={registerCall} close={() => setRegisterCall(false)}>
          <RegisterCallForm
            reference={reference}
            call={call}
            onCall={onCall}
            stopCall={stopCall}
            time={time}
            renter={renter}
          />
        </Dialog>
      </div>
      <br />
    </div>
  );
};
