import React, { useState } from "react";
import { GetFileURL } from "services/Procedures";

export const FilesExplorerModal = ({ file }) => {
  const [loading, setLoading] = useState(false);
  const getsignedUrl = async (key) => {
    setLoading(true);
    const { data } = await GetFileURL({
      file: key,
    });
    window.open(data);
    setLoading(false);
  };

  return (
    <div className="notification" style={{ width: "500px" }}>
      <h1 className="title is-5">Documentos disponbles para: {file?.title}</h1>
      <hr />
      <div className="columns">
        {file?.files?.map((file, index) => {
          return (
            <button
              key={index}
              onClick={() => getsignedUrl(file)}
              className={`column is-4 box is-shadowless has-background-link is-flex is-flex-direction-column is-align-items-center is-borderless as-cursor ${
                loading ? "is-loading" : ""
              }`}
            >
              <span>
                <i className="fas fa-file-pdf"></i>
              </span>
              <span className="label">Archivo {(index = 1)}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};
