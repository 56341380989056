import React, { useContext, useEffect, useState } from "react";
import { Panel } from "../../../../../components/Panel";
import Dialog from "../../../../../components/Dialog";
import { AddRealEstateForm } from "../../../../../components/AddRealEstateForm";
import { GetRealEstate } from "../../../../../services/Admin";
import useCopyToClipboard from "../../../../../hooks/useClipBoard";
import { NotificationContext } from "../../../../../services/NotificationService";

export const RealEstate = () => {
  // eslint-disable-next-line no-unused-vars
  const [value, copy] = useCopyToClipboard();
  const { createNotification } = useContext(NotificationContext);

  const [showNewForm, setShowNewForm] = useState(false);
  const [selectedRealEstate, setSelectedRealEstate] = useState(null);
  const [realEstates, setRealEstates] = useState([]);

  const getRealEstate = async () => {
    const { data } = await GetRealEstate();
    setRealEstates(data);
    setShowNewForm(false);
  };

  useEffect(() => {
    getRealEstate();
  }, []);

  return (
    <Panel>
      <div className="navbar">
        <div className="navbar-start">
          <p className="is-size-5 has-text-weight-bold">
            Agentes inmobiliarios asociados
          </p>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <button
                className="button is-primary is-small"
                onClick={() => {
                  setShowNewForm(true);
                }}
              >
                <span>Registrar socio</span>
                <span className="icon">
                  <i className="fa fa-plus"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <table className="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th>Nombre/Razón Social</th>
            <th></th>
            <th>RFC</th>
            <th>Correo</th>
            <th>Teléfono</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {realEstates.map((realEstate) => (
            <tr key={realEstate._id}>
              <td>{realEstate.name}</td>
              <td>{realEstate.businessName}</td>
              <td>{realEstate.rfc}</td>
              <td>{realEstate.phone}</td>
              <td>{realEstate.email}</td>
              <td>
                <p className="buttons">
                  <button
                    className="button is-link"
                    onClick={() => {
                      setSelectedRealEstate(realEstate);
                      setShowNewForm(true);
                    }}
                  >
                    <span className="icon">
                      <i className="fa fa-eye"></i>
                    </span>
                  </button>
                  <button
                    className="button is-info"
                    onClick={() => {
                      copy(
                        `https://invix.mx/crear-cuenta?reference=${realEstate._id}`
                      );
                      createNotification({
                        message: "Link copiado al portapeles",
                      });
                    }}
                  >
                    <span className="icon">
                      <i className="fa fa-link"></i>
                    </span>
                  </button>
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Dialog
        isActive={showNewForm}
        close={() => {
          setShowNewForm(false);
        }}
        width="700px"
      >
        {showNewForm && (
          <AddRealEstateForm
            close={() => {
              createNotification({
                message: "Inmobiliaria guardada correctamente",
              });
              getRealEstate();
            }}
            realEstate={selectedRealEstate}
          />
        )}
      </Dialog>
    </Panel>
  );
};
