import React, { useState } from 'react'
import { AddIssue } from '../../services/Procedures';

const sections = [
  {
    value: 'generalData',
    label: 'Información General',
    fields: [
      {
        name: 'INE',
        value: 'ineFile',
      },
      {
        name: 'Comprobante de domicilio',
        value: 'domFile',
      },      
      {
        name: 'Acta constitutiva',
        value: 'actFile',
      },
      {
        name: 'Cédula fiscal',
        value: 'fiscalFile',
      },
      {
        name: 'Carta poder',
        value: 'powerFile',
      },
      {
        name: 'Primer Nombre',
        value: 'firstName',
      },
      {
        name: 'Segundo Nombre',
        value: 'secondName',
      },
      {
        name: 'Apellido Paterno',
        value: 'lastName1',
      },
      {
        name: 'Apellido Materno',
        value: 'lastName2',
      },
      {
        name: 'Fecha de nacimiento',
        value: 'birthdate',
      },
      {
        name: 'Lugar de nacimiento',
        value: 'birthPlace',
      },
      {
        name: 'Domicilio',
        value: 'address',
      },
      {
        name: 'RFC',
        value: 'rfc',
      },
      {
        name: 'CURP',
        value: 'curp',
      },
      {
        name: 'Nacionalidad',
        value: 'nationality',
      },
      {
        name: 'Estado civil',
        value: 'maritialStatus',
      },
      {
        name: 'Nombre del cónyuge',
        value: 'spouseName',
      },
      {
        name: 'Teléfono del cónyuge',
        value: 'spousePhone',
      },
      {
        name: 'Estado del domicilio actual',
        value: 'houseStatus',
      },
      {
        name: 'Estado del domicilio actual (Especifíque)',
        value: 'otherStatus',
      },
      {
        name: 'Monto de renta',
        value: 'rentAmmount',
      },
      {
        name: 'Tiempo en el domicilio',
        value: 'timeInHouse',
      },
      {
        name: 'Nombre del dueño',
        value: 'houseOwner',
      },
      {
        name: 'Contacto del dueño',
        value: 'houseContact',
      },
      {
        name: 'Razón social',
        value: 'businessName',
      },
      {
        name: 'Nacionalidad',
        value: 'businessNationality',
      }
    ]
  },
  {
    value: 'economyActivity',
    label: 'Actividad Económica',
    fields: [
      {
        name: 'Comprobante de ingresos',
        value: 'entryFile',
      },
      {
        name: 'Fuente principal de ingresos',
        value: 'mainEntry',
      },
      {
        name: 'Fuente de trabajo',
        value: 'workSource',
      },
      {
        name: 'Descripción del trabajo',
        value: 'businessDesc',
      },
      {
        name: 'Fuente de pensión',
        value: 'pensionSource',
      },
      {
        name: 'Fuente principal de ingresos(Otro)',
        value: 'other',
      },
      {
        name: 'Tiempo en el trabajo',
        value: 'timeInJob',
      },
      {
        name: 'domicilio del trabajo',
        value: 'address',
      },
      {
        name: 'Teléfono del trabajo',
        value: 'jobPhone',
      },
      {
        name: 'Sitio web',
        value: 'website',
      },
      {
        name: 'Ingreso mensual neto',
        value: 'mensualEntry',
      },
      {
        name: 'Otros ingresos',
        value: 'otherEntry',
      },
      {
        name: 'Nombre de otro ingreso',
        value: 'otherEntryName',
      },
      {
        name: 'Monto de otros ingresos',
        value: 'otherEntryAmmount',
      },
      {
        name: 'Total de ingresos',
        value: 'totalMonthEntry',
      },
      {
        name: 'Gastos del mes',
        value: 'totalMonthExpenses',
      },
      {
        name: 'Actividad principal',
        value: 'mainActivity',
      }
    ]
  },
  {
    value: 'destinationProperty',
    label: 'Destino del inmuble',
    fields: [
      {
        name: 'Uso del inmueble',
        value: 'usage',
      },
      {
        name: 'Total de habitantes',
        value: 'totalPeople',
      },
      {
        name: 'Niños',
        value: 'children',
      },
      {
        name: 'Adultos',
        value: 'adults',
      },
      {
        name: 'Adulto mayor',
        value: 'olderAdults',
      },
      {
        name: 'Tiene mascotas',
        value: 'hasPets',
      },
      {
        name: 'Tipo de mascota',
        value: 'petType',
      },
      {
        name: 'Nombre de quién habitará el inmueble',
        value: 'habitationalName',
      }
    ]
  },
  {
    value: 'personalReference',
    label: 'Referencias personales',
    fields: [
      {
        name: 'Nombre',
        value: 'name',
      },
      {
        name: 'Teléfono',
        value: 'phone',
      },
      {
        name: 'Ocupación o giroono',
        value: 'job',
      },
      {
        name: 'Relación',
        value: 'relationship',
      },
      {
        name: 'Correo electrónico',
        value: 'email',
      },
    ]
  },
  {
    value: 'guarantorData',
    label: 'Datos del fiador',
    fields: [
      {
        name: 'INE',
        value: 'ineFile',
      },
      {
        name: 'Nombre',
        value: 'name',
      },
      {
        name: 'Lugar de nacimiento',
        value: 'birthPlace',
      },
      {
        name: 'Fecha de nacimiento',
        value: 'birthdate',
      },
      {
        name: 'RFC',
        value: 'rfc',
      },
      {
        name: 'CURP',
        value: 'curp',
      },
      {
        name: 'Nacionalidad',
        value: 'nationality',
      },
      {
        name: 'Estado civil',
        value: 'maritialStatus',
      },
      {
        name: 'Trabajo',
        value: 'job',
      },
      {
        name: 'Nombre del cónyuge',
        value: 'spouseName',
      },
      {
        name: 'Teléfono del cónyuge',
        value: 'spousePhone',
      },
      {
        name: 'Correo electrónico',
        value: 'email',
      },
      {
        name: 'Teléfono',
        value: 'phone',
      },
      {
        name: 'Dirección',
        value: 'address',
      },
      {
        name: 'Razón social',
        value: 'businessName',
      },
      {
        name: 'RFC (Empresa)',
        value: 'businessRFC',
      },
      {
        name: 'Dirección de la empresa',
        value: 'businessAddress',
      },
      {
        name: 'Nacionalidad de la empresa',
        value: 'businessNationality',
      },
      {
        name: 'Teléfono de la empresa',
        value: 'businessPhone',
      }
    ]
  },
  {
    value: 'guarantorProperty',
    label: 'Propiedad del fiador',
    fields: [
      {
        name: 'Domicilio',
        value: 'address',
      },
      {
        name: 'Reporta gravamen?',
        value: 'assessment',
      },
      {
        name: 'Tipo de gravamen',
        value: 'assessmentName',
      }
    ]
  }
]

export const AddIssueForm = ({ close, procedure }) => {
  const [desc, setDesc] = useState('')
  const [loading, setLoading] = useState('')
  const [selectedSection, selectSection] = useState('')
  const [selectedField, selectField] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading('is-loading')
    await AddIssue({
      procedure: procedure._id,
      section: selectedSection,
      field: selectedField,
      description: desc,
    })
    setLoading('is-false');
    setDesc('');
    selectSection('');
    selectField('');
    close();
  }

  return (
    <div className="notification is-primary">
      <div className="navbar is-transparent">
        <div className="navbar-start">
          <p className="subtitle has-text-white">
            Añadir observación
          </p>
        </div>
        <div className="navbar-end">
          <button className='button is-circle is-primary' data-tooltip="Cerrar" onClick={close}>
            <span className="icon">
              <i className="fa fa-times"></i>                
            </span>
          </button>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
      <label className="label has-text-white">Sección</label>
      <div className="select is-fullwidth">
        <select value={selectedSection} onChange={ ({ target: { value } }) => { 
          selectSection(value)
         }}>
           <option value="" disabled>Selecciona una sección</option>
         {
          sections.map( s => <option key={s.value} value={s.value}>{ s.label }</option>)
         }
        </select>
      </div>
      <br/>
      <label className="label has-text-white">Campo</label>
      {
        selectedSection !== '' ?
        <div className="select is-fullwidth">
          <select value={selectedField} onChange={ ({ target: { value } }) => { selectField(value) }}>
           <option value="" disabled>Selecciona una campo</option>
          {
            sections.find((s => s.value === selectedSection)).fields.map( s => <option key={s.value} value={s.value}>{ s.name }</option>)
          }
          </select>
        </div>
        : null
      }
      <br/>
        <div className="field">
          <label className="label has-text-white">Description</label>
          <div className="control">
            <input className="input " type="text" placeholder="Descripción de la observación" value={desc} onChange={(e) => setDesc(e.target.value)}/>
          </div>
        </div>
        <div className="has-text-right">
          <button className={`button is-white has-text-primary ${loading}`} type="submit">
            <span>Agregar observación</span>
            <span className="icon">
              <i className="far fa-paper-plane"></i>
            </span>
          </button>
        </div>
      </form>
    </div>
  )
}
