import moment from "moment";
import React, { useContext } from "react";
import { InvestigationContext } from "..";
import { InfoItem } from "../../InfoItem";
import { StageNavbar } from "../../StageNavbar";

export const GuarantorData = ({
  guarantorData,
  isCollapsed = false,
  collapse,
}) => {
  const { openFile, attachFile, openValidateForm } =
    useContext(InvestigationContext);

  return (
    <div>
      <StageNavbar>
        <p
          className="is-size-5 has-text-weight-bold as-cursor no-selectable"
          onClick={() => collapse(!isCollapsed)}
        >
          <span className="icon">
            {isCollapsed ? (
              <i className="fas fa-caret-right"></i>
            ) : (
              <i className="fas fa-caret-down"></i>
            )}
          </span>
          <span>
            Datos del fiador{" "}
            {guarantorData.fulfillment != null ? (
              <span>
                <span
                  className={`icon is-rounded mr-3 ${
                    guarantorData.fulfillment === true
                      ? "has-text-success"
                      : "has-text-is-success "
                  }`}
                >
                  {guarantorData.fulfillment === true ? (
                    <i className="fas fa-check"></i>
                  ) : (
                    <i className="fas fa-check"></i>
                  )}
                </span>
              </span>
            ) : (
              ""
            )}
          </span>
        </p>
        {
          // VALIDATE BUTTON
          !isCollapsed && (
            <div className="actions">
              <button
                className="button is-light has-text-weight-bold"
                onClick={() => openValidateForm("guarantorData")}
              >
                <span>Validar</span>
                <span className="icon">
                  <i className="fas fa-check"></i>
                </span>
              </button>
            </div>
          )
        }
      </StageNavbar>
      <br />
      {!isCollapsed && (
        <div>
          <p className="has-text-weight-bold">Documentos:</p>
          <br />
          <div className="tags is-sticky">
            <span
              className="tag is-primary is-rounded is-medium has-text-white is-pointer"
              onClick={() => {
                openFile(guarantorData.ineFile);
              }}
              onDoubleClick={() => {
                attachFile("guarantorData", "ineFile");
              }}
            >
              <span className="icon">
                <i className="fa fa-id-card"></i>
              </span>
              <span>INE</span>
            </span>

            <span
              className={`tag ${
                (guarantorData?.curpFile?.length ?? 0) !== 0
                  ? "is-info"
                  : "is-danger"
              } is-rounded is-medium has-text-white is-pointer`}
              onClick={() => {
                if ((guarantorData?.curpFile?.length ?? 0) !== 0) {
                  openFile(guarantorData?.curpFile);
                } else {
                  attachFile("guarantorData", "curpFile");
                }
              }}
              onDoubleClick={() => {
                attachFile("guarantorData", "curpFile");
              }}
            >
              <span className="icon">
                {(guarantorData?.curpValidationFile?.length ?? 0) !== 0 ? (
                  <i className="fas fa-check-circle"></i>
                ) : (
                  <i className="fas fa-cloud-upload-alt"></i>
                )}
              </span>
              <span>CURP</span>
            </span>

            <span
              className="tag is-primary is-rounded is-medium has-text-white is-pointer"
              onClick={() => {
                openFile(guarantorData.domFile);
              }}
              onDoubleClick={() => {
                attachFile("guarantorData", "domFile");
              }}
            >
              <span className="icon">
                <i className="fas fa-file-invoice"></i>
              </span>
              <span>Comprobante de domicilio</span>
            </span>

            <span
              className={`tag ${
                guarantorData.ineValidationFile.length !== 0
                  ? "is-info"
                  : "is-danger"
              } is-rounded is-medium has-text-white is-pointer`}
              onClick={() => {
                if (guarantorData.ineValidationFile.length !== 0) {
                  openFile(guarantorData.ineValidationFile);
                } else {
                  attachFile("guarantorData", "ineValidationFile");
                }
              }}
              onDoubleClick={() => {
                attachFile("guarantorData", "ineValidationFile");
              }}
            >
              <span className="icon">
                {guarantorData.ineValidationFile.length !== 0 ? (
                  <i className="fas fa-check-circle"></i>
                ) : (
                  <i className="fas fa-cloud-upload-alt"></i>
                )}
              </span>
              <span>Validación INE</span>
            </span>
            <span
              className={`tag ${
                (guarantorData?.curpValidationFile?.length ?? 0) !== 0
                  ? "is-info"
                  : "is-danger"
              } is-rounded is-medium has-text-white is-pointer`}
              onClick={() => {
                if ((guarantorData?.curpValidationFile?.length ?? 0) !== 0) {
                  openFile(guarantorData?.curpValidationFile);
                } else {
                  attachFile("guarantorData", "curpValidationFile");
                }
              }}
              onDoubleClick={() => {
                attachFile("guarantorData", "curpValidationFile");
              }}
            >
              <span className="icon">
                {(guarantorData?.curpValidationFile?.length ?? 0) !== 0 ? (
                  <i className="fas fa-check-circle"></i>
                ) : (
                  <i className="fas fa-cloud-upload-alt"></i>
                )}
              </span>
              <span>Validación CURP</span>
            </span>
            {guarantorData?.isBusiness && (
              <>
                <span
                  className={`tag ${
                    (guarantorData?.actFile?.length ?? 0) !== 0
                      ? "is-info"
                      : "is-danger"
                  } is-rounded is-medium has-text-white is-pointer`}
                  onClick={() => {
                    if ((guarantorData?.actFile?.length ?? 0) !== 0) {
                      openFile(guarantorData?.actFile);
                    } else {
                      attachFile("guarantorData", "actFile");
                    }
                  }}
                  onDoubleClick={() => {
                    attachFile("guarantorData", "actFile");
                  }}
                >
                  <span className="icon">
                    {(guarantorData?.actFile?.length ?? 0) !== 0 ? (
                      <i className="fas fa-check-circle"></i>
                    ) : (
                      <i className="fas fa-cloud-upload-alt"></i>
                    )}
                  </span>
                  <span>Acta constitutiva</span>
                </span>

                <span
                  className={`tag ${
                    (guarantorData?.powerFile?.length ?? 0) !== 0
                      ? "is-info"
                      : "is-danger"
                  } is-rounded is-medium has-text-white is-pointer`}
                  onClick={() => {
                    if ((guarantorData?.powerFile?.length ?? 0) !== 0) {
                      openFile(guarantorData?.powerFile);
                    } else {
                      attachFile("guarantorData", "powerFile");
                    }
                  }}
                  onDoubleClick={() => {
                    attachFile("guarantorData", "powerFile");
                  }}
                >
                  <span className="icon">
                    {(guarantorData?.powerFile?.length ?? 0) !== 0 ? (
                      <i className="fas fa-check-circle"></i>
                    ) : (
                      <i className="fas fa-cloud-upload-alt"></i>
                    )}
                  </span>
                  <span>Subir Poder del representante</span>
                </span>

                {guarantorData.powerFile?.map((f, i) => (
                  <span
                    key={i}
                    className="tag is-link is-rounded is-medium has-text-white is-pointer"
                    onClick={() => {
                      openFile([f]);
                    }}
                  >
                    <span className="icon">
                      <i className="fa fa-file-alt"></i>
                    </span>
                    <span>Poder del representante: {i + 1} </span>
                  </span>
                ))}

                <span
                  className={`tag ${
                    (guarantorData?.fiscalFile?.length ?? 0) !== 0
                      ? "is-info"
                      : "is-danger"
                  } is-rounded is-medium has-text-white is-pointer`}
                  onClick={() => {
                    if ((guarantorData?.fiscalFile?.length ?? 0) !== 0) {
                      openFile(guarantorData?.fiscalFile);
                    } else {
                      attachFile("guarantorData", "fiscalFile");
                    }
                  }}
                  onDoubleClick={() => {
                    attachFile("guarantorData", "fiscalFile");
                  }}
                >
                  <span className="icon">
                    {(guarantorData?.fiscalFile?.length ?? 0) !== 0 ? (
                      <i className="fas fa-check-circle"></i>
                    ) : (
                      <i className="fas fa-cloud-upload-alt"></i>
                    )}
                  </span>
                  <span>Cédula fiscal</span>
                </span>
              </>
            )}
          </div>
          {guarantorData.isBusiness ? (
            <div>
              <p className="has-text-weight-bold">Datos de la empresa</p>
              <br />
              <InfoItem
                field="Razón social"
                value={guarantorData.businessName}
              />
              <InfoItem field="RFC" value={guarantorData.businessRFC} />
              <InfoItem
                field="Dirección"
                value={guarantorData.businessAddress}
              />
              <InfoItem
                field="Nacionalidad"
                value={guarantorData.businessNationality}
              />
              <InfoItem field="Teléfono" value={guarantorData.businessPhone} />
              <p className="has-text-weight-bold">
                Datos del representante legal
              </p>
              <br />
              <InfoItem field="Nombre" value={guarantorData.name} />
              <InfoItem
                field="Nacionalidad"
                value={guarantorData.nationality}
              />
              <InfoItem field="CURP/ID" value={guarantorData.curp} />
              <InfoItem field="RFC" value={guarantorData.rfc} />
              <InfoItem
                field="Fecha de nacimiento"
                value={moment(guarantorData.birthdate).format("DD MMMM YYYY")}
              />
              <InfoItem
                field="Lugar de nacimiento"
                value={guarantorData.birthPlace}
              />
              <InfoItem field="Domicilio" value={guarantorData.address} />
              <InfoItem field="Teléfono" value={guarantorData.phone} />
            </div>
          ) : (
            <div>
              <InfoItem field="Nombre" value={guarantorData.name} />
              <InfoItem
                field="Nacionalidad"
                value={guarantorData.nationality}
              />
              <InfoItem field="CURP/ID" value={guarantorData.curp} />
              <InfoItem field="RFC" value={guarantorData.rfc} />
              <InfoItem
                field="Fecha de nacimiento"
                value={moment(guarantorData.birthdate).format("DD MMMM YYYY")}
              />
              <InfoItem
                field="Lugar de nacimiento"
                value={guarantorData.birthPlace}
              />
              <InfoItem field="Domicilio" value={guarantorData.address} />
              <InfoItem field="Teléfono" value={guarantorData.phone} />
              <InfoItem
                field="Correo electrónico"
                value={guarantorData.email}
              />
              <InfoItem
                field="Estado civil"
                value={guarantorData.maritialStatus}
              />
              <InfoItem field="Cónyuge" value={guarantorData.spouseName} />
              <InfoItem
                field="Teléfono del cónyuge"
                value={guarantorData.spousePhone}
              />
            </div>
          )}
          <br />
          <hr />
        </div>
      )}
    </div>
  );
};
