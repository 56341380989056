import React, { useCallback, useEffect, useState } from "react";
import { GetRealEstateById } from "../../services/Admin";

export const RealEstateTag = ({ id }) => {
  const [realEstateName, setRealEstateName] = useState("");

  const getRealEstateData = useCallback(async () => {
    const { data } = await GetRealEstateById(id);
    setRealEstateName(data.name);
  }, [id]);

  useEffect(() => {
    getRealEstateData();
  }, [getRealEstateData]);

  return <span className="tag is-warning mr-2">{realEstateName}</span>;
};
