import React, { useEffect, useState, useContext, createContext } from "react";
import Moment from "react-moment";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { UpdateField } from "services/Procedures";
import {
  GetProcedureData,
  UploadFile,
  ValidateStage,
  FinishProcedure,
  UpdateProcedure,
  AddTag,
  DeleteTag,
} from "../../services/Procedures";
import { GetTags } from "../../services/Admin";
import { AuthContext } from "../../services/authService/context";
import { Padding } from "../Padding";
import { Panel } from "../Panel";
import { AddIssueModal } from "../AddIssueModal";
import { FloatingDetailActions } from "../DetailActions";
import { LoaderItem } from "../Loader";
import { AddIssueForm } from "../AddIssueForm";
import { GeneralData } from "./GeneralData";
import { EconomyActivity } from "./EconomyActivity";
import { DestinationProperty } from "./DestinationProperty";
import { PersonalReferences } from "./PersonalReferences";
import { GuarantorData } from "./GuarantorData";
import { GuarantorProperty } from "./GuarantorProperty";
import { RegisterCallDetail } from "../RegisterCallDetail";
import { OtherFiles } from "./OtherFiles";
import Dialog from "../Dialog";
import FileViewer from "../FileViewer";
import Issues from "../Issues";
import { NotificationContext } from "../../services/NotificationService";
import { ConfirmationDialogContext } from "../ConfirmationDialog";
import { LinksModal } from "../LinksModal";
import { RealEstateTag } from "../RealEstasteTag";
import { PROCEDURE_TYPES } from "../../constants/procedure";

export const InvestigationContext = createContext({
  renter: null,
  updatePersonalReference: null,
  openCallHistoryItem: null,
});

const tabs = {
  generalInformation: true,
  economyActivity: true,
  destinationProperty: true,
  personalReferences: true,
  guarandorData: true,
  guarantorProperty: true,
  review: false,
};

const Detail = styled.div`
  nav.navbar {
    min-height: 40px !important;
  }
`;

export function ProcedureDetail() {
  const { currentUser } = useContext(AuthContext);
  const { showConfirmationDialog } = useContext(ConfirmationDialogContext);
  const { createNotification } = useContext(NotificationContext);
  const [procedureData, setData] = useState({});
  const [tags, setTags] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [addIssue, setAddIssue] = useState(false);
  const [stages, setSatege] = useState(tabs);
  const [addIssueModal, setAddIssueModal] = useState(false);
  const [showLinks, setShowLinks] = useState(false);
  const [call, setCall] = useState(null);
  const { id: procedureId } = useParams();
  const [file, openFile] = useState(null);
  const [showAddTag, setShowAddTag] = useState(false);
  const [tag, setTag] = useState("");

  // Validate stages
  const [fulfillment, setFulfillment] = useState(true);
  const [notes, setNotes] = useState("");
  const [validateForm, openValidateForm] = useState(false);

  const [selectedUpload, setSelectedUpload] = useState({
    stage: "",
    fileName: "",
  });

  useEffect(() => {
    getProcedureData();
    getTags();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProcedureData = async () => {
    const { data } = await GetProcedureData(procedureId);
    setData(data);
    setLoading(false);

    if (data.status === 0) {
      await UpdateProcedure(
        {
          status: 1,
        },
        procedureId
      );
    }
  };

  const getTags = async () => {
    const { data } = await GetTags();
    setTags(data);
  };

  const attachFile = async (stage, fileName, placeInIndex = null) => {
    setSelectedUpload({
      stage,
      fileName,
      placeInIndex,
    });
    const file = document.getElementById("file");
    file.click();
  };

  const onChangeFile = async (e) => {
    if (e.target.files.length > 0) {
      createNotification({
        message: "Subiendo documento(s)...",
      });
    }
    const data = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      data.append(e.target.files[i].name, e.target.files[i]);
    }
    data.append("fileName", selectedUpload.fileName);
    data.append("modelName", selectedUpload.stage);
    data.append("renterID", procedureData?.renter?._id);
    if (selectedUpload.placeInIndex != null) {
      console.log("Save on index", selectedUpload.placeInIndex);
      data.append("placeInIndex", selectedUpload.placeInIndex);
    }
    const {
      data: { urls },
    } = await UploadFile(procedureData._id, data);
    if (e.target.files.length > 0) {
      createNotification({
        message: "El documento se ha subido",
      });
    }
    const procedureDataUpdate = procedureData;
    procedureDataUpdate.renter[selectedUpload.stage][selectedUpload.fileName] =
      urls;
    setData((prevData) => ({ ...prevData, ...procedureDataUpdate }));
  };

  const isBusiness = () => procedureData?.renter?.isBusiness;

  const updatePersonalReference = async (data) => {
    const personalReferences = procedureData?.renter?.personalReferences;
    const index = personalReferences.findIndex((r) => r._id === data._id);
    personalReferences[index] = data;

    const renter = procedureData?.renter;
    setData({
      ...procedureData,
      renter: { ...renter, personalReferences },
    });
  };

  const updateReferenceData = (reference, index) => {
    let newRefs = procedureData.renter.personalReferences;
    newRefs[index] = reference;
    setData(() => ({
      ...procedureData,
      renter: {
        ...procedureData.renter,
        personalReferences: newRefs,
      },
    }));
  };

  const validateStage = async (stage, validation) => {
    if (validateForm === "otherFiles") {
      const { data } = await UpdateField({
        modelName: "generalData",
        query: {
          _id: procedureData?.renter?.generalData._id,
        },
        update: {
          creditAndLegalHistoryFulFillment: true,
        },
      });
      if (data) {
        createNotification({
          message: "Información validada correctamente.",
        });
      }
    } else {
      const { data } = await ValidateStage(procedureData?.renter[stage]._id, {
        stage,
        ...validation,
      });
      createNotification(data);
    }

    const updateStage = { ...procedureData?.renter[stage], ...validation };
    const procedureDataUpdate = procedureData;
    procedureDataUpdate.renter[stage] = updateStage;
    setData((prevData) => ({ ...prevData, ...procedureDataUpdate }));
    setFulfillment(false);
    setNotes("");
    openValidateForm(null);
  };

  ProcedureDetail.finishProcedure = async () => {
    const { data } = await FinishProcedure(procedureData?._id);
    createNotification(data);
  };

  const addTag = async () => {
    setShowAddTag(true);
  };

  const addTagSubmit = async (e) => {
    e.preventDefault();
    await AddTag({ tag }, procedureData?._id);
    setData({ ...procedureData, tags: [...procedureData.tags, tag] });
    setShowAddTag(false);
    getTags();
  };

  const deleteTag = async (tag) => {
    await DeleteTag({ tag }, procedureData?._id);
    setData({
      ...procedureData,
      tags: procedureData.tags.filter((t) => t !== tag),
    });
    setShowAddTag(false);
  };

  return (
    <Padding padding="16px 30px">
      <LoaderItem isLoading={isLoading}>
        <InvestigationContext.Provider
          value={{
            renter: procedureData?.renter,
            procedure: procedureData,
            updatePersonalReference,
            openCallHistoryItem: (call) => setCall(call),
            openFile: (file) => openFile(file),
            attachFile,
            openValidateForm,
          }}
        >
          <div className="columns">
            <div className={`column ${file !== null ? "is-7" : ""}`}>
              <Panel>
                <Detail>
                  <nav
                    className="navbar is-marginless is-transparent"
                    style={{ zIndex: 0 }}
                  >
                    <div className="navbar-start">
                      <h1 className="is-size-4 has-text-weight-bold">
                        Investigación de:{" "}
                        <span className="has-text-primary is-uppercase">
                          {procedureData?.renter?.generalData?.firstName} &nbsp;
                          {procedureData?.renter?.generalData?.secondName}{" "}
                          &nbsp;
                          {procedureData?.renter?.generalData?.lastName1} &nbsp;
                          {procedureData?.renter?.generalData?.lastName2}
                        </span>
                      </h1>
                    </div>
                    <div className="navbar-end">
                      <div className="buttons">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/panel/tramite/descargar/${procedureData._id}`}
                          className="button is-info"
                        >
                          <span>Descargar</span>
                          <span className="icon">
                            <i className="far fa-print" />
                          </span>
                        </a>
                        <button
                          className="button is-primary"
                          onClick={() =>
                            showConfirmationDialog({
                              title: "Finalizar investigación",
                              description:
                                "Quieres finalizar esta investigación y notificar al cliente, esta acción no se puede deshacer.",
                              agreeFunction: ProcedureDetail.finishProcedure,
                            })
                          }
                        >
                          <span>Enviar Investigación</span>
                          <span className="icon">
                            <i className="far fa-paper-plane" />
                          </span>
                        </button>
                      </div>
                    </div>
                  </nav>
                  <div className="navbar is-transparent" style={{ zIndex: 0 }}>
                    <div className="navbar-start">
                      {procedureData?.renter?.user?.referencedBy && (
                        <RealEstateTag
                          id={procedureData?.renter?.user?.referencedBy}
                        />
                      )}
                      <span className="tag is-info mr-2">
                        {PROCEDURE_TYPES[procedureData.type]}
                      </span>
                      <span className="tag is-link mr-2">
                        {isBusiness() ? "Persona Moral" : "Persona Física"}
                      </span>
                      {procedureData?.tags?.map((tag) => (
                        <span
                          key={tag}
                          className="tag procedure-tag is-link mr-2"
                        >
                          <span>{tag}</span>
                          <button
                            class="delete is-small"
                            onClick={() => deleteTag(tag)}
                          ></button>
                        </span>
                      ))}
                      {!showAddTag ? (
                        <span
                          className="tag is-primary mr-2 as-cursor"
                          onClick={addTag}
                        >
                          Añadir etiqueta +
                        </span>
                      ) : (
                        <form onSubmit={addTagSubmit}>
                          <div className="field">
                            <p class="control has-icons-left has-icons-right">
                              <input
                                className="input is-small"
                                type="text"
                                placeholder="Añadir etiqueta"
                                value={tag}
                                onChange={(e) => setTag(e.target.value)}
                                list="tags"
                              />
                              <datalist id="tags">
                                {tags?.map((tagItem) => (
                                  <option
                                    key={tagItem?._id}
                                    value={tagItem?.tag}
                                  />
                                ))}
                              </datalist>
                              <span
                                class="is-small"
                                onClick={() => setShowAddTag(false)}
                                style={{
                                  cursor: "pointer",
                                  position: "absolute",
                                  right: "10px",
                                  top: "5px",
                                }}
                              >
                                <i class="fas fa-times"></i>
                              </span>
                            </p>
                          </div>
                        </form>
                      )}
                    </div>
                    <div className="navbar-end"></div>
                  </div>
                  <div>
                    Ultima edición: &nbsp;
                    <b>
                      <Moment fromNow>{procedureData?.updatedAt}</Moment>
                    </b>
                    &nbsp; por: &nbsp;
                    <b>
                      {procedureData?.updatedBy?._id === currentUser._id
                        ? "Ti"
                        : procedureData?.updatedBy?.firstName}
                    </b>
                  </div>
                  <form>
                    <input
                      className="is-hidden"
                      type="file"
                      name="file"
                      id="file"
                      accept=".pdf, .png, .jpg, .jpeg"
                      onChange={onChangeFile}
                      multiple
                    />
                  </form>
                  <hr className="is-marginless" />
                  {addIssue && (
                    <AddIssueForm
                      procedure={procedureData}
                      close={() => setAddIssue(false)}
                    />
                  )}
                  <br />

                  <GeneralData
                    generalData={procedureData?.renter?.generalData}
                    isBusiness={isBusiness}
                    isCollapsed={stages.generalInformation}
                    collapse={(collapse) =>
                      setSatege({ ...stages, generalInformation: collapse })
                    }
                  />

                  <EconomyActivity
                    economyActivity={procedureData?.renter?.economyActivity}
                    isBusiness={isBusiness}
                    isCollapsed={stages.economyActivity}
                    collapse={(collapse) =>
                      setSatege({ ...stages, economyActivity: collapse })
                    }
                  />

                  <OtherFiles />

                  <DestinationProperty
                    destinationProperty={
                      procedureData?.renter?.destinationProperty
                    }
                    isBusiness={isBusiness}
                    isCollapsed={stages.destinationProperty}
                    collapse={(collapse) =>
                      setSatege({ ...stages, destinationProperty: collapse })
                    }
                  />

                  <PersonalReferences
                    personalReferences={
                      procedureData?.renter?.personalReferences
                    }
                    isBusiness={isBusiness}
                    isCollapsed={stages.personalReferences}
                    collapse={(collapse) =>
                      setSatege({ ...stages, personalReferences: collapse })
                    }
                    updateReferenceData={updateReferenceData}
                  />

                  <GuarantorData
                    guarantorData={procedureData?.renter?.guarantorData}
                    isBusiness={isBusiness}
                    isCollapsed={stages.guarandorData}
                    collapse={(collapse) =>
                      setSatege({ ...stages, guarandorData: collapse })
                    }
                  />

                  <GuarantorProperty
                    isClg={procedureData?.type === "CLG"}
                    guarantorProperty={procedureData?.renter?.guarantorProperty}
                    isBusiness={isBusiness}
                    ownerFile={procedureData?.renter?.guarantorData.ownerFile}
                    houseDeedsFile={
                      procedureData?.renter?.guarantorData.houseDeedsFile
                    }
                    registrationTicketFile={
                      procedureData?.renter?.guarantorData
                        .registrationTicketFile
                    }
                    predialPaymentFile={
                      procedureData?.renter?.guarantorData.predialPaymentFile
                    }
                    isCollapsed={stages.guarantorProperty}
                    collapse={(collapse) =>
                      setSatege({ ...stages, guarantorProperty: collapse })
                    }
                  />

                  <Issues />
                </Detail>
              </Panel>
            </div>
            {file !== null ? (
              <div className="column is-5">
                <Panel style={{ position: "sticky", top: "10px" }}>
                  <FileViewer fileKey={file[0]} openFile={openFile} />
                </Panel>
              </div>
            ) : null}
          </div>
          <Dialog isActive={call !== null} close={() => setCall(null)}>
            {call !== null && <RegisterCallDetail call={call} />}
          </Dialog>

          <FloatingDetailActions
            procedure={procedureData}
            setAddIssueModal={() => setAddIssueModal(true)}
            setShowLinks={() => setShowLinks(true)}
          />

          <AddIssueModal show={addIssueModal}>
            <AddIssueForm
              procedure={procedureData}
              close={() => setAddIssueModal(false)}
            />
          </AddIssueModal>

          <LinksModal show={showLinks}>
            <div className="notification is-info">
              <div className="navbar is-transparent">
                <div className="navbar-start">
                  <p className="subtitle has-text-white">Enlaces directos</p>
                </div>
                <div className="navbar-end">
                  <button
                    className="button is-circle is-info"
                    data-tooltip="Cerrar"
                    onClick={() => setShowLinks(false)}
                  >
                    <span className="icon">
                      <i className="fa fa-times" />
                    </span>
                  </button>
                </div>
              </div>
              <div className="tags">
                <a
                  href="https://www.boletingdl.com.mx/inicio.php"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tag is-light is-medium"
                >
                  Boletín GDL
                </a>
                <a
                  href="https://listanominal.ine.mx/scpln/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tag is-light is-medium"
                >
                  Validación INE
                </a>
                <a
                  href="https://www.gob.mx/curp/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tag is-light is-medium"
                >
                  Validación CURP
                </a>
                <a
                  href="https://verificacfdi.facturaelectronica.sat.gob.mx/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tag is-light is-medium"
                >
                  Comprobantes Fiscales
                </a>
                <a
                  href="https://otorgantes.circulodecredito.com.mx"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tag is-light is-medium"
                >
                  Círculo de crédito
                </a>
              </div>
            </div>
          </LinksModal>

          {validateForm && (
            <Dialog
              isActive={validateForm}
              close={() => openValidateForm(false)}
            >
              <div className="notification">
                <p className="title is-3 has-text-centered">
                  Completar validación
                </p>
                <br />
                {/* <p className="has-text-weight-bold">
                    ¿Cumple?
                  </p>
                  <br/>
                  <div className="control" onChange={({ target: {value}}) => setFulfillment(value)}>
                    <label className="radio">
                      <input type="radio" name="fulfillment" value={true}/>
                      Si
                    </label>
                    <label className="radio">
                      <input type="radio" name="fulfillment" value={false}/>
                      No
                    </label>
                  </div> */}
                <br />
                <p className="has-text-weight-bold">Notas</p>
                <textarea
                  className="textarea"
                  placeholder="Notas de la sección"
                  value={notes}
                  onChange={({ target: { value } }) => setNotes(value)}
                />
                <br />
                <button
                  className="button is-primary is-fullwidth"
                  onClick={() =>
                    validateStage(validateForm, {
                      notes,
                      fulfillment: JSON.parse(fulfillment),
                    })
                  }
                >
                  Guardar
                </button>
              </div>
            </Dialog>
          )}
        </InvestigationContext.Provider>
      </LoaderItem>
    </Padding>
  );
}
