import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { API_VERSION } from "services/Partners";

export const SelectProperty = ({ onClose }) => {
  const [selected, setSelected] = useState(null);
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(`${API_VERSION}/partners/property/`);
      setProperties(data);
    })();
  }, []);

  return (
    <div className="notification overflow-auto" style={{ width: "500px" }}>
      <h1 className="title is-5">Selecciona una propiedad</h1>
      {properties.map((property) => (
        <div
          key={property._id}
          className={`notification m-1 as-cursor ${
            selected?._id === property._id ? "is-link" : "is-white"
          }`}
          onClick={() => setSelected(property)}
        >
          {property.name} <br /> {property.address}
        </div>
      ))}

      <div className="column is-full buttons mt-6 is-flex is-justify-content-end">
        <button className="button" type="button" onClick={() => onClose()}>
          Cancelar
        </button>
        <button
          className={`button is-primary`}
          type="button"
          onClick={() => onClose(selected)}
        >
          Aceptar
        </button>
      </div>
    </div>
  );
};
