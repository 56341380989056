import styled from "styled-components";

export const CardHeader = styled.div`
  display: flex;
  .icon {
    width: 45px;
    height: 45px;
    min-width: 45px;
    min-height: 45px;
    box-shadow: none;
    border-radius: 100%;
    color: white !important;
    font-size: 18px;
    &.orange {
      color: #fc7753 !important;
      background-color: #fc77535e;
    }

    &.blue {
      color: #537afc !important;
      background-color: #537afc61;
    }
    
    &.green {
      color: #38BA51 !important;
      background-color: #38BA515e;
    }

    &.purple {
      color: #9738BA !important;
      background-color: #9738BA61;
    }
  }
  .data {
    font-weight: 500;
    padding: 0px 18px;
    div:first-child {
      font-weight: 600;
    }
  }
`;
