import axios from 'axios';
import { API } from '../../api';

const API_VERSION = API.server

export const Stages = {
  GeneralData: 0,
  EconomyActivity: 1,
  DestinationProperty: 2,
  PersonalReferences: 3,
  GuarantorData: 4,
  GuarantorProperty: 5,
  Review: 6,
  GenerateDoc: 7,
}

export const StagesLabels = [
  'Información General',
  'Actividad económica',
  'Destino del inmueble',
  'Referencias personales',
  'Datos del fiador',
  'Propiedad del fiador',
  'Revisón',
  'Generar documento',
]

export const IssuesLabels = {
  generalData: {    
    domFile: 'Comprobante de domicilio',
    ineFile: 'Identificación oficial',
    actFile: 'Acta constitutiva',
    fiscalFile: 'Cédula fiscal',
    powerFile: 'Carta poder',
    label: 'Información General',
    fullName: 'Nombre',
    birthdate: 'Fecha de nacimiento',
    birthPlace: 'Lugar de nacimiento',
    street: 'Domocilio (Calle)',
    number: 'Domocilio (Número)',
    postalCode: 'Domiclio (CP)',
    state: 'Domiclio (Estado)',
    city: 'Domiclio (Ciudad)',
    rfc: 'RFC',
    curp: 'CURP',
    nationality: 'Nacionalidad',
    maritialStatus: 'Estado civil',
    spouseName: 'Nombre del cónyuge',
    spousePhone: 'Teléfono del cónyuge',
    houseStatus: 'Estado del domiclio actual',
    otherStatus: 'Estado del domiclio actual (Especifíque)',
    rentAmmount: 'Monto de renta',
    timeInHouse: 'Tiempo en el domicilio',
    houseOwner: 'Nombre del dueño',
    houseContact: 'Contacto del dueño',
    businessName: 'Razón social',
    businessNationality: 'Nacionalidad',
  },
  economyActivity: { 
    entryFile: 'Comprobantes de ingresos',   
    label: 'Actividad Económica',
    mainEntry: 'Fuente principal de ingresos',
    workSource: 'Fuente de trabajo',
    businessDesc: 'Descripción del trabajo',
    pensionSource: 'Fuente de pensión',
    other: 'Fuente principal de ingresos(Otro)',
    timeInJob: 'Tiempo en el trabajo',
    address: 'Domiclio del trabajo',
    jobPhone: 'Teléfono del trabajo',
    website: 'Sitio web',
    mensualEntry: 'Ingreso mensual neto',
    otherEntry: 'Otros ingresos',
    otherEntryName: 'Nombre de otro ingreso',
    otherEntryAmmount: 'Monto de otros ingresos',
    totalMonthEntry: 'Total de ingresos',
    totalMonthExpenses: 'Gastos del mes',
    mainActivity: 'Actividad principal',
  },
  destinationProperty: {    
    label: 'Destino del inmuble',
    usage: 'Uso del inmueble',
    totalPeople: 'Total de habitantes',
    children: 'Niños',
    adults: 'Adultos',
    olderAdults: 'Adulto mayor',
    hasPets: 'Tiene mascotas',
    petType: 'Tipo de mascota',
    habitationalName: 'Nombre de quién habitará el inmueble',
  },
  personalReference: {
    label: 'Referencias personales',
  },
  guarantorData: {    
    label: 'Datos del fiador',
    name: 'Nombre',
    birthPlace: 'Lugar de nacimiento',
    birthdate: 'Fecha de nacimiento',
    rfc: 'RFC',
    curp: 'CURP',
    nationality: 'Nacionalidad',
    maritialStatus: 'Estado civil',
    job: 'Trabajo',
    spouseName: 'Nombre del cónyuge',
    spousePhone: 'Teléfono del cónyuge',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    address: 'Dirección',
    businessName: 'Razón social',
    businessRFC: 'RFC (Empresa)',
    businessAddress: 'Dirección de la empresa',
    businessNationality: 'Nacionalidad de la empresa',
    businessPhone: 'Teléfono de la empresa',
  },
  guarantorProperty: {    
    ownerFile: 'Comprobante de titularidad del inmueble',
    label: 'Propiedad del fiador',
    address: 'Domicilio',
    assessment: 'Reporta gravamen?',
    assessmentName: 'Tipo de gravamen',
  }
}

export const UpdateField = (data) => axios.post(`${API_VERSION}/procedures/update-data`, data)
export const GetProcedures = (filter = '') => axios.get(`${API_VERSION}/procedures${filter}`)
export const GetOnHoldRenters = () => axios.get(`${API_VERSION}/renter/users`)
export const GetProceduresByFilter = (filter = 'all') => axios.get(`${API_VERSION}/procedures/reports?query=${filter}`)
export const GetProcedureData = (id) => axios.get(`${API_VERSION}/procedures/${id}`)
export const UpdateProcedure = (procedure, id) => axios.put(`${API_VERSION}/procedures/${id}`, procedure)
export const AddIssue = (data) => axios.post(`${API_VERSION}/procedures/add-issue`, data)
export const AddTag = (data, id) => axios.post(`${API_VERSION}/procedures/add-tag/${id}`, data)
export const DeleteTag = (data, id) => axios.post(`${API_VERSION}/procedures/delete-tag/${id}`, data)
export const NotifyIssues = (data) => axios.post(`${API_VERSION}/procedures/notify-issues`, data)
export const SolveIssue = (data) => axios.post(`${API_VERSION}/procedures/solve-issues`, data)
export const GetIssues = (id) => axios.get(`${API_VERSION}/procedures/issues/${id}`)

export const ValidateStage = (id, data) => axios.post(`${API_VERSION}/procedures/validate-stage/${id}`, data)
export const FinishProcedure = (id) => axios.post(`${API_VERSION}/procedures/finish/${id}`, {})
export const GenerateQrLink = (procedureId) => axios.get(`${API_VERSION}/renter/qr-link/${procedureId}`)

export const InsertJobCallHistory = (id, data) => axios.post(`${API_VERSION}/procedures/economy-activity/insert-call-history/${id}`, data)
export const UpdateJobCallHistory = (id, data) => axios.put(`${API_VERSION}/procedures/economy-activity/update-call-history/${id}`, data)

export const InsertCallHistory = (id, data) => axios.put(`${API_VERSION}/personal-reference/insert-call-history/${id}`, data)
export const UpdateCallHistory = (id, data) => axios.put(`${API_VERSION}/personal-reference/update-call-history/${id}`, data)
export const DeleteCallHistory = (id, data) => axios.put(`${API_VERSION}/personal-reference/delete-call-history/${id}`, data)
export const AddReferenceComment = (id, comments) => axios.put(`${API_VERSION}/references/reference-comments/${id}`, {comments})
export const UpdateReference = (id, data) => {
  return axios.put(`${API_VERSION}/references/${id}`, data)
}
export const GetFileURL = (data) => axios.post(`${API_VERSION}/procedures/get-file`, data)
export const UploadFile = (id, data) => axios.post(`${API_VERSION}/procedures/upload-file/${id}`, data)

export const GetCreditHistory = (id) => axios.get(`${API_VERSION}/credit-history/${id}`)
export const CreateNewProcedure = (data) => axios.post(`${API_VERSION}/procedures/create/`, data)

export const DownloadFilesZip = (id) => axios.get(`${API_VERSION}/procedures/files/`+id,)

export const QualifyHistory = (data, id) => axios.post(`${API_VERSION}/credit-history/qualify/${id}`, data)