import React from "react";
import { CallHistoryItem } from "../CallHistoryItem";

export const CallHistoryList = ({
  isSmall = false,
  showActions = true,
  calls,
  reference,
  economyActivtyId,
  remove,
}) => {
  let history = calls.filter((e) => !e.onTrash);

  return (
    <div>
      {calls.length !== 0 && (
        <table className="table is-narrow is-fullwidth is-transparent">
          <thead>
            <tr>
              <th className={isSmall ? "is-size-7" : ""}>Contestó</th>
              <th className={isSmall ? "is-size-7" : ""}>Fecha</th>
              <th className={isSmall ? "is-size-7" : ""}>Duración</th>
              <th className={isSmall ? "is-size-7" : ""}>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {history.map((call, index) => (
              <CallHistoryItem
                key={index}
                remove={remove}
                showActions={showActions}
                isSmall={isSmall}
                call={call}
                reference={reference}
                economyActivtyId={economyActivtyId}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
