import Dialog from "components/Dialog";
import { Input } from "components/Form";
import React, { useCallback, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FormSection } from "./formSection";
import { reorderItems } from "../utils";

const EMPTY_SECTION = (id) => {
  return {
    id: id.toString(),
    name: `Sección ${id.toString()}`,
    fields: [],
  };
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, isSelected) => ({
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: "#f8f8fe",
  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: isDragging || isSelected ? "#66d7d1" : "transparent",
  ...draggableStyle,
});

export const NewForm = () => {
  const [sections, setSections] = useState([]);
  const [showEditSection, setShowEditSection] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorderItems(
      sections,
      result.source.index,
      result.destination.index
    );

    setSections(items);
  };

  const saveEditingSection = useCallback(() => {
    const newSections = sections;
    newSections[parseInt(showEditSection?.id) - 1] = showEditSection;
    setSections([...newSections]);
    setShowEditSection(null);
  }, [showEditSection, sections]);

  return (
    <div>
      <h1 className="title is-size-5">Crear formulario</h1>
      <nav className="navbar">
        <div className="navbar-start">
          <button
            className="box button is-small is-primary"
            onClick={() =>
              setSections([...sections, EMPTY_SECTION(sections.length + 1)])
            }
          >
            <span>Agregar Seccion</span>
            <span className="icon">
              <i className="fas fa-list-alt"></i>
            </span>
          </button>
        </div>
      </nav>
      <div className="columns">
        <div className="column is-4">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {sections.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="box is-shadowless"
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            selectedSection?.id === item.id
                          )}
                          onClick={() => setSelectedSection(item)}
                        >
                          <div className="is-align-content-center is-flex is-justify-content-space-between">
                            <div>
                              <p className="has-text-weight-bold is-size-6">
                                {item?.name}
                              </p>
                            </div>
                            <div className="buttons">
                              <button
                                className="button is-small is-white"
                                onClick={() => setShowEditSection(item)}
                              >
                                <span className="icon">
                                  <i className="fas fa-pen"></i>
                                </span>
                              </button>
                              <div
                                className="button is-borderless is-transparent is-small"
                                {...provided.dragHandleProps}
                              >
                                <span className="icon">
                                  <i className="fas fa-grip-vertical"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {sections.length === 0 && (
            <div className="box has-background-grey-lighter	is-shadowless">
              <p className="title is-size-6">
                Agrega una sección a tu formulario
              </p>
            </div>
          )}
        </div>
        <div className="column is-8">
          <div className="box has-background-grey-lighter	is-shadowless">
            {selectedSection && (
              <FormSection selectedSection={selectedSection} />
            )}
          </div>
        </div>
      </div>
      <code>{JSON.stringify(sections)}</code>
      <Dialog
        isActive={showEditSection != null}
        close={() => setShowEditSection(null)}
      >
        {showEditSection && (
          <div className="notification is-white" style={{ width: "400px" }}>
            <h1 className="title is-size-5">Editar Sección</h1>
            <Input
              type="text"
              label="Nombre de la sección"
              placeholder="Nombre de la sección"
              onChange={(e) =>
                setShowEditSection({
                  ...showEditSection,
                  name: e.target.value,
                })
              }
              value={showEditSection?.name}
            />
            <div className="buttons mt-6 is-flex is-justify-content-end">
              <button
                className="button is-danger is-outlined"
                onClick={() => setShowEditSection(null)}
              >
                Cancelar
              </button>
              <button
                className="button is-primary"
                onClick={saveEditingSection}
              >
                Guardar
              </button>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};
