import React, { useState,useEffect, useContext } from 'react'
import { NotificationContext } from '../../services/NotificationService'
import { GetIssues, IssuesLabels, NotifyIssues, SolveIssue } from '../../services/Procedures'
import { InvestigationContext } from '../ProcedureDetail'
import { StageNavbar } from '../StageNavbar'

export const Issues = (props) => {
  const [issues, setIssues] = useState([])
  const [isCollapsed, collapse] = useState(true)
  const { procedure } = useContext(InvestigationContext)
  const { createNotification } = useContext(NotificationContext)

  useEffect(() => {
    (async function () {
      let { data: { issues: issuesFinded = [] } } = await GetIssues(procedure._id)
      setIssues(issuesFinded);      
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed])

  const notify = async () => {
    let { data } = await NotifyIssues({
      procedure: procedure._id,
      user: procedure.renter.user._id,
    });

    createNotification(data);
  }
  
  const solve = async (issue) => {
    let res = await SolveIssue({
      issue: issue._id
    });
    if (res.status === 200) {
      let index = issues.map(i => i._id ).indexOf(issue._id);
      let newIssues = issues;
      newIssues.splice(index, 1);

      setIssues(newIssues);
    }
    createNotification(res.data);
  }
  

  const issueItem = (issue) => {

    if (issue.section === 'personalReference') {
      return <div key={issue._id} className="is-flex has-background-light p-3 m-2 is-justify-content-space-between">
       <p key={issue._id}  className="has-text-primary has-background-light p-3 m-2">
        Hay un error en <b>{ IssuesLabels[issue.section].label }</b>
        <br/>
        <i>Comentarios: <b>&nbsp;{ issue.description }</b></i>
      </p> 
      <button className="button is-outlined is-circle is-success has-text-weight-bold" onClick={() => solve(issue)}>
        <span className="icon">
          <i className="fas fa-check"></i>
        </span> 
      </button>
      </div>
    }

    return <div key={issue._id} className="is-flex has-background-light p-3 m-2 is-justify-content-space-between">
      <p key={issue._id}  className="has-text-primary">
        Hay un error en la sección <b>{ IssuesLabels[issue.section].label }</b>, está incompleto el campo <b>{ IssuesLabels[issue.section][issue.field] }</b>
        <br/>
        <i>Comentarios: <b>&nbsp;{ issue.description }</b></i>
        <br/>        
      </p> 
      <button className="button is-outlined is-circle is-success has-text-weight-bold" onClick={() => solve(issue)}>
        <span className="icon">
          <i className="fas fa-check"></i>
        </span> 
      </button>
    </div>
  }
  
  return (         
    <div>
      <StageNavbar>
        <p className="is-size-5 has-text-weight-bold as-cursor has-text-primary" onClick={() => collapse(!isCollapsed)}>
          <span className="icon">
            {
              isCollapsed ?  <i className="fas fa-caret-right"></i> :  <i className="fas fa-caret-down"></i>
            }
          </span>
          <span>
          Observaciones <span className="tag is-danger is-rounded">
            { issues.length }
          </span>
          </span>
        </p>
        {
          ! isCollapsed && <div className="actions">
            <button className="button is-light has-text-weight-bold" onClick={() => notify()}>
              <span>
                Notificar todas las observaciones
              </span>
              <span className="icon">
                <i className="fas fa-bell"></i>
              </span> 
            </button>
          </div>
        }
      </StageNavbar>      
      
      {
        !isCollapsed && <div>
          <br/>
          {
            issues.length === 0 && <div className="notification">Esta investigación no tiene observaciones.</div>
          }

          {
            issues.map(i => issueItem(i))
          }          
        </div>
      }
      <br/>
    </div>
  )
}

export default Issues;

