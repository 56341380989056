import { CallHistoryList } from "components/CallHistoryList";
import { CardHeader } from "components/Card";
import { DataItem, DataLabel } from "components/DataItem";
import { InvestigationContext } from "components/ProcedureDetail";

import React, { useContext } from "react";

export const PersonalReferences = () => {
  const { loading, renter: { personalReferences } = {} } =
    useContext(InvestigationContext);
  return loading ? (
    "Cargando"
  ) : (
    <div>
      <h3 className="title is-5">Referencias Personales</h3>
      <div className="columns">
        {personalReferences?.map((p) => (
          <div className="column is-6-desktop is-12-tablet" key={p._id}>
            <div className="box">
              <CardHeader>
                <div className="icon orange">
                  <i className="fas fa-user"></i>
                </div>
                <div className="data">
                  <div>{p?.name}</div>
                  <div className="has-text-grey	">{p?.relationship}</div>
                </div>
              </CardHeader>
              <br />
              <DataItem field="E-mail" data={p?.email} />
              <DataItem field="RFC" data={p?.phone} titleCase />
              <DataItem field="Ocupación" data={p?.job} titleCase />
              <div className="my-2" />
              <DataLabel label="Comentarios" />
              <DataItem
                field=""
                data={p?.comments?.length > 0 ? p?.comments : "Sin comentarios"}
                titleCase
              />
              <br />
              {p?.history.length !== 0 && (
                <>
                  <DataLabel label="Llamadas" />
                  <div className="notification is-white">
                    <CallHistoryList
                      isSmall
                      calls={p?.history}
                      showActions={false}
                      economyActivtyId={p._id}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
