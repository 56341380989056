import React, { useContext } from "react";
import { InvestigationContext } from "..";
import { InfoItem } from "../../InfoItem";
import { StageNavbar } from "../../StageNavbar";

const usages = [
  "Casa habitación",
  "Comercial",
  "Oficina",
  "Industrial",
  "Bodega",
  "Agricola",
  "Mixto",
  "Otro",
];

export const DestinationProperty = ({
  destinationProperty,
  isBusiness,
  isCollapsed = false,
  collapse,
}) => {
  const { openValidateForm } = useContext(InvestigationContext);

  return (
    <div>
      <StageNavbar>
        <p
          className="is-size-5 has-text-weight-bold as-cursor no-selectable"
          onClick={() => collapse(!isCollapsed)}
        >
          <span className="icon">
            {isCollapsed ? (
              <i className="fas fa-caret-right"></i>
            ) : (
              <i className="fas fa-caret-down"></i>
            )}
          </span>
          <span>
            Destino del inmueble{" "}
            {destinationProperty.fulfillment != null ? (
              <span>
                <span
                  className={`icon is-rounded mr-3 ${
                    destinationProperty.fulfillment === true
                      ? "has-text-success"
                      : "has-text-is-success "
                  }`}
                >
                  {destinationProperty.fulfillment === true ? (
                    <i className="fas fa-check"></i>
                  ) : (
                    <i className="fas fa-check"></i>
                  )}
                </span>
              </span>
            ) : (
              ""
            )}
          </span>
        </p>
        {
          // VALIDATE BUTTON
          !isCollapsed && (
            <div className="actions">
              <button
                className="button is-light has-text-weight-bold"
                onClick={() => openValidateForm("destinationProperty")}
              >
                <span>Validar</span>
                <span className="icon">
                  <i className="fas fa-check"></i>
                </span>
              </button>
            </div>
          )
        }
      </StageNavbar>
      <br />
      {!isCollapsed && (
        <div>
          <div className="notification is-light mr-3">
            <span className="is-uppercase has-text-weight-bold has-text-primary">
              Uso
            </span>
            <br />
            <span className="has-text-weight-bold is-size-5">
              {usages[destinationProperty?.usage]}
            </span>
          </div>
          {destinationProperty?.usage === 0 && isBusiness() ? (
            <InfoItem
              field="Nombre de quién habitará"
              value={destinationProperty?.habitationalName}
            />
          ) : null}
          <p className="has-text-weight-bold">Personas que habitarán</p>
          <br />
          <div className="is-flex is-align-items-baseline">
            {destinationProperty?.childrenAges?.map((children, i) => (
              <div className="notification is-light mr-3">
                <span className="is-uppercase has-text-weight-bold has-text-primary">
                  Un niño de:
                </span>
                <br />
                <span className="has-text-weight-bold is-size-2">
                  {children?.age}
                </span>{" "}
                &nbsp; años
              </div>
            ))}
            <div className="notification is-light mr-3">
              <span className="is-uppercase has-text-weight-bold has-text-primary">
                Total de Adultos:
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                {destinationProperty?.adults}
              </span>
            </div>
            <div className="notification is-light mr-3">
              <span className="is-uppercase has-text-weight-bold has-text-primary">
                Total:
              </span>
              <br />
              <span className="has-text-weight-bold is-size-2">
                {parseInt(destinationProperty?.adults) +
                  destinationProperty?.childrenAges?.length}
              </span>
            </div>
          </div>
          <p className="has-text-weight-bold">Mascotas</p>
          <br />
          {destinationProperty?.hasPets ? (
            <div className="notification is-light mr-3">
              <span className="has-text-weight-bold is-size-5">
                {destinationProperty?.petType}
              </span>
            </div>
          ) : (
            <div className="notification is-light mr-3">
              <span className="has-text-weight-bold is-size-5">
                No habrá mascotas
              </span>
            </div>
          )}
          <br />
        </div>
      )}
    </div>
  );
};
