import React, { useState } from 'react'
import { CreateStaff, UpdateStaff } from '../../services/Staff'

const userFields = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: 0,
};

export const AddUserForm = (props) => {
  let { user }= props; 
  const [userData, setUserData] = useState({ ...userFields, ...user })

  const [isLoading, setLoading] = useState('')


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading('is-loading');
    if (user) {
      let { data } = await UpdateStaff(userData, user._id);
      if (data) {
        props.close(data, 'update')
      }
    } else {
      let { data } = await CreateStaff(userData);
      if (data) {
        props.close(data)
      }
    }
  }

  return (
    <div className="notification is-white">
      <p className="title is-3 has-text-centered">
        Agregar personal
      </p>
      <br/>
      <form onSubmit={handleSubmit}>
        <div className="columns is-multiline">
          <div className="column is-6">
            <div className="field">
              <label className="label">Nombre(s)</label>
              <div className="control">
                <input className="input is-medium" type="text" value={userData.firstName} onChange={(e) => setUserData({...userData, firstName: e.target.value})}/>
              </div>
            </div>                
                            
          </div>
          <div className="column is-6">
            <div className="field">
              <label className="label">Apellidos</label>
              <div className="control">
                <input className="input is-medium" type="text" value={userData.lastName} onChange={(e) => setUserData({...userData, lastName: e.target.value})}/>
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="field">
              <label className="label">Teléfono</label>
              <div className="control">
                <input className="input is-medium" type="tel" maxLength="10" value={userData.phone} onChange={(e) => setUserData({...userData, phone: e.target.value})}/>
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="field">
              <label className="label">Correo</label>
              <div className="control">
                <input className="input is-medium" type="email" value={userData.email} onChange={(e) => setUserData({...userData, email: e.target.value})}/>
              </div>
            </div>
          </div>
          <div className="column is-12">
            <div className="select is-fullwidth">
              <select name="role" id="role" value={userData.role} onChange={(e) => setUserData({...userData, role: e.target.value})}>
                <option value={0}>Administrador</option>
                <option value={1}>Ejecutivo</option>
              </select>
            </div>
          </div>
        </div>
          <button className={`button is-primary is-fullwidth ${isLoading}`} type="submit">
            { user ? 'Actualizar' : 'Crear' }
          </button>
      </form>
    </div>
  )
}
