import React from "react";
import "./style.scss";

export const Input = (props) => {
  const {
    label,
    type = "text",
    placeholder = "",
    onChange,
    error,
    showError,
    value,
    helper,
    isLoading,
    ...rest
  } = props;

  return (
    <div className="field">
      {label && <label className="label">{label}</label>}
      <div className={`control ${isLoading ? "is-loading" : ""}`}>
        <input
          className="input"
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          {...rest}
        />
      </div>

      {helper}
      {showError && <p className="help is-danger">{error}</p>}
    </div>
  );
};
