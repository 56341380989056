import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery'
import { AuthUser, setToken, setCurrentUser } from '../../services/Staff'

export const SignInForm = (props) => {
  const query = useQuery()
  const login = query.get('login');

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, showError] = useState('')

  const signIn = async (e) => {
    e.preventDefault();
    try {
      let { data } = await AuthUser({
        email,
        password,
      })
  
      setToken(data.token);
      setCurrentUser(data.userData);
  
      window.location.href = '/'
    } catch (error) {
      showError('Usuario o contraseña incorrectos.')
    }
    
  } 

  return (
    <div>
      <div className="has-text-centered">
        <img src="/assets/logo.png" alt="Invix" className="logo is-centered"/>
      </div>
      <br/>     
      <p className="title is-3 has-text-centered">
        Iniciar sesión
      </p>
      {
        error !== '' ? <div>
          <div className="notification is-danger has-text-white">
            { error }
          </div> 
          <br/>
        </div>: null
      }
      {
        login === 'expired' && (
          <div className="notification is-danger has-text-white">
            La sesión ha expirado, por favor ingresa de nuevo
          </div> 
        )
      }
      <form onSubmit={signIn}>
        <div className="field">
          <label className="label">Correo electrónico</label>
          <div className="control">
            <input className="input is-medium" type="email" placeholder="ejemplo@ejemplo.com" value={email} onChange={(e) => setEmail(e.target.value)}/>
          </div>
        </div>

        <div className="field">
          <label className="label">Contraseña</label>
          <div className="control">
            <input className="input is-medium" type="password" placeholder="***" value={password} onChange={(e) => setPassword(e.target.value)}/>
          </div>
        </div>
        <br/>
        <NavLink to="/recuperar-contrasena" className="has-text-primary has-text-weight-bold">
          Recuperar contraseña
        </NavLink>
        <button className="button is-primary is-fullwidth" type="submit">
          Aceptar
        </button>
        </form>
        <br/>
    </div>
  )
}
