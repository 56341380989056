import React, { useState } from 'react'
import { Timer } from '../Timer';

export const RegisterCallForm = ({ reference, call, stopCall, onCall, time, renter }) => {

  const [callData, setCallData] = useState({
    id: Date.now(),
    known: '',
    meetTime: '',
    relationship: '',
    isResponsible: '',
    jobActivity: '',
    financialSolvency: '',
    recomended: '',
    date:  Date.now()
  })

  return (
    <div className="notification is-overflowed">
      <p className="title is-3 has-text-centered">
        Registrar una llamada
      </p>
      <p className="subtitle is-4 has-text-centered">
        Solicitante: <b>{ renter?.user?.firstName } { renter?.user?.lastName }</b>
        <br/>
        Nombre de la referencia : <b>{ reference.name }</b>
        <br/>
        Teléfono: <b>{ reference.phone }</b>
      </p>

      <button className={`button is-fullwidth ${ onCall ? 'is-danger pulse' : 'is-success' }`} onClick={onCall ? () => stopCall({...callData, answered: true}) : call}>
        { onCall ? ( <div>
          <span style={{display: 'flex'}}>
            Terminar llamada &nbsp; <Timer time={time}/>
          </span>
          <span className="icon">
            <i className="fas fa-stop"></i>
          </span>
        </div>) : ( <div>
          <span>
            Comenzar llamada
          </span>
          <span className="icon">
            <i className="fas fa-play"></i>
          </span>
        </div>) }
      </button>
      <br/>
      { onCall ? (  <button className={`button is-fullwidth ${ onCall ? 'is-danger' : 'is-success' }`} onClick={onCall ? () => stopCall({ ...callData, answered: false }) : call}>
          <span style={{display: 'flex'}}>
            No contestó
          </span>
          <span className="icon">
            <i className="fas fa-stop"></i>
          </span>
        </button>) : null}
      
      <hr/>
      <form>        
        <div className="columns">
          <div className="column is-6">
            <div className="field">
              <label className="label">¿Conoce al referido?</label>
              <div className="select is-fullwidth">
                <select value={callData.known} onChange={(e) => setCallData({...callData, known:e.target.value})}>
                  <option disabled value=''>Seleccionar opción</option>
                  <option value="si">Si</option>
                  <option value="no">No</option>
                  <option value="no lo sabe">No lo sabe</option>
                </select>
              </div>
            </div>           
            <div className="field">
              <label className="label">¿Cuál es su relación con el referido?</label>
              <div className="control">
                <input className="input" type="text" placeholder="Respuesta" value={callData.relationship} onChange={(e) => setCallData({...callData, relationship:e.target.value})}/>
              </div>
            </div>
            <div className="field">
              <label className="label">¿Lo considera una persona responsable?</label>
              <div className="select is-fullwidth">
                <select value={callData.isResponsible} onChange={(e) => setCallData({...callData, isResponsible:e.target.value})}>
                  <option disabled value=''>Seleccionar opción</option>
                  <option value="si">Si</option>
                  <option value="no">No</option>
                  <option value="no lo sabe">No lo sabe</option>
                </select>
              </div>
            </div>
            <div className="field">
              <label className="label">¿Sabe cuál es su activad laboral?</label>
              <div className="control">
                <input className="input" type="text" placeholder="Respuesta" value={callData.jobActivity} onChange={(e) => setCallData({...callData, jobActivity:e.target.value})}/>
              </div>
            </div>
          </div>
          <div className="column is-6">
            <div className="field">
              <label className="label">Tiempo de conocerle</label>
              <div className="control">
                <input className="input" type="text" placeholder="Respuesta" value={callData.meetTime} onChange={(e) => setCallData({...callData, meetTime:e.target.value})}/>
              </div>
            </div>
            <div className="field">
              <label className="label">¿ Lo considera solvente económicamente ?</label>
              <div className="select is-fullwidth">
                <select value={callData.financialSolvency} onChange={(e) => setCallData({...callData, financialSolvency:e.target.value})}>
                  <option disabled value=''>Seleccionar opción</option>
                  <option value="si">Si</option>
                  <option value="no">No</option>
                  <option value="no lo sabe">No lo sabe</option>
                </select>
              </div>
            </div>
            <div className="field">
              <label className="label">¿ Lo recomienda como inquilino ?</label>
              <div className="select is-fullwidth">
                <select value={callData.recomended} onChange={(e) => setCallData({...callData, recomended:e.target.value})}>
                  <option disabled value=''>Seleccionar opción</option>
                  <option value="si">Si</option>
                  <option value="no">No</option>
                  <option value="no lo sabe">No lo sabe</option>
                </select>
              </div>
            </div>
          </div>
        </div>        
      </form>
    </div> 
  )
}
