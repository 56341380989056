import React, { useState } from "react";

export const DropwDownMenu = ({
  children,
  icon,
  buttonText,
  buttonClass,
  alignment = "",
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={`dropdown ${alignment} ${open && "is-active"}`}>
      <div className="dropdown-trigger">
        <button
          onClick={() => setOpen(!open)}
          className={`button ${buttonClass}`}
          aria-haspopup="true"
          aria-controls="dropdown-menu"
        >
          <span>{buttonText}</span>
          {icon}
        </button>
      </div>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content" onClick={() => setOpen(!open)}>
          {children}
        </div>
      </div>
    </div>
  );
};
