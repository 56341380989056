import React from "react";
import styled from "styled-components";

const FileCardStyle = styled.button`
  width: 100%;
  border: 2px solid #d9dbe9;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  color: #002962;
  cursor: pointer;
  box-shadow: none;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1),
      0 0px 0 1px rgba(10, 10, 10, 0.02);
  }

  .files-count {
    color: white;
    background-color: #66d7d1;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
  }
`;

export const FileCard = ({ label, onOpenFile, files }) => {
  return files?.length > 0 ? (
    <FileCardStyle className="box" onClick={() => onOpenFile(label, files)}>
      <div className="is-flex is-align-items-center">
        <div className="files-count">{files?.length}</div>
        <div className="pl-3">{label}</div>
      </div>
      <div>
        <i className="fas fa-link"></i>
      </div>
    </FileCardStyle>
  ) : "";
};
