import { USAGES_CAHART_COLORS } from "utils/charts";
import { PROPERY_USAGES } from "constants/procedure";
import React, { useEffect, useState } from "react";
import { RadialChart } from "react-vis";
import { getProceduresUsages } from "services/Dashboard";

export const UsagesChart = () => {
  const [procedures, setProcedures] = useState([]);

  useEffect(() => {
    (async () => {
      const { data } = await getProceduresUsages();
      const usageItems = data.map((usageItem) => {
        return {
          label: PROPERY_USAGES[usageItem?._id],
          color: USAGES_CAHART_COLORS[usageItem?._id],
          angle: usageItem.count,
        };
      });
      setProcedures(usageItems.sort((a, b) => b?.angle - a?.angle));
    })();
  }, []);

  return (
    <div>
      <RadialChart
        className="mx-auto relative"
        colorType="literal"
        data={procedures}
        color={(d) => d.color}
        width={200}
        height={200}
        animation={"gentle"}
      ></RadialChart>

      {procedures?.map((usage) => (
        <div
          className="tag mx-1 is-medium has-text-weight-bold"
          style={{
            backgroundColor: usage.color,
          }}
        >
          {usage?.label}
        </div>
      ))}
    </div>
  );
};
