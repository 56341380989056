import React from "react";

export const PendingSecion = () => {
  return (
    <section className="hero is-small">
      <div className="hero-body">
        <div className="is-flex is-align-items-center">
          <i className="fas fa-exclamation-circle is-size-3 mr-4"></i>
          <div>
            <p className="title">Sección pendiente</p>
            <p className="subtitle">
              Esta sección aún no es validada por nuestros agentes.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
