import React, { useState } from 'react'
import styled from 'styled-components';

export const DetailActions = (props) => {

  const { setAddIssue } = props;

  return (
    <div className="navbar-item">
      <div className="buttons">        
        <button className='button is-circle is-light' data-tooltip="Añadir observación" onClick={setAddIssue}>
          <span className="icon">
            <i className="fa fa-exclamation"></i>                
          </span>
        </button>
      </div>
    </div>
  )
}

const FloatingActions = styled.div`
  position: fixed;
  right: 50px;
  bottom: 50px;
  display: flex;
  flex-direction: column;

  .button {
    transition: all 0.1s ease-in-out;
    margin: 5px;
    // transform: scale(${({show}) => show ? 1:0});

    &:hover {
      transform: scale(1.2);
    }
    
  }
`;

export const FloatingDetailActions = (props) => {

  const { setAddIssueModal, setShowLinks } = props;

  const [show] = useState(false)

  /*useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    }
  }, [])

  const handleScroll = (e) => {
    if (window.scrollY > 200) {
      console.log('Log at ~ file: index.jsx ~ line 56 ~ handleScroll ~ window.screenY', window.screenY)
      setShow(true);
    } else {
      setShow(false);
    }
  }*/

  return (
    <FloatingActions show={show}>
      <button className='button is-circle is-primary is-medium has-tooltip-left' data-tooltip="Añadir observación" onClick={setAddIssueModal}>
        <span className="icon">
          <i className="fa fa-exclamation"></i>                
        </span>
      </button>

      <button className='button is-circle is-info is-medium has-tooltip-left' data-tooltip="Enlaces directos" onClick={setShowLinks}>
        <span className="icon">
          <i className="fas fa-link"></i>       
        </span>
      </button>
    </FloatingActions>
  )
}
