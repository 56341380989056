import React, { useState } from "react";

export const InfoItem = ({
  field,
  value,
  onBlur,
  disabled = true,
  labelSize = "auto",
  name,
  type = "text",
}) => {
  const [inputValue, setValue] = useState(value);
  return (
    <div className="field is-vertical">
      <div className="field-label is-normal has-text-left">
        <label className="label" style={{ width: labelSize }}>
          {field}
        </label>
      </div>
      <div className="field-body">
        <div className="field">
          <div className="control">
            <input
              id={name}
              className="input is-uppercase"
              type={type}
              defaultValue={inputValue}
              onBlur={value !== inputValue ? onBlur : () => null}
              onChange={(e) => setValue(e.target.value)}
              disabled={disabled}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
