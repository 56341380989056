import React, { useState, useRef, useEffect, useContext } from "react";
import { InfoItem } from "../../InfoItem";
import {
  InsertJobCallHistory,
  UpdateField,
} from "../../../services/Procedures";
import Dialog from "../../Dialog";
import { RegisterCallForm } from "../../RegisterCallForm";
import { CallHistoryList } from "../../CallHistoryList";
import { StageNavbar } from "../../StageNavbar";
import { InvestigationContext } from "..";
import { FormatCurrency } from "../../FormatCurrency";

export const EconomyActivity = ({
  economyActivity,
  isBusiness,
  isCollapsed = false,
  collapse,
}) => {
  const { renter, openFile, attachFile, openValidateForm } =
    useContext(InvestigationContext);
  const [time, setTime] = useState(0);
  const [onCall, Call] = useState(false);
  const [startAt, setStartAt] = useState(null);
  const [history, setHistory] = useState(economyActivity.callHistory ?? []);
  const [registerCall, setRegisterCall] = useState(false);
  const [compareEnrty, setCompareEntry] = useState(false);
  const [compareOtherEnrty, setCompareOtherEntry] = useState(false);

  const [mensualEntryComparation, setMensualEntryComparation] = useState(
    economyActivity?.mensualEntryComparation ?? 0
  );
  const [otherEntryComparation, setOtherEntryComparation] = useState(
    economyActivity?.otherEntryComparation ?? 0
  );

  const countRef = useRef(time);
  countRef.current = time;

  useEffect(() => {
    let interval;
    if (onCall) {
      interval = setInterval(() => {
        setTime((currCount) => currCount + 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [onCall]);

  const call = () => {
    Call(true);
    setStartAt(Date.now());
  };

  const stopCall = (callData) => {
    setRegisterCall(false);
    addToHistory(callData);
    Call(false);
    setTime(0);
  };

  const addToHistory = async (callData) => {
    let itemHistoy = {
      duration: time,
      startAt,
      notes: "",
      ...callData,
    };

    await InsertJobCallHistory(economyActivity._id, itemHistoy);
    setHistory((prev) => prev.concat(itemHistoy));

    let newHistory = history;
    newHistory.push(itemHistoy);
  };

  const updateMensualEntryComparation = async (value) => {
    await UpdateField({
      modelName: "economyActivity",
      query: {
        _id: economyActivity._id,
      },
      update: {
        mensualEntryComparation: mensualEntryComparation,
      },
    });
  };

  const updateOtherEntryComparation = async (value) => {
    await UpdateField({
      modelName: "economyActivity",
      query: {
        _id: economyActivity._id,
      },
      update: {
        otherEntryComparation: otherEntryComparation,
      },
    });
  };

  const RenderMainEntry = () => {
    /**
     * Empleo -> workSource
     * Negocio -> businessDesc
     * Pensión -> pensionSource
     * Otro -> other
     */
    switch (economyActivity?.mainEntry) {
      case "empleo":
        return <InfoItem field="Empresa" value={economyActivity?.workSource} />;
      case "negocio":
        return (
          <InfoItem
            field="Nombre del negocio"
            value={economyActivity?.businessDesc}
          />
        );
      case "pension":
        return (
          <InfoItem
            field="Fuente de pensión"
            value={economyActivity?.pensionSource}
          />
        );
      case "otro":
        return (
          <InfoItem
            field="Fuente de ingreso (Otra)"
            value={economyActivity?.other}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <StageNavbar>
        <p
          className="is-size-5 has-text-weight-bold as-cursor no-selectable"
          onClick={() => collapse(!isCollapsed)}
        >
          <span className="icon">
            {isCollapsed ? (
              <i className="fas fa-caret-right"></i>
            ) : (
              <i className="fas fa-caret-down"></i>
            )}
          </span>
          <span>
            Actividad Económica
            {economyActivity.fulfillment != null ? (
              <span>
                <span
                  className={`icon is-rounded mr-3 ${
                    economyActivity.fulfillment === true
                      ? "has-text-success"
                      : "has-text-is-success "
                  }`}
                >
                  {economyActivity.fulfillment === true ? (
                    <i className="fas fa-check"></i>
                  ) : (
                    <i className="fas fa-check"></i>
                  )}
                </span>
              </span>
            ) : (
              ""
            )}
          </span>
        </p>
        {
          // VALIDATE BUTTON
          !isCollapsed && (
            <div className="actions">
              <button
                className="button is-light has-text-weight-bold"
                onClick={() => openValidateForm("economyActivity")}
              >
                <span>Validar</span>
                <span className="icon">
                  <i className="fas fa-check"></i>
                </span>
              </button>
            </div>
          )
        }
      </StageNavbar>
      <br />
      {!isCollapsed && (
        <div>
          <p className="has-text-weight-bold">Documentos:</p>
          <br />
          <div className="tags is-sticky">
            <span
              className="tag is-info is-rounded is-medium has-text-white is-pointer"
              onClick={() => {
                attachFile("economyActivity", "entryFile");
              }}
            >
              <span className="icon">
                <i className="fa fa-file-alt"></i>
              </span>
              <span>Reemplazar Comprobantes Ingreso</span>
            </span>
            {economyActivity.entryFile?.map((f, i) => (
              <span
                key={i}
                className="tag is-primary is-rounded is-medium has-text-white is-pointer"
                onClick={() => {
                  openFile([f]);
                }}
                onDoubleClick={() => {
                  attachFile("economyActivity", "entryFile", i);
                }}
              >
                <span className="icon">
                  <i className="fa fa-file-alt"></i>
                </span>
                <span>C. Ingreso {i + 1} </span>
              </span>
            ))}
            {economyActivity.entryFile.length === 0 && (
              <span
                className="tag is-link is-rounded is-medium has-text-white is-pointer"
                onClick={() => {
                  attachFile("economyActivity", "entryFile");
                }}
              >
                <span className="icon">
                  <i className="fa fa-file-alt"></i>
                </span>
                <span>Subir C. Ingreso</span>
              </span>
            )}
            {economyActivity.otherFile?.map((f, i) => (
              <span
                key={i}
                className="tag is-primary is-rounded is-medium has-text-white is-pointer"
                onClick={() => {
                  openFile([f]);
                }}
                onDoubleClick={() => {
                  attachFile("economyActivity", "otherFile", i);
                }}
              >
                <span className="icon">
                  <i className="fa fa-file-alt"></i>
                </span>
                <span>Otro Ingreso {i + 1} </span>
              </span>
            ))}
            {economyActivity.invoiceValidations?.map((f, i) => (
              <span
                key={i}
                className="tag is-link is-rounded is-medium has-text-white is-pointer"
                onClick={() => {
                  openFile([f]);
                }}
              >
                <span className="icon">
                  <i className="fa fa-file-alt"></i>
                </span>
                <span>Comprobante de factura: {i + 1} </span>
              </span>
            ))}
            <span
              className={`tag ${
                economyActivity.invoiceValidations.length !== 0
                  ? "is-info"
                  : "is-danger"
              } is-rounded is-medium has-text-white is-pointer`}
              onClick={() => {
                if (economyActivity.invoiceValidations.length !== 0) {
                  openFile(economyActivity.invoiceValidations);
                } else {
                  attachFile("economyActivity", "invoiceValidations");
                }
              }}
              onDoubleClick={() => {
                attachFile("economyActivity", "invoiceValidations");
              }}
            >
              <span className="icon">
                {economyActivity.invoiceValidations.length !== 0 ? (
                  <i className="fas fa-check-circle"></i>
                ) : (
                  <i className="fas fa-cloud-upload-alt"></i>
                )}
              </span>
              <span>Subir validaciones de facturas</span>
            </span>
          </div>

          {isBusiness() && (
            <InfoItem
              field="Actividad principal"
              value={economyActivity?.businessDesc}
            />
          )}

          <InfoItem
            field="Fuente de ingreso"
            value={economyActivity?.mainEntry}
          />
          {RenderMainEntry()}

          <div>
            <InfoItem
              field="Tiempo de trabajo"
              value={economyActivity?.timeInJob}
            />
            <InfoItem
              field="Télefono del trabajo"
              value={economyActivity?.jobPhone}
            />

            <p className="has-text-weight-bold">
              Historial de llamadas al trabajo
            </p>
            <div className="is-flex is-flex-wrap-wrap is-justify-content-space-between my-5">
              <div className="navbar is-transparent is-flex-grow-1">
                <div className="navbar-start">
                  <div className="navbar-item">
                    <p className="">
                      Para registrar una llamada da click en el botón "Registrar
                      llamada"
                    </p>
                  </div>
                </div>
                <div className="navbar-end">
                  <div className="buttons">
                    <button
                      className={`button is-primary`}
                      onClick={() => setRegisterCall(true)}
                    >
                      Registrar llamada
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {history.length !== 0 && (
              <div className="notification is-white">
                <CallHistoryList
                  calls={history}
                  economyActivtyId={economyActivity._id}
                />
              </div>
            )}

            <InfoItem
              field="Sitio web del trabajo"
              value={economyActivity?.website}
            />
            <InfoItem
              field="Dirección del trabajo"
              value={economyActivity?.address}
            />

            <hr />
            <p className="has-text-weight-bold">Otros ingresos</p>
            <br />
            {economyActivity?.otherEntry.map((e, i) => {
              let name =
                e === "otros"
                  ? economyActivity?.otherEntryName
                  : e.replaceAll("_", " ");
              return (
                <InfoItem key={e} field={`Ingreso ${i + 1}`} value={name} />
              );
            })}
            <br />
            <p className="is-size-3 has-text-weight-bold">Comprobar ingresos</p>
            <p className="is-size-4">
              Da clic las cantidades de la derecha para introducit la cantidad
              comprobada.
            </p>
            <div
              className="box mx-4 my-6"
              style={{ width: "800px", background: "#F6F8F9" }}
            >
              <div className="is-flex">
                <div className="is-size-4	 has-text-right is-fullwidth">
                  <span className="has-text-weight-bold">
                    Ingresos declarados
                  </span>
                  <p>
                    Ingreso Mensual
                    <span className="has-text-weight-bold has-text-primary">
                      +
                      <FormatCurrency value={economyActivity?.mensualEntry} />
                    </span>
                  </p>
                  <p>
                    Otros ingresos
                    <span className="has-text-weight-bold has-text-primary">
                      +
                      <FormatCurrency
                        value={economyActivity?.otherEntryAmmount ?? 0}
                      />
                    </span>
                  </p>
                  <p>
                    Total de ingresos
                    <span className="has-text-weight-bold has-text-success">
                      =
                      <FormatCurrency
                        value={
                          (economyActivity?.mensualEntry ?? 0) +
                          (economyActivity?.otherEntryAmmount ?? 0)
                        }
                      />
                    </span>
                  </p>
                  <hr className="is-marginless has-background-grey" />
                  <p>
                    Gastos
                    <span className="has-text-weight-bold has-text-danger">
                      -
                      <FormatCurrency
                        value={economyActivity?.totalMonthExpenses}
                      />
                    </span>
                  </p>
                  <hr className="is-marginless has-background-grey" />
                  <p>
                    Total
                    <span className="has-text-weight-bold has-text-success">
                      =
                      <FormatCurrency
                        value={
                          (economyActivity?.otherEntryAmmount ?? 0) +
                          (economyActivity?.mensualEntry ?? 0) -
                          (economyActivity?.totalMonthExpenses ?? 0)
                        }
                      />
                    </span>
                  </p>
                </div>
                <div
                  style={{
                    width: "2px",
                    background: "#363636",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                ></div>
                <div className="is-size-4	 has-text-left is-fullwidth">
                  <span className="has-text-weight-bold">
                    Ingresos comprobados
                  </span>
                  <p
                    className="is-underlined"
                    onClick={() => setCompareEntry(true)}
                  >
                    <span className="has-text-weight-bold has-text-primary is-pointer">
                      +<FormatCurrency value={mensualEntryComparation} />
                    </span>
                    Ingreso mensual
                  </p>
                  <p
                    className="is-underlined"
                    onClick={() => setCompareOtherEntry(true)}
                  >
                    <span className="has-text-weight-bold has-text-primary is-pointer">
                      +<FormatCurrency value={otherEntryComparation} />
                    </span>
                    Otros ingresos
                  </p>
                  <p>
                    <span className="has-text-weight-bold has-text-success">
                      =
                      <FormatCurrency
                        value={
                          parseInt(mensualEntryComparation) +
                          parseInt(otherEntryComparation)
                        }
                      />
                    </span>
                    Total de ingresos
                  </p>
                  <hr className="is-marginless has-background-grey" />
                  <p>
                    <span className="has-text-weight-bold has-text-danger">
                      <FormatCurrency
                        value={economyActivity?.totalMonthExpenses}
                      />
                    </span>
                    Gastos
                  </p>
                  <hr className="is-marginless has-background-grey" />
                  <p>
                    <span className="has-text-weight-bold has-text-primary">
                      =
                      <FormatCurrency
                        value={
                          parseInt(otherEntryComparation) +
                          parseInt(mensualEntryComparation) -
                          parseInt(economyActivity?.totalMonthExpenses)
                        }
                      />
                    </span>
                    Total
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <Dialog isActive={registerCall} close={() => setRegisterCall(false)}>
        <RegisterCallForm
          reference={{
            name: economyActivity?.workSource,
            phone: economyActivity?.jobPhone,
          }}
          call={call}
          onCall={onCall}
          stopCall={stopCall}
          time={time}
          renter={renter}
        />
      </Dialog>

      <Dialog isActive={compareEnrty} close={() => setCompareEntry(false)}>
        <div className="notification is-whte" style={{ minWidth: "600px" }}>
          <p className="title is-3 has-text-centered">
            Comparativa de ingresos:
          </p>
          <div className="is-flex is-justify-content-center">
            <div className="field">
              <label className="label  is-medium">Comprobable</label>
              <div className="control">
                <input
                  className="input  is-medium"
                  type="number"
                  value={mensualEntryComparation}
                  style={{ maxWidth: "120px" }}
                  onChange={({ target: { value } }) =>
                    setMensualEntryComparation(value)
                  }
                  onBlur={({ target: { value } }) =>
                    updateMensualEntryComparation(value)
                  }
                />
              </div>
            </div>

            <span className="icon  is-align-self-center">
              <i className="fas fa-arrows-alt-h"></i>
            </span>

            <div className="field">
              <label className="label  is-medium">Indicado</label>
              <div className="control">
                <input
                  className="input  is-medium"
                  type="text"
                  value={economyActivity?.mensualEntry || 0}
                  readOnly
                  style={{ maxWidth: "120px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        isActive={compareOtherEnrty}
        close={() => setCompareOtherEntry(false)}
      >
        <div className="notification is-whte" style={{ minWidth: "600px" }}>
          <p className="title is-3 has-text-centered">
            Comparativa de ingresos:
          </p>
          <div className="is-flex is-justify-content-center">
            <div className="field">
              <label className="label  is-medium">Comprobable</label>
              <div className="control">
                <input
                  className="input  is-medium"
                  type="text"
                  value={otherEntryComparation}
                  style={{ maxWidth: "120px" }}
                  onChange={({ target: { value } }) =>
                    setOtherEntryComparation(value)
                  }
                  onBlur={({ target: { value } }) =>
                    updateOtherEntryComparation(value)
                  }
                />
              </div>
            </div>
            <span className="icon  is-align-self-center">
              <i className="fas fa-arrows-alt-h"></i>
            </span>

            <div className="field">
              <label className="label  is-medium">Indicado</label>
              <div className="control">
                <input
                  className="input is-medium"
                  type="number"
                  value={economyActivity?.otherEntryAmmount || 0}
                  readOnly
                  style={{ maxWidth: "120px" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
