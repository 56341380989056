import React, { useState } from "react";
import { CreateRealEstate, UpdateRealEstate } from "../../services/Admin";
import { useFormik } from "formik";
import * as Yup from "yup";
const realestateFields = {
  name: {
    id: "name",
    type: "text",
    value: "",
    label: "Nombre o Razón Social",
  },
  rfc: {
    id: "rfc",
    type: "text",
    value: "",
    label: "RFC",
  },
  email: {
    id: "email",
    type: "email",
    value: "",
    label: "Correo electrónico",
  },
  phone: {
    id: "phone",
    type: "tel",
    value: "",
    label: "Teléfono",
  },
  address: {
    is: "is-12",
    id: "address",
    type: "text",
    value: "",
    label: "Dirección",
  },
  contactName: {
    contact: true,
    is: "is-4",
    id: "contactName",
    type: "text",
    value: "",
    label: "Nombre de Contacto",
  },
  contactEmail: {
    contact: true,
    is: "is-4",
    id: "contactName",
    type: "email",
    value: "",
    label: "Correo de Contacto",
  },
  contactPhone: {
    contact: true,
    is: "is-4",
    id: "contactPhone",
    type: "tel",
    value: "",
    label: "Teléfono de Contacto",
  },
};

export const AddRealEstateForm = (props) => {
  let { realEstate } = props;
  const [realEstateData, setrealEstateData] = useState({
    ...Object.keys(realestateFields).reduce((acc, key) => {
      acc[key] = realestateFields[key].value;
      return acc;
    }, {}),
    ...realEstate,
  });

  const [isLoading, setLoading] = useState("");

  const formik = useFormik({
    initialValues: {
      ...realEstateData,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("El nombre es requerido"),
      rfc: Yup.string().required("El RFC es requerido"),
      email: Yup.string().email().required("El correo es requerido"),
      phone: Yup.string().required("El teléfono es requerido"),
      address: Yup.string().required("La dirección es requerida"),
      contactName: Yup.string().required("El nombre de contacto es requerido"),
      contactEmail: Yup.string().required("El correo de contacto es requerido"),
      contactPhone: Yup.string().required(
        "El teléfono de contacto es requerido"
      ),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleSubmit = async (values) => {
    setLoading("is-loading");
    if (realEstate) {
      let { data } = await UpdateRealEstate(values, realEstate._id);
      if (data) {
        props.close(data, "update");
      }
    } else {
      let { data } = await CreateRealEstate(values);
      setLoading("");
      props.close(data);
    }
  };

  return (
    <div className="notification is-white">
      <p className="title is-3 has-text-centered">Agregar inmobiliaria</p>
      <br />
      <form onSubmit={formik.handleSubmit}>
        <div className="columns is-multiline">
          {Object.keys(realestateFields).map((key) => (
            <div
              key={key}
              className={`column ${
                realestateFields[key]?.is ? realestateFields[key]?.is : "is-6"
              } 
              ${
                realestateFields[key]?.contact
                  ? "has-background-primary-light mt-2"
                  : ""
              }`}
            >
              <div className="field">
                <label className="label" htmlFor={key}>
                  {realestateFields[key].label}
                </label>
                <div className="control">
                  <input
                    id={key}
                    name={key}
                    className="input is-medium"
                    type={realestateFields[key].type}
                    value={formik.values[key]}
                    onChange={formik.handleChange}
                  />
                  {
                    formik.errors[key] && formik.touched[key] ? (
                      <p className="help is-danger">{formik.errors[key]}</p>
                    ) : null // if the field is not touched, don't show the error
                  }
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          className={`button is-primary is-fullwidth ${isLoading}`}
          type="submit"
        >
          {realEstate ? "Actualizar" : "Crear"}
        </button>
      </form>
    </div>
  );
};
