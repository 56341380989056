import React, { useContext, useState } from "react";
import { InfoItem } from "../../InfoItem";
import { InvestigationContext } from "..";
import { StageNavbar } from "../../StageNavbar";
import { UpdateField } from "services/Procedures";
import { statesNames } from "constants/states";

export function GeneralData({
  generalData: generalDataProp,
  isBusiness,
  isCollapsed = false,
  collapse,
}) {
  const { renter, openFile, attachFile, openValidateForm } =
    useContext(InvestigationContext);

  const [generalData, setGeneralData] = useState(generalDataProp);

  const updateField = async (field, value) => {
    await UpdateField({
      modelName: "generalData",
      query: {
        _id: generalData._id,
      },
      update: {
        [field]: value,
      },
    });
  };

  const updateFieldOnBlur = async (e) => {
    setGeneralData({
      ...generalData,
      [e.target.id]: e.target.value,
    });
    updateField(e.target.id, e.target.value);
  };

  return (
    <div>
      <StageNavbar>
        <p
          className="is-size-5 has-text-weight-bold as-cursor no-selectable"
          onClick={() => collapse(!isCollapsed)}
        >
          <span className="icon">
            {isCollapsed ? (
              <i className="fas fa-caret-right" />
            ) : (
              <i className="fas fa-caret-down" />
            )}
          </span>
          <span>
            Información General{" "}
            {generalData.fulfillment != null ? (
              <span>
                <span
                  className={`icon is-rounded mr-3 ${
                    generalData.fulfillment === true
                      ? "has-text-success"
                      : "has-text-is-success "
                  }`}
                >
                  {generalData.fulfillment === true ? (
                    <i className="fas fa-check" />
                  ) : (
                    <i className="fas fa-check" />
                  )}
                </span>
              </span>
            ) : (
              ""
            )}
          </span>
        </p>
        {
          // VALIDATE BUTTON
          !isCollapsed && (
            <div className="actions">
              <button
                className="button is-light has-text-weight-bold"
                onClick={() => openValidateForm("generalData")}
              >
                <span>Validar</span>
                <span className="icon">
                  <i className="fas fa-check" />
                </span>
              </button>
            </div>
          )
        }
      </StageNavbar>
      <br />
      {!isCollapsed && (
        <div>
          <p className="has-text-weight-bold">Documentos:</p>
          <br />
          <div className="tags is-sticky">
            <span
              className="tag is-primary is-rounded is-medium has-text-white is-pointer"
              onClick={() => {
                openFile(generalData.ineFile);
              }}
              onDoubleClick={() => {
                attachFile("generalData", "ineFile");
              }}
            >
              <span className="icon">
                <i className="fa fa-id-card" />
              </span>
              <span>INE</span>
            </span>

            <span
              className="tag is-primary is-rounded is-medium has-text-white is-pointer"
              onClick={() => {
                openFile(generalData.domFile);
              }}
              onDoubleClick={() => {
                attachFile("generalData", "domFile");
              }}
            >
              <span className="icon">
                <i className="fas fa-file-invoice" />
              </span>
              <span>Comprobante de domicilio</span>
            </span>

            {renter.isBusiness && (
              <div>
                {generalData.actFile?.map((f, i) => (
                  <span
                    key={i}
                    className="tag is-link is-rounded is-medium has-text-white is-pointer"
                    onClick={() => {
                      openFile([f]);
                    }}
                  >
                    <span className="icon">
                      <i className="fa fa-file-alt" />
                    </span>
                    <span>
                      Acta:
                      {i + 1}
                    </span>
                  </span>
                ))}

                <span
                  className="tag is-primary is-rounded is-medium has-text-white is-pointer mr-2"
                  onDoubleClick={() => {
                    attachFile("generalData", "actFile");
                  }}
                >
                  <span className="icon">
                    <i className="fa fa-file-alt" />
                  </span>
                  <span>Subir Acta(s)</span>
                </span>
              </div>
            )}
            <span
              className={`tag ${
                generalData.curpFile.length !== 0 ? "is-info" : "is-danger"
              } is-rounded is-medium has-text-white is-pointer`}
              onClick={() => {
                if (generalData.curpFile.length !== 0) {
                  openFile(generalData.curpFile);
                } else {
                  attachFile("generalData", "curpFile");
                }
              }}
              onDoubleClick={() => {
                attachFile("generalData", "curpFile");
              }}
            >
              <span className="icon">
                {generalData.curpFile.length !== 0 ? (
                  <i className="fas fa-check-circle" />
                ) : (
                  <i className="fas fa-cloud-upload-alt" />
                )}
              </span>
              <span>
                CURP
                {generalData.curpFile.length}
              </span>
            </span>
            <span
              className={`tag ${
                generalData.curpValidationFile.length !== 0
                  ? "is-info"
                  : "is-danger"
              } is-rounded is-medium has-text-white is-pointer`}
              onClick={() => {
                if (generalData.curpValidationFile.length !== 0) {
                  openFile(generalData.curpValidationFile);
                } else {
                  attachFile("generalData", "curpValidationFile");
                }
              }}
              onDoubleClick={() => {
                attachFile("generalData", "curpValidationFile");
              }}
            >
              <span className="icon">
                {generalData.curpValidationFile.length !== 0 ? (
                  <i className="fas fa-check-circle" />
                ) : (
                  <i className="fas fa-cloud-upload-alt" />
                )}
              </span>
              <span>Validación CURP</span>
            </span>
            <span
              className={`tag ${
                generalData.ineValidationFile.length !== 0
                  ? "is-info"
                  : "is-danger"
              } is-rounded is-medium has-text-white is-pointer`}
              onClick={() => {
                if (generalData.ineValidationFile.length !== 0) {
                  openFile(generalData.ineValidationFile);
                } else {
                  attachFile("generalData", "ineValidationFile");
                }
              }}
              onDoubleClick={() => {
                attachFile("generalData", "ineValidationFile");
              }}
            >
              <span className="icon">
                {generalData.ineValidationFile.length !== 0 ? (
                  <i className="fas fa-check-circle" />
                ) : (
                  <i className="fas fa-cloud-upload-alt" />
                )}
              </span>
              <span>Validación INE</span>
            </span>
          </div>
          <hr />
          {isBusiness() && (
            <>
              <div className="columns my-2">
                <div className="column is-3">
                  <InfoItem
                    field="Razón social"
                    value={generalData?.businessName}
                    disabled={false}
                    onBlur={(e) => {
                      updateField("businessName", e.target.value);
                    }}
                  />
                </div>
              </div>
              <p className="has-text-weight-bold">Representante legal</p>
            </>
          )}
          <div className="columns">
            <div className="column is-3">
              <InfoItem
                field="Nombre"
                value={generalData?.firstName}
                disabled={false}
                onBlur={(e) => {
                  updateField("firstName", e.target.value);
                }}
              />
            </div>
            <div className="column is-3">
              <InfoItem
                field="Segundo Nombre"
                value={generalData?.secondName}
                disabled={false}
                name="secondName"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-3">
              <InfoItem
                field="Apellido Paterno"
                value={generalData?.lastName1}
                disabled={false}
                name="lastName1"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-3">
              <InfoItem
                field="Apellido Materno"
                value={generalData?.lastName2}
                disabled={false}
                name="lastName2"
                onBlur={updateFieldOnBlur}
              />
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-3">
              <div className="field">
                <label className="label">Nacionalidad</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id={isBusiness() ? "businessNationality" : "nationality"}
                      value={
                        isBusiness()
                          ? generalData?.businessNationality
                          : generalData?.nationality
                      }
                      onChange={updateFieldOnBlur}
                    >
                      <option value="" disabled>
                        Selecciona una opción
                      </option>
                      <option value="mexicana">Mexicana</option>
                      <option value="extranjera">Extranjera</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-3">
              <InfoItem
                field="CURP o ID"
                value={generalData?.curp}
                disabled={false}
                name="curp"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-3">
              <InfoItem
                field="RFC"
                value={generalData?.rfc}
                disabled={false}
                onBlur={(e) => {
                  updateField("rfc", e.target.value);
                }}
              />
            </div>
            <div className="column is-3">
              <div className="field">
                <label className="label">Estado civil</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="maritialStatus"
                      value={generalData.maritialStatus}
                      onChange={updateFieldOnBlur}
                    >
                      <option value="" disabled>
                        Selecciona una opción
                      </option>
                      <option value="soltero">Soltero(a)</option>
                      <option value="casado">Casado(a)</option>
                      <option value="unionlibre">Union libre</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-3">
              <InfoItem
                field="Nombre del cónyuge"
                value={generalData?.spouseName}
                disabled={false}
                name="spouseName"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-3">
              <InfoItem
                field="Teléfono del cónyuge "
                value={generalData?.spousePhone}
                disabled={false}
                name="spousePhone"
                onBlur={updateFieldOnBlur}
              />
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-3">
              <InfoItem
                type="date"
                field="Fecha de nacimiento"
                //value={moment(generalData?.birthdate).format("DD MMMM YYYY")}
                value={generalData?.birthdate}
                disabled={false}
                name="birthdate"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-3">
              <InfoItem
                field="Lugar de nacimiento"
                value={generalData?.birthPlace}
                disabled={false}
                name="birthPlace"
                onBlur={updateFieldOnBlur}
              />
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-3">
              <InfoItem
                field="Télefono"
                value={renter?.user?.phone}
                disabled={false}
                name="phone"
                onBlur={updateFieldOnBlur}
              />
            </div>
          </div>
          <p className="has-text-weight-bold">Domicilio</p>
          <div className="columns is-multiline">
            <div className="column is-3">
              <InfoItem
                field="Calle"
                value={generalData?.street}
                disabled={false}
                name="street"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-3">
              <InfoItem
                field="Número"
                value={generalData?.number}
                disabled={false}
                name="number"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-3">
              <InfoItem
                field="Ciudad o Municipio"
                value={generalData?.city}
                disabled={false}
                name="city"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-3">
              <div className="field">
                <label className="label">Estado</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="state"
                      value={generalData.state}
                      onChange={updateFieldOnBlur}
                    >
                      <option value="" disabled>
                        Selecciona un estado
                      </option>
                      {statesNames.map((s) => (
                        <option key={s} value={s}>
                          {s.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-3">
              <InfoItem
                field="Código Postal"
                value={generalData?.postalCode}
                disabled={false}
                name="postalCode"
                onBlur={updateFieldOnBlur}
              />
            </div>
          </div>
          <div className="columns is-multiline">
            <div className="column is-3">
              <div className="field">
                <label className="label">Estado</label>
                <div className="control">
                  <div className="select is-fullwidth">
                    <select
                      id="houseStatus"
                      value={generalData.houseStatus}
                      onChange={updateFieldOnBlur}
                    >
                      <option value="" disabled>
                        Selecciona un estado
                      </option>
                      {["rentado", "propio", "otro"].map((s) => (
                        <option key={s} value={s}>
                          {s.toUpperCase()}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-2">
              <InfoItem
                field="Contacto"
                value={generalData?.houseContact}
                disabled={false}
                name="houseContact"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-2">
              <InfoItem
                field="Dueño"
                value={generalData?.houseOwner}
                disabled={false}
                name="houseOwner"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-2">
              <InfoItem
                field="Monto de renta"
                value={generalData?.rentAmmount}
                disabled={false}
                name="rentAmmount"
                onBlur={updateFieldOnBlur}
              />
            </div>
            <div className="column is-2">
              <InfoItem
                field="Tiempo"
                value={generalData?.timeInHouse}
                disabled={false}
                name="timeInHouse"
                onBlur={updateFieldOnBlur}
              />
            </div>
          </div>
          {/* <InfoItem
            field="Estado"
            value={
              generalData?.houseStatus === "otro"
                ? generalData?.otherStatus
                : generalData?.houseStatus
            }
          /> */}
          <hr />
        </div>
      )}
    </div>
  );
}
