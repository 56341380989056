import React, { useState, useContext } from "react";
import Moment from "react-moment";
import {
  UpdateCallHistory,
  UpdateJobCallHistory,
} from "../../services/Procedures";
import { InvestigationContext } from "../ProcedureDetail";

export const CallHistoryItem = ({
  showActions = true,
  isSmall = false,
  call,
  reference,
  economyActivtyId,
  remove,
}) => {
  const { openCallHistoryItem } = useContext(InvestigationContext);

  var sec_num = parseInt(call.duration);
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  const [answered, setAnswered] = useState(call.answered);

  const updateAnswered = async (value) => {
    if (reference) {
      await UpdateCallHistory(reference, {
        id: call.id,
        update: {
          "history.$.answered": value,
        },
      });
    } else {
      await UpdateJobCallHistory(economyActivtyId, {
        id: call.id,
        update: {
          "history.$.answered": value,
        },
      });
    }
    setAnswered(value);
  };

  const deleteCall = async (callToDelete) => {
    if (reference) {
      await UpdateCallHistory(reference, {
        id: callToDelete.id,
        update: {
          "history.$.onTrash": true,
        },
      });
    } else {
      await UpdateJobCallHistory(economyActivtyId, {
        id: callToDelete.id,
        update: {
          "history.$.onTrash": true,
        },
      });
    }
    remove(callToDelete);
  };

  const AnsweredActions = () => {
    return (
      <div className="buttons">
        <button
          className={`button is-circle is-small is-danger ${
            !answered ? "" : "is-outlined "
          }`}
          onClick={() => (showActions ? updateAnswered(false) : null)}
        >
          <span className="icon">
            <i className="fas fa-times"></i>
          </span>
        </button>
        <button
          className={`button is-circle is-small is-success ${
            answered ? "" : "is-outlined"
          }`}
          onClick={() => (showActions ? updateAnswered(true) : null)}
        >
          <span className="icon">
            <i className="fas fa-check"></i>
          </span>
        </button>
      </div>
    );
  };

  const Answered = () => {
    return (
      <div className="buttons">
        {!answered ? (
          <button className={`button is-circle is-small is-danger`}>
            <span className="icon">
              <i className="fas fa-times"></i>
            </span>
          </button>
        ) : (
          <button className={`button is-circle is-small is-success`}>
            <span className="icon">
              <i className="fas fa-check"></i>
            </span>
          </button>
        )}
      </div>
    );
  };

  return (
    <tr>
      <td>{showActions ? <AnsweredActions /> : <Answered />}</td>
      <td className={isSmall ? "is-size-6" : ""}>
        <Moment format="DD MMMM YYYY hh:mm">{call.startAt}</Moment>{" "}
      </td>
      <td className={isSmall ? "is-size-6" : ""}>
        {hours}:{minutes}:{seconds}{" "}
      </td>

      <td className={isSmall ? "is-size-6" : ""}>
        <div className="buttons">
          <button
            className="button is-circle is-small is-outlined is-primary"
            data-tooltip="Ver detalle"
            onClick={() => openCallHistoryItem(call)}
          >
            <span className="icon">
              <i className="fas fa-window-maximize"></i>
            </span>
          </button>
          {showActions && (
            <button
              className="button is-circle is-small is-outlined is-danger"
              data-tooltip="Eliminar llamada"
              onClick={() => deleteCall(call)}
            >
              <span className="icon">
                <i className="fas fa-trash-alt"></i>
              </span>
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};
