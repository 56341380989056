import React from 'react';

export const AuthStatus = {
  Uninitialized: 0,
  Unauthenticated: 1,
  Authenticated: 2,
}

export const AuthContext = React.createContext({
  currentUser: null,
  authStatus: AuthStatus.Uninitialized
});

export default AuthContext;