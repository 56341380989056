import React from "react";
import { NavLink } from "react-router-dom";

export const FormsList = () => {
  return (
    <div className="navbar">
      <div className="navbar-start">
        <p className="is-size-5 has-text-weight-bold">
          Formularios de investigaciones
        </p>
      </div>
      <div className="navbar-end">
        <div className="navbar-item">
          <div className="buttons">
            <NavLink to="nuevo" className="button is-primary is-small">
              <span>Crear formulario</span>
              <span className="icon">
                <i className="fa fa-plus"></i>
              </span>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
