import React, { useState } from "react";
import { setToken, setCurrentUser } from "../../services/Staff";
import {
  SendPassword,
  AuthPartner,
  CheckPartner,
} from "../../services/Partners";

export const PartnersSignInForm = (props) => {
  const [signUpData, setSignUpData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [error, showError] = useState("");

  const [showPasswordField, setShowpasswordField] = useState(false);
  const [showCreatePassword, setShowCreatePassword] = useState(false);

  const sendPassword = async () => {
    const { password, confirmPassword } = signUpData;
    if (password !== confirmPassword) {
      showError("Las contraseñas no coinciden");
      return;
    }
    const res = await SendPassword({
      ...signUpData,
    }).catch((e) => {
      showError("Ha ocurrido un error.");
    });

    if (res?.data) {
      auth();
      showError("");
    }
  };

  const checkPartner = async () => {
    let { data } = await CheckPartner({
      email: signUpData.email,
    });

    if (data) {
      setShowpasswordField(true);
    } else {
      setShowCreatePassword(true);
    }
  };

  const auth = async () => {
    try {
      // eslint-disable-next-line no-undef
      let { data } = await AuthPartner({
        ...signUpData,
      });
      setToken(data.token);
      setCurrentUser({
        type: "partner",
        ...data.userData,
      });
      window.location = "/panel";
    } catch (error) {
      showError("Usuario o contraseña incorrectos.");
      alert();
      return true;
    }
  };

  const signIn = async (e) => {
    e.preventDefault();
    auth();
  };

  return (
    <div>
      <div className="has-text-centered">
        <img src="/assets/logo.png" alt="Invix" className="logo is-centered" />
      </div>
      <br />
      <p className="title is-3 has-text-centered">Iniciar sesión</p>
      {error !== "" ? (
        <div>
          <div className="notification is-danger has-text-white">{error}</div>
          <br />
        </div>
      ) : null}
      {showCreatePassword && (
        <div className="notification is-link has-text-white has-text-primary has-text-weight-bold mb-4">
          Crea una contraseña para acceder.
        </div>
      )}
      <form onSubmit={signIn}>
        <div className="field">
          <label className="label">Correo electrónico</label>
          <div className="control">
            <input
              className="input is-medium"
              type="email"
              placeholder="ejemplo@ejemplo.com"
              value={signUpData.email}
              onChange={(e) =>
                setSignUpData({
                  ...signUpData,
                  email: e.target.value,
                })
              }
            />
          </div>
        </div>

        {(showPasswordField || showCreatePassword) && (
          <div className="field">
            <label className="label">Contraseña</label>
            <div className="control">
              <input
                className="input is-medium"
                type="password"
                placeholder="***"
                value={signUpData.password}
                onChange={(e) =>
                  setSignUpData({
                    ...signUpData,
                    password: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}

        {showCreatePassword && (
          <div className="field">
            <label className="label">Confirmar Contraseña</label>
            <div className="control">
              <input
                className="input is-medium"
                type="password"
                placeholder="***"
                value={signUpData.confirmPassword}
                onChange={(e) =>
                  setSignUpData({
                    ...signUpData,
                    confirmPassword: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
        <br />
        {!showPasswordField && !showCreatePassword && (
          <button
            className="button is-primary is-fullwidth"
            type="button"
            onClick={checkPartner}
          >
            Siguiente
          </button>
        )}

        {showCreatePassword && (
          <button
            className="button is-primary is-fullwidth"
            type="button"
            onClick={sendPassword}
          >
            Crear contraseña
          </button>
        )}

        {showPasswordField && (
          <button className="button is-primary is-fullwidth" onClick={signIn}>
            Iniciar sesión
          </button>
        )}
      </form>
      <br />
    </div>
  );
};
