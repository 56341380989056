import React from "react";
import "./style.scss";
import moment from "moment/moment";
import { STATUS } from "../../../constants/procedure";

export const ProcedureListItem = ({ procedure, createNewProcedure }) => {
  return (
    <div
      key={procedure?._id}
      className="columns box is-shadowless procedure-list-item is-multiline is-flex is-marginless pt-0 pb-0 is-vcentered"
    >
      <div className="column is-2 is-6-mobile mt-4">
        <div className="pt-0" style={{ marginTop: "-22px" }}>
          <div className="is-size-5 has-text-weight-bold">
            {moment(procedure?.createdAt).format("D")}
          </div>
          <div className="is-size-7 has-text-weight-bold">
            de {moment(procedure?.createdAt).format("MMMM YY")}
          </div>
        </div>
      </div>

      <div className="column is-4 is-6-mobile has-text-weight-bold is-align-items-center	 is-flex">
        <div>
          {procedure?.renter?.user?.firstName}{" "}
          {procedure?.renter?.user?.lastName}
        </div>
      </div>

      <div className="column is-1 is-4-mobile has-text-weight-bold is-align-items-center is-flex">
        <div className="tag is-link is-pointer">{procedure?.type}</div>
      </div>
      <div className="column is-2 is-4-mobile has-text-weight-bold is-align-items-center is-flex">
        <div className={STATUS[procedure?.status].classes}>
          {STATUS[procedure?.status].label}
        </div>
      </div>
      <div className="column is-2 is-4-mobile has-text-weight-bold is-align-items-center is-flex">
        <div>
          {procedure?.tags?.map((tag) => (
            <span key={tag} className="tag procedure-tag is-link mr-2">
              <span>{tag}</span>
            </span>
          ))}
        </div>
      </div>
      <div className="column is-1 is-4-mobile has-text-weight-bold is-align-items-center is-flex">
        {!procedure?.uncreated && (
          <a
            href={`/panel/tramites/${procedure?._id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="button is-link is-small"
          >
            <span>Ver detalle</span>
          </a>
        )}
        {procedure?.uncreated && (
          <button
            onClick={() => createNewProcedure(procedure)}
            target="_blank"
            rel="noopener noreferrer"
            className="button is-link is-small"
          >
            Crear investigación
          </button>
        )}
      </div>
    </div>
  );
};
