import React, { useCallback, useEffect, useState } from "react";
import { Padding } from "../../../../components/Padding";
import { Panel } from "../../../../components/Panel";
import { PartnerProcedures } from "../../../../services/Partners";
import { ProcedureListItem } from "../../../../components/ProceduresList/ProcedureListItem";
import { ProceduresToolbar } from "components/ProceduresToolbar";

export const Procedures = () => {
  const [procedures, setProcedures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [proceduresFiltered, setFilterResult] = useState([]);
  const [filters, setFilters] = useState({
    search: "",
    status: 1,
    type: "",
  });

  const getProcedures = useCallback(async () => {
    setLoading(true);
    const { data } = await PartnerProcedures();
    setProcedures(data);
    setLoading(false);
  }, []);

  useEffect(() => {
    getProcedures();
  }, [getProcedures]);

  useEffect(() => {
    getProcedures();
  }, [filters.status, getProcedures]);

  useEffect(() => {
    if (filters.search.length < 3) setFilterResult(procedures);

    if (filters.search.length > 3) {
      setFilterResult(
        procedures.filter(
          (p) =>
            p.renter.user.firstName?.includes(filters.search) ||
            p.renter.user.phone?.includes(filters.search) ||
            p.renter.user.email?.includes(filters.search) ||
            p.renter.user.lastName?.includes(filters.search)
        )
      );
    }
  }, [filters, procedures]);

  return (
    <Padding padding="16px 30px">
      {/* TODO: tools */}
      <div className="columns">
        <div className="column is-12">
          <Panel>
            <h1 className="title is-size-5">Investigaciones</h1>
            <ProceduresToolbar
              filters={filters}
              setFilters={setFilters}
              loading={loading}
              setLoading={setLoading}
            />
            <div className="columns is-hidden-mobile">
              <div className="column is-2 has-text-weight-medium secondary-text is-size-7">
                Fecha
              </div>
              <div className="column is-4 has-text-weight-medium secondary-text is-size-7">
                Nombre
              </div>
              <div className="column is-2 has-text-weight-medium secondary-text is-size-7">
                Tipo
              </div>
              <div className="column is-2 has-text-weight-medium secondary-text is-size-7">
                Estatus
              </div>
              <div className="column is-2 has-text-weight-medium secondary-text is-size-7">
                Acciones
              </div>
            </div>
            {proceduresFiltered?.map((procedure) => (
              <ProcedureListItem key={procedure?._id} procedure={procedure} />
            ))}
          </Panel>
        </div>
      </div>
    </Padding>
  );
};
