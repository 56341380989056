import { Input } from "components/Form";
import useCopyToClipboard from "hooks/useClipBoard";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { NotificationContext } from "services/NotificationService";
import { GetProceduresByFilter } from "services/Procedures";
import AuthContext from "services/authService/context";
const Status = ["Creada", "En Proceso", "Con observaciones", "Finalizada"];

const QUERIES = [
  { id: "", name: "Descargar", disabled: true, onlyAdmin: false },
  { id: "all", name: "Todas", onlyAdmin: false },
  { id: "new", name: "Nuevas", onlyAdmin: true },
  { id: "on_proccess", name: "En proceso", onlyAdmin: false },
  { id: "with_issues", name: "Con observaciones", onlyAdmin: false },
  { id: "finished", name: "Terminadas", onlyAdmin: false },
  {
    id: "credit_history_auth",
    name: "Consulta de HD autorizada",
    onlyAdmin: true,
  },
];
export const ProceduresToolbar = ({
  setFilters,
  filters,
  loading,
  setLoading,
}) => {
  const { isPartners, currentUser } = useContext(AuthContext);
  const [value, copy] = useCopyToClipboard();
  const { createNotification } = useContext(NotificationContext);
  const [results, setResults] = useState();
  const [querySelected, selectQuery] = useState(QUERIES[0]);

  const getProcedures = useCallback(
    async (query) => {
      let { data } = await GetProceduresByFilter(query);
      setResults(data);
      setLoading(false);
    },
    [setLoading]
  );

  useEffect(() => {
    if (querySelected.id) {
      getProcedures(querySelected.id);
    }
  }, [getProcedures, querySelected.id]);

  const download = () => {
    const data = results.map((item) => {
      return [
        moment(item.createdAt).format("DD-MM-YYYY"),
        moment(item.finishedAt).format("DD-MM-YYYY"),
        Status[item.status],
        item.renter.user.firstName,
        item.renter.user.lastName,
        item.renter.user.email,
        item.renter.user.phone,
        moment(item.createdAt).format("DD-MM-YYYY HH:mm"),
      ];
    });
    const rowsData = [
      [
        "Fecha de creación",
        "Fecha de finalización",
        "Estatus",
        "Nombres",
        "Apellidos",
        "Email",
        "Teléfono",
        "Fecha de autorización por nip",
      ],
      ...data,
    ];
    const csvContent =
      "data:text/csv;charset=utf-8," +
      rowsData.map((r) => r.join(",")).join("\n");
    const urlEncoded = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("id", urlEncoded);
    link.setAttribute("href", urlEncoded);
    link.setAttribute("download", "investigaciones.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <div className="columns">
      <div className="column is-4 is-align-items-center	is-flex">
        <Input
          type="text"
          placeholder="Buscar por nombre, correo y teléfono"
          onChange={(e) =>
            setFilters({
              ...filters,
              search: e.target.value,
            })
          }
          value={filters.search}
        />
      </div>
      {!isPartners && (
        <div className="column is-align-items-center is-flex">
          <div className="tabs is-toggle is-small">
            <ul>
              <li>
                <a
                  href="#!"
                  onClick={() => setFilters({ ...filters, status: -1 })}
                  className={`${filters.status === -1 ? "is-active" : ""}`}
                >
                  Pendientes
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  onClick={() => setFilters({ ...filters, status: 0 })}
                  className={`${filters.status === 0 ? "is-active" : ""}`}
                >
                  Nuevas
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  onClick={() => setFilters({ ...filters, status: 1 })}
                  className={`${filters.status === 1 ? "is-active" : ""}`}
                >
                  En proceso
                </a>
              </li>
              <li>
                <a
                  href="#!"
                  onClick={() => setFilters({ ...filters, status: 3 })}
                  className={`${filters.status === 3 ? "is-active" : ""}`}
                >
                  Terminadas
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="column is-align-items-center is-flex">
        <div className="select is-primary is-small">
          <select
            name="query"
            id="query"
            value={querySelected.id}
            onChange={(e) => {
              selectQuery(QUERIES.find((item) => item.id === e.target.value));
              getProcedures(
                QUERIES.find((item) => item.id === e.target.value).id
              );
            }}
          >
            {QUERIES.map((query) => (
              <>
                {((isPartners && !query.onlyAdmin) || !isPartners) && (
                  <option
                    key={query.id}
                    value={query.id}
                    disabled={query.disabled}
                  >
                    {query.name}
                  </option>
                )}
              </>
            ))}
          </select>
        </div>
        <button
          disabled={querySelected.id === ""}
          onClick={download}
          target="_blank"
          rel="noopener noreferrer"
          className="button is-primary is-small ml-2"
        >
          <span>Descargar</span>
          <span className="icon">
            <i className="fas fa-cloud-download-alt"></i>
          </span>
        </button>
        {loading && <button className="button is-white is-loading"></button>}
      </div>
      <div className="column is-2">
        <button
          onClick={() => {
            copy(`https://invix.mx/crear-cuenta?reference=${currentUser._id}`);
            createNotification({
              message: "Link para el inquilino copiado!",
            });
          }}
          target="_blank"
          rel="noopener noreferrer"
          className="button is-primary is-small ml-2"
        >
          <span>Crear investigación</span>
        </button>
      </div>
    </div>
  );
};
