import { CardHeader } from "components/Card";
import { DataItem, DataLabel } from "components/DataItem";
import { InvestigationContext } from "components/ProcedureDetail";
import { PROPERY_USAGES, PROPERY_USAGES_ICONS } from "constants/procedure";

import React, { useContext } from "react";
import { PendingSecion } from "../PendingSection";

export const DestinationProperty = () => {
  const { loading, renter: { destinationProperty } = {} } =
    useContext(InvestigationContext);
  return loading ? (
    "Cargando"
  ) : (
    <>
      {!destinationProperty?.fultillment ? (
        <PendingSecion />
      ) : (
        <div>
          <h3 className="title is-5">Destino del inmueble</h3>
          <div className="columns">
            <div className="column">
              <div className="columns is-align-items-stretch">
                <div className="column is-3-desktop is-6-tablet">
                  <div className="box mb-3">
                    <CardHeader>
                      <div className="icon purple">
                        <i
                          className={`fas ${
                            PROPERY_USAGES_ICONS[destinationProperty?.usage]
                          }`}
                        ></i>
                      </div>
                      <div className="data">
                        <div> Uso del inmueble</div>
                        <div className="has-text-grey	">
                          {PROPERY_USAGES[destinationProperty?.usage]}
                        </div>
                      </div>
                    </CardHeader>
                  </div>
                  <div className="box">
                    <CardHeader>
                      <div className="icon green">
                        <i className="fas fa-paw"></i>
                      </div>
                      <div className="data">
                        <div>Mascotas</div>
                        {destinationProperty?.hasPets ? (
                          <div className="has-text-grey	">
                            {destinationProperty?.petType}
                          </div>
                        ) : (
                          <div className="has-text-grey	">Sin mascotas</div>
                        )}
                      </div>
                    </CardHeader>
                  </div>
                </div>
                <div className="column is-6-desktop is-6-tablet">
                  <div className="box">
                    <CardHeader>
                      <div className="icon blue">
                        <i className="fas fa-users"></i>
                      </div>
                      <div className="data">
                        <div>Personas que habitarán/usarán</div>
                        <div className="has-text-grey	">
                          Adultos: <b>{destinationProperty?.adults}</b>, niños:{" "}
                          <b>
                            {destinationProperty?.childrenAges?.length ?? 0}
                          </b>
                        </div>
                      </div>
                    </CardHeader>
                    <div className="mt-4" />
                    {destinationProperty?.childrenAges?.length > 0 && (
                      <>
                        <DataLabel label="Edad de los niños" />
                        <div className="mt-4" />
                        {destinationProperty?.childrenAges?.map((c, i) => (
                          <DataItem
                            field={`Niño ${i + 1}`}
                            data={`${c.age} año(s)`}
                          />
                        ))}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
