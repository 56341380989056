import React, { useEffect, useState } from 'react'
import Moment from 'react-moment'
import { Panel } from '../../../../../components/Panel'
import Dialog from '../../../../../components/Dialog'
import { GetStaff } from '../../../../../services/Staff'
import 'moment/locale/es';
import { AddUserForm } from '../../../../../components/AddUserForm'

const roles = [
  'Administrador',
  'Ejecutivo'
]

export const Users = (props) => {
  const [staff, setStaff] = useState([])
  const [staffData, setStaffData] = useState([])
  const [addUser, setAddUser] = useState(false)
  const [selectedUser, editUser] = useState(null)
  
  const [filters, setFilters] = useState({
    role: 'all'
  });

  useEffect(() => {
    getUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    return () => filterStaff()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const filterStaff = () => {
    let filteredStaff = staffData;
    if (filters.role !== 'all'){
      console.log('Log at ~ file: index.jsx ~ line 34 ~ filterStaff ~ filters.role', filters.role)
      filteredStaff = staffData.filter( e => e.role === filters.role);
    }

    setStaff([...filteredStaff])
  }

  const getUsers = async () => {
    let { data } = await GetStaff();
    setStaff(data);
    setStaffData(data);
  }

  const updateItems = (data, isUpdating = null) => {
    setAddUser(false);
    if (isUpdating) {
      let itemIndex = staff.findIndex(e => e._id === data._id);
      let newStaffItems = staff;
      newStaffItems[itemIndex] = data;
      setStaff([...newStaffItems])
    } else {
      setStaff([...staff, data])
    }
  }

  return (
    <Panel>
      <div className="navbar">
        <div className="navbar-start">
        <p className="is-size-5 has-text-weight-bold">
          Usuarios registrados
        </p>
        </div>
        <div className="navbar-end">
          <div className="navbar-item">
            <div className="buttons">
              <button className="button is-primary is-small" onClick={() => { editUser(null); setAddUser(true);}}>
                <span>Crear usuario</span>
                <span className="icon">
                  <i className="fa fa-plus"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr/>
      <table className="table is-fullwidth is-striped">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>Correo</th>
            <th>Teléfono</th>
            <th>
              Rol
              <div className="select is-small is-hidden">
                <select name="role" id="role" value={filters?.role} onChange={({ target: { value } }) => setFilters({...filters, role: value}) }>
                  <option value="all">Todos los roles</option>
                  <option value={0}>Administrador</option>
                  <option value={1}>Ejecutivo</option>
                </select>
              </div>
            </th>
            <th>Fecha de creación</th>
            <th>Fecha de actualización</th>
            <th>Creado por</th>
            <th>Actualizado por</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {
            staff.map((user) => (
              <tr key={user._id}>
                <td>{ user.firstName }</td>
                <td>{ user.lastName }</td>
                <td>{ user.email }</td>
                <td>{ user.phone }</td>
                <td> <span className={`tag ${ user.role === 0 ? 'is-primary' : 'is-info'  }`}>  { roles[user.role] } </span> </td>
                <td>
                  <Moment fromNow>{ user.createdAt }</Moment>
                </td>
                <td>
                  <Moment fromNow>{ user.updatedAt }</Moment>
                </td>
                <td>
                  <span className="tag is-rounded is-medium is-pointer">
                    <span className="icon">
                      <i className="fa fa-user"></i>
                    </span>
                    <span>
                      { user?.createdBy?.firstName }
                    </span>
                  </span>
                </td>
                <td>
                  <span className="tag is-rounded is-medium is-pointer">
                    <span className="icon">
                      <i className="fa fa-user"></i>
                    </span>
                    <span>
                      { user?.updatedBy?.firstName }
                    </span>
                  </span>
                </td>
                <td>
                  <p className="buttons">
                    <button className="button is-link" onClick={() => {
                      editUser(user);
                      setAddUser(true);
                    }}>
                      <span className="icon">
                        <i className="fa fa-eye"></i>
                      </span>
                    </button>
                  </p>
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>

      <Dialog
        isActive={addUser}
        close={() => {
          setAddUser(false);
          editUser(null)
        }}
        width="500px"
      >
        { addUser && <AddUserForm close={updateItems} user={selectedUser}/> }
      </Dialog>
    </Panel>
  )
}