import { CardHeader } from "components/Card";
import { DataItem } from "components/DataItem";
import { FileCard } from "components/FileCard";
import { InvestigationContext } from "components/ProcedureDetail";

import React, { useContext } from "react";
import { PendingSecion } from "../PendingSection";

export const CreditLegalHistory = () => {
  const {
    openFilesModal,
    loading,
    renter: { generalData } = {},
  } = useContext(InvestigationContext);
  return loading ? (
    "Cargando"
  ) : (
    <>
      {!generalData?.creditAndLegalHistoryFulFillment ? (
        <PendingSecion />
      ) : (
        <div>
          <h3 className="title is-5">Historial Legal y Crediticio</h3>
          <div className="columns">
            <div className="column">
              <div className="columns is-align-items-stretch">
                <div className="column is-6-desktop is-12-tablet">
                  <div className="box">
                    <CardHeader>
                      <div className="icon orange">
                        <i className="fas fa-gavel" aria-hidden="true"></i>
                      </div>
                      <div className="data">
                        <div>Historial legal</div>
                        <div className="has-text-grey	"></div>
                      </div>
                    </CardHeader>
                    <br />
                    <DataItem
                      field=""
                      data={generalData?.legalHistoryQualification}
                    />
                  </div>
                </div>
                <div className="column is-6-desktop is-12-tablet">
                  <div className="box h-full">
                    <CardHeader>
                      <div className="icon blue">
                        <i className="fas fa-credit-card"></i>
                      </div>
                      <div className="data">
                        <div>Historial crediticio</div>
                        <div className="has-text-grey	"></div>
                      </div>
                    </CardHeader>
                    <br />
                    <DataItem
                      field=""
                      data={generalData?.creditHistoryQualification}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-3">
              <h4 className="title is-6">Documentos</h4>
              <FileCard
                label="Historial legal"
                files={generalData?.legalHistory}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Historial crediticio"
                files={generalData?.creditHistory}
                onOpenFile={openFilesModal}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
