import React from "react";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";
import { Padding } from "../../../../components/Padding";
import { Users } from "./Users";
import { Forms } from "./Forms";
import { RealEstate } from "./RealEstate";

export const Configuration = (props) => {
  return (
    <>
      <nav className="navbar secondary has-background-primary">
        <div className="navbar-start">
          <NavLink
            className="navbar-item"
            to="/panel/configuracion/usuarios"
            activeClassName="is-active"
          >
            Usuarios
          </NavLink>
          <NavLink
            className="navbar-item"
            to="/panel/configuracion/inmobiliarias"
            activeClassName="is-active"
          >
            Inmobiliarias
          </NavLink>
          <NavLink
            className="navbar-item"
            to="/panel/configuracion/formularios"
            activeClassName="is-active"
          >
            Formularios
          </NavLink>
        </div>
      </nav>

      <Padding padding="16px 30px">
        <Switch>
          <Redirect
            exact
            from="/panel/configuracion/"
            to="/panel/configuracion/usuarios"
          />
          <Route path="/panel/configuracion/usuarios" component={Users} />
          <Route path="/panel/configuracion/formularios" component={Forms} />
          <Route
            path="/panel/configuracion/inmobiliarias"
            component={RealEstate}
          />
        </Switch>
      </Padding>
    </>
  );
};
