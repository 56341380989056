import React, { useContext } from "react";
import { InvestigationContext } from "..";
import { InfoItem } from "../../InfoItem";
import { StageNavbar } from "../../StageNavbar";

export const GuarantorProperty = ({
  isClg,
  guarantorProperty,
  isBusiness,
  isCollapsed = false,
  collapse,
  ownerFile = [],
  houseDeedsFile = [],
  registrationTicketFile = [],
  predialPaymentFile = [],
}) => {
  const { openFile, openValidateForm, attachFile } =
    useContext(InvestigationContext);
  return (
    <div>
      <StageNavbar>
        <p
          className="is-size-5 has-text-weight-bold as-cursor no-selectable"
          onClick={() => collapse(!isCollapsed)}
        >
          <span className="icon">
            {isCollapsed ? (
              <i className="fas fa-caret-right"></i>
            ) : (
              <i className="fas fa-caret-down"></i>
            )}
          </span>
          <span>
            Inmueble del fiador{" "}
            {guarantorProperty.fulfillment != null ? (
              <span>
                <span
                  className={`icon is-rounded mr-3 ${
                    guarantorProperty.fulfillment === true
                      ? "has-text-success"
                      : "has-text-success "
                  }`}
                >
                  {guarantorProperty.fulfillment === true ? (
                    <i className="fas fa-check"></i>
                  ) : (
                    <i className="fas fa-check"></i>
                  )}
                </span>
              </span>
            ) : (
              ""
            )}
          </span>
        </p>
        {
          // VALIDATE BUTTON
          !isCollapsed && (
            <div className="actions">
              <button
                className="button is-light has-text-weight-bold"
                onClick={() => openValidateForm("guarantorProperty")}
              >
                <span>Validar</span>
                <span className="icon">
                  <i className="fas fa-check"></i>
                </span>
              </button>
            </div>
          )
        }
      </StageNavbar>
      <br />
      {!isCollapsed && (
        <div>
          <p className="has-text-weight-bold">Documentos:</p>
          <br />
          <div className="tags is-sticky">
            {ownerFile.map((f, i) => (
              <span
                className="tag is-primary is-rounded is-medium has-text-white is-pointer"
                onClick={() => {
                  openFile([f]);
                }}
                onDoubleClick={() => {
                  attachFile("guarantorData", "ownerFile", i);
                }}
              >
                <span className="icon">
                  <i className="fas fa-file-invoice"></i>
                </span>
                <span>Comprobante de titularidad {i + 1}</span>
              </span>
            ))}
            {houseDeedsFile.length === 0 && (
              <span
                className="tag is-info is-rounded is-medium has-text-white is-pointer"
                onDoubleClick={() => {
                  attachFile("guarantorData", "houseDeedsFile");
                }}
              >
                <span className="icon">
                  <i className="fa fa-id-card"></i>
                </span>
                <span>Subir Escrituras</span>
              </span>
            )}
            {houseDeedsFile.map((f, i) => (
              <span
                className="tag is-primary is-rounded is-medium has-text-white is-pointer"
                onClick={() => {
                  openFile([f]);
                }}
                onDoubleClick={() => {
                  attachFile("guarantorData", "houseDeedsFile", i);
                }}
              >
                <span className="icon">
                  <i className="fas fa-file-invoice"></i>
                </span>
                <span> Escrituras {i + 1}</span>
              </span>
            ))}
            {registrationTicketFile.map((f, i) => (
              <span
                className="tag is-primary is-rounded is-medium has-text-white is-pointer"
                onClick={() => {
                  openFile([f]);
                }}
                onDoubleClick={() => {
                  attachFile("guarantorData", "registrationTicketFile", i);
                }}
              >
                <span className="icon">
                  <i className="fas fa-file-invoice"></i>
                </span>
                <span> Boleta registral {i + 1}</span>
              </span>
            ))}
            {predialPaymentFile.map((f, i) => (
              <span
                className="tag is-primary is-rounded is-medium has-text-white is-pointer"
                onClick={() => {
                  openFile([f]);
                }}
                onDoubleClick={() => {
                  attachFile("guarantorData", "predialPaymentFile", i);
                }}
              >
                <span className="icon">
                  <i className="fas fa-file-invoice"></i>
                </span>
                <span> Predial {i + 1}</span>
              </span>
            ))}
            <span
              className="tag is-info is-rounded is-medium has-text-white is-pointer"
              onDoubleClick={() => {
                attachFile("guarantorData", "registrationTicketFile");
              }}
            >
              <span className="icon">
                <i className="fa fa-id-card"></i>
              </span>
              <span>Subir Boleta registral</span>
            </span>

            {isClg && (
              <>
                {guarantorProperty?.clgFile?.map((f, i) => {
                  return (
                    <span
                      className="tag is-primary is-rounded is-medium has-text-white is-pointer"
                      onClick={() => {
                        openFile([f]);
                      }}
                    >
                      <span className="icon">
                        <i className="fas fa-file-invoice"></i>
                      </span>
                      <span>CLG {i + 1}</span>
                    </span>
                  );
                })}
                <span
                  className="tag is-info is-rounded is-medium has-text-white is-pointer"
                  onDoubleClick={() => {
                    attachFile("guarantorProperty", "clgFile");
                  }}
                >
                  <span className="icon">
                    <i className="fa fa-id-card"></i>
                  </span>
                  <span>Subir CLG</span>
                </span>
              </>
            )}

            <span
              className="tag is-info is-rounded is-medium has-text-white is-pointer"
              onDoubleClick={() => {
                attachFile("guarantorData", "predialPaymentFile");
              }}
            >
              <span className="icon">
                <i className="fa fa-id-card"></i>
              </span>
              <span>Subir Pago de Predial</span>
            </span>
          </div>

          <div>
            <InfoItem field="Domicilio" value={guarantorProperty.address} />
            <InfoItem
              field="Reporta gravamen"
              value={guarantorProperty.assessment}
            />
            <InfoItem
              field="Tipo de gravamen"
              value={guarantorProperty.assessmentName}
            />
          </div>
        </div>
      )}
    </div>
  );
};
