import React, { useState, useContext, useEffect } from "react";
import { InvestigationContext } from "..";
import { NotificationContext } from "../../../services/NotificationService";
import {
  GetCreditHistory,
  QualifyHistory,
  UpdateField,
} from "../../../services/Procedures";
import { StageNavbar } from "../../StageNavbar";

const QUALIFICATIONS = ["Con observaciones", "Sin observaciones"];

/*
 * OtherFiles
 */
export const OtherFiles = (props) => {
  const { createNotification } = useContext(NotificationContext);
  const [isCollapsed, collapse] = useState(true);
  const { renter, openFile, attachFile, openValidateForm } =
    useContext(InvestigationContext);
  const { generalData } = renter;
  // eslint-disable-next-line no-unused-vars
  const [creditHistory, setCreditHistory] = useState(null);
  const [creditHistoryQualification, setCreditHistoryQualification] = useState(
    generalData.creditHistoryQualification
  );
  const [legalHistoryQualification, setLegalHistoryQualification] = useState(
    generalData.legalHistoryQualification
  );

  const [historyNotes, setHistoryNotes] = useState({
    creditHistoryNotes: generalData?.creditHistoryNotes,
    legalHistoryNotes: generalData?.legalHistoryNotes,
  });

  OtherFiles.getCreditHistory = async () => {
    let { data } = await GetCreditHistory(renter._id);
    if (data.creditHistory) {
      setCreditHistory(data.creditHistory);
    }
  };

  useEffect(() => {
    OtherFiles.getCreditHistory();
  }, []);

  const updateQulification = async (qualificationType, qualification) => {
    await QualifyHistory(
      {
        [qualificationType]: qualification,
      },
      generalData._id
    );
    createNotification({
      message: "Calificación guardada",
    });
  };

  const addHistoryNotes = async (field, value) => {
    await UpdateField({
      modelName: "generalData",
      query: {
        _id: generalData._id,
      },
      update: {
        [field]: value,
      },
    });
    createNotification({
      message: 'Notas actualizadas'
    })
  };

  return (
    <div>
      <StageNavbar>
        <p
          className="is-size-5 has-text-weight-bold as-cursor has-text-primary"
          onClick={() => collapse(!isCollapsed)}
        >
          <span className="icon">
            {isCollapsed ? (
              <i className="fas fa-caret-right"></i>
            ) : (
              <i className="fas fa-caret-down"></i>
            )}
          </span>
          <span>
            Historial legal y crediticio{" "}
            {generalData.fulfillment != null ? (
              <span>
                <span
                  className={`icon is-rounded mr-3 ${
                    generalData.fulfillment === true
                      ? "has-text-success"
                      : "has-text-is-success "
                  }`}
                >
                  {generalData.fulfillment === true ? (
                    <i className="fas fa-check" />
                  ) : (
                    <i className="fas fa-check" />
                  )}
                </span>
              </span>
            ) : (
              ""
            )}
          </span>
        </p>
        {
          // VALIDATE BUTTON
          !isCollapsed && (
            <div className="actions">
              <button
                className="button is-light has-text-weight-bold"
                onClick={() => openValidateForm("otherFiles")}
              >
                <span>Validar</span>
                <span className="icon">
                  <i className="fas fa-check"></i>
                </span>
              </button>
            </div>
          )
        }
      </StageNavbar>
      <br />
      {!isCollapsed && (
        <div>
          <p className="has-text-weight-bold">Documentos:</p>
          <br />
          <div className="tags">
            <span
              className={`tag ${
                generalData.creditHistory.length !== 0 ? "is-info" : "is-danger"
              } is-rounded is-medium has-text-white is-pointer`}
              onClick={() => {
                if (generalData.creditHistory.length !== 0) {
                  openFile(generalData.creditHistory);
                } else {
                  attachFile("generalData", "creditHistory");
                }
              }}
              onDoubleClick={() => {
                attachFile("generalData", "creditHistory");
              }}
            >
              <span className="icon">
                {generalData.creditHistory.length !== 0 ? (
                  <i className="fas fa-check-circle"></i>
                ) : (
                  <i className="fas fa-cloud-upload-alt"></i>
                )}
              </span>
              <span>Historial Crediticio</span>
            </span>

            <span
              className={`tag ${
                generalData.legalHistory.length !== 0 ? "is-info" : "is-danger"
              } is-rounded is-medium has-text-white is-pointer`}
              onClick={() => {
                if (generalData.legalHistory.length !== 0) {
                  openFile(generalData.legalHistory);
                } else {
                  attachFile("generalData", "legalHistory");
                }
              }}
              onDoubleClick={() => {
                attachFile("generalData", "legalHistory");
              }}
            >
              <span className="icon">
                {generalData.legalHistory.length !== 0 ? (
                  <i className="fas fa-check-circle"></i>
                ) : (
                  <i className="fas fa-cloud-upload-alt"></i>
                )}
              </span>
              <span>Historial legal</span>
            </span>
          </div>
          <br />
          {/* Datos obtenidos: */}
          {/* <CreditHistoryF creditHistory={creditHistory}/> */}
          <div className="columns">
            <div className="column is-3">
              <label className="label">Calificar Historial Crediticio</label>
              <div className="select is-fullwidth">
                <select
                  value={creditHistoryQualification}
                  onChange={({ target: { value } }) => {
                    setCreditHistoryQualification(value);
                    updateQulification("creditHistoryQualification", value);
                  }}
                >
                  <option value="" disabled>
                    Selecciona una opción
                  </option>
                  {QUALIFICATIONS.map((s) => (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  ))}
                </select>
                <textarea
                  className="textarea mt-2"
                  placeholder="Agregar notas de las observaciones"
                  value={historyNotes.creditHistoryNotes}
                  onChange={({ target: { value } }) =>
                    setHistoryNotes({
                      ...historyNotes,
                      creditHistoryNotes: value,
                    })
                  }
                  onBlur={({ target: { value } }) =>
                    addHistoryNotes("creditHistoryNotes", value)
                  }
                />
              </div>
            </div>
            <div className="column is-3">
              <label className="label">Calificar Historial Legal</label>
              <div className="select is-fullwidth">
                <select
                  value={legalHistoryQualification}
                  onChange={({ target: { value } }) => {
                    setLegalHistoryQualification(value);
                    updateQulification("legalHistoryQualification", value);
                  }}
                >
                  <option value="" disabled>
                    Selecciona una opción
                  </option>
                  {QUALIFICATIONS.map((s) => (
                    <option key={s} value={s}>
                      {s}
                    </option>
                  ))}
                </select>
                <textarea
                  className="textarea mt-2"
                  placeholder="Agregar notas de las observaciones"
                  value={historyNotes.legalHistoryNotes}
                  onChange={({ target: { value } }) =>
                    setHistoryNotes({
                      ...historyNotes,
                      legalHistoryNotes: value,
                    })
                  }
                  onBlur={({ target: { value } }) =>
                    addHistoryNotes("legalHistoryNotes", value)
                  }
                />
              </div>
            </div>
          </div>
          <br />
        </div>
      )}
    </div>
  );
};

export default OtherFiles;
