import React, { useEffect, useState } from 'react'
import { GetProcedureCount, GetProcedureCountByStatus } from '../../../services/Admin';
import DataTile from '../../DataTile';

/**
 * status
 * 0 -> created 
 * 1 -> on process
 * 2 -> has issues
 * 3 -> completed
 * 4 -> rejected
 */

export const ProcedureCount = () => {

  const [count, setCount] = useState(0)
  useEffect(() => {
    (async function (){
      let { data: { proceduresCount } } = await GetProcedureCount();
      setCount(proceduresCount ?? 0);
    })();
  }, [])

  return (
    <DataTile>
      <span className="is-uppercase has-text-weight-bold has-text-primary">
        <span className="icon">
          <i className="fas fa-book"></i>
        </span>
        <span>Total</span>
      </span>
      <span className="has-text-weight-bold is-size-2">
      { count }
      </span>
    </DataTile>    
  )
}

export const ProcedureCountNew = () => {

  const [count, setCount] = useState(0)
  useEffect(() => {
    (async function (){
      let { data: { proceduresCount } } = await GetProcedureCountByStatus(0);
      setCount(proceduresCount ?? 0);
    })();
  }, [])

  return (
    <DataTile>
      <span className="is-uppercase has-text-weight-bold has-text-primary">
        <span className="icon">
          <i className="fas fa-clock"></i>
        </span>
        <span>Nuevos</span>
      </span>
      <span className="has-text-weight-bold is-size-2">
      { count }
      </span>
    </DataTile>    
  )
}
export const ProcedureCountOnProcess = () => {

  const [count, setCount] = useState(0)
  useEffect(() => {
    (async function (){
      let { data: { proceduresCount } } = await GetProcedureCountByStatus(1);
      setCount(proceduresCount ?? 0);
    })();
  }, [])

  return (
    <DataTile>
      <span className="is-uppercase has-text-weight-bold has-text-primary">
        <span className="icon">
          <i className="fas fa-clock"></i>
        </span>
        <span>En Proceso</span>
      </span>
      <span className="has-text-weight-bold is-size-2">
      { count }
      </span>
    </DataTile>    
  )
}


export const ProcedureCountWithIssues = () => {

  const [count, setCount] = useState(0)
  useEffect(() => {
    (async function (){
      let { data: { proceduresCount } } = await GetProcedureCountByStatus(2);
      setCount(proceduresCount ?? 0);
    })();
  }, [])

  return (
    <DataTile>
      <span className="is-uppercase has-text-weight-bold has-text-danger">
        <span className="icon">
          <i className="fas fa-exclamation-circle"></i>
        </span>
        <span>Con Observaciones</span>
      </span>
      <span className="has-text-weight-bold is-size-2">
      { count }
      </span>
    </DataTile>    
  )
}

export const ProcedureCountFinished = () => {

  const [count, setCount] = useState(0)
  useEffect(() => {
    (async function (){
      let { data: { proceduresCount } } = await GetProcedureCountByStatus(3);
      setCount(proceduresCount ?? 0);
    })();
  }, [])

  return (
    <DataTile>
      <span className="is-uppercase has-text-weight-bold has-text-success">
        <span className="icon">
          <i className="fas fa-check-circle"></i>
        </span>
        <span>Terminadas</span>
      </span>
      <span className="has-text-weight-bold is-size-2">
      { count }
      </span>
    </DataTile>    
  )
}