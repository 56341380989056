import React, { useContext } from "react";
import { RadialChart } from "react-vis";

import { AmmountCard } from "components/AmmountCard";
import { CallHistoryList } from "components/CallHistoryList";
import { CardHeader } from "components/Card";
import { DataItem, DataLabel, IconData } from "components/DataItem";
import { FileCard } from "components/FileCard";
import { InvestigationContext } from "components/ProcedureDetail";
import { FormatCurrency } from "components/FormatCurrency";
import { PendingSecion } from "../PendingSection";

export const EconomyActivity = () => {
  const {
    openFilesModal,
    isBusiness,
    loading,
    renter: { economyActivity } = {},
  } = useContext(InvestigationContext);
  return loading ? (
    "Cargando"
  ) : (
    <>
      {!economyActivity?.fulfillment ? (
        <PendingSecion />
      ) : (
        <div>
          <h3 className="title is-5">Actividad económica</h3>
          <div className="columns">
            <div className="column is-9 p-0">
              {!isBusiness && (
                <div className="column is-12">
                  <h4 className="title is-6">Información laboral</h4>
                  <div className="columns">
                    <div className="column is-6-desktop is-12-tablet is-align-items-stretch is-flex">
                      <div className="box" style={{ width: "100%" }}>
                        <CardHeader>
                          <div className="icon green">
                            <i className="fas fa-dollar-sign"></i>
                          </div>
                          <div className="data">
                            <div>Fuente de ingreso principal</div>
                            <div className="has-text-grey	is-capitalized">
                              {economyActivity?.mainEntry}
                            </div>
                          </div>
                        </CardHeader>
                        <br />
                        <DataItem
                          field="Empresa"
                          data={economyActivity?.workSource}
                          titleCase
                        />
                        <DataItem
                          field="Tiempo trabajando"
                          data={economyActivity?.timeInJob}
                          titleCase
                        />
                        <div className="my-2" />
                        {economyActivity?.maritialStatus !== "soltero" && (
                          <>
                            <DataLabel label="Contacto de empleo/negocio" />
                            <IconData>
                              <div className="icon red">
                                <i className="fas fa-phone"></i>
                              </div>
                              <div className="data">
                                {economyActivity?.jobPhone}
                              </div>
                            </IconData>
                          </>
                        )}
                      </div>
                    </div>
                    <div className="column is-6-desktop is-12-tablet">
                      <div className="box h-full">
                        <CardHeader>
                          <div className="icon purple">
                            <i className="fas fa-phone"></i>
                          </div>
                          <div className="data">
                            <div>Registro de llamadas</div>
                            <div className="has-text-grey	">
                              Llamadas para confirmar información
                            </div>
                          </div>
                        </CardHeader>
                        {economyActivity?.callHistory.length !== 0 && (
                          <div className="notification is-white">
                            <CallHistoryList
                              isSmall
                              calls={economyActivity?.callHistory}
                              showActions={false}
                              economyActivtyId={economyActivity._id}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {isBusiness && (
                <div className="column is-4">
                  <div className="box">
                    <h5 className="text orange has-text-weight-bold is-uppercase mb-2">
                      Actividad principal
                    </h5>
                    <DataItem field="" data={economyActivity?.mainActivity} />
                  </div>
                </div>
              )}
              <div className="column is-12">
                <h4 className="title is-6">Ingresos</h4>
                <div className="columns">
                  <div className="column is-3">
                    <AmmountCard
                      label="ingreso mensual"
                      ammount={economyActivity?.mensualEntry ?? 0}
                      ammountVerified={
                        economyActivity?.mensualEntryComparation ?? 0
                      }
                    />
                  </div>
                  <div className="column is-3">
                    <AmmountCard
                      label="otros ingresos"
                      ammount={economyActivity?.otherEntryAmmount ?? 0}
                      ammountVerified={
                        economyActivity?.otherEntryComparation ?? 0
                      }
                    />
                  </div>
                  <div className="column is-3">
                    <AmmountCard
                      label="total de ingresos"
                      ammount={
                        (economyActivity?.mensualEntry ?? 0) +
                        (economyActivity?.otherEntryAmmount ?? 0)
                      }
                      ammountVerified={
                        (economyActivity?.mensualEntryComparation ?? 0) +
                        (economyActivity?.otherEntryComparation ?? 0)
                      }
                    />
                  </div>
                  <div className="column is-3">
                    <AmmountCard
                      label="gasto mensual"
                      ammount={economyActivity?.totalMonthExpenses ?? 0}
                      verifiedLabel="total libre"
                      ammountVerified={
                        (economyActivity?.mensualEntry ?? 0) +
                        (economyActivity?.otherEntryAmmount ?? 0) -
                        (economyActivity?.totalMonthExpenses ?? 0)
                      }
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-4">
                    <div className="box">
                      <h5 className="text orange has-text-weight-bold is-uppercase mb-2">
                        Gastos contra ingresos
                      </h5>
                      <RadialChart
                        className="mx-auto relative"
                        colorType="literal"
                        innerRadius={65}
                        radius={75}
                        data={[
                          {
                            angle:
                              (economyActivity?.mensualEntry ?? 0) +
                              (economyActivity?.otherEntryAmmount ?? 0),
                            color: "#66D7D1",
                          },
                          {
                            angle: economyActivity?.totalMonthExpenses ?? 0,
                            color: "#FF415B",
                          },
                        ]}
                        color={(d) => d.color}
                        width={160}
                        height={160}
                        animation={"gentle"}
                      >
                        <span
                          className="has-text-weight-bold"
                          style={{
                            position: "absolute",
                            left: "0px",
                            right: "0px",
                            textAlign: "center",
                            top: "calc(50% - 20px)",
                          }}
                        >
                          <FormatCurrency
                            value={
                              (economyActivity?.mensualEntry ?? 0) +
                              (economyActivity?.otherEntryAmmount ?? 0) -
                              (economyActivity?.totalMonthExpenses ?? 0)
                            }
                          />
                          <br />
                          <span className="has-text-grey">Libre</span>
                        </span>
                      </RadialChart>
                      <br />
                      <div className="tags has-text-left">
                        <div className="tag is-danger">Gastos</div>
                        <div className="tag is-link">Ingresos</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-3">
              <h4 className="title is-6">Documentos</h4>
              <FileCard
                label="Comp. de ingresos"
                files={economyActivity?.entryFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Otros Comp. de ingresos"
                files={economyActivity?.otherFile}
                onOpenFile={openFilesModal}
              />
              <h4 className="title is-6">Validaciones</h4>
              <FileCard
                label="Comp. de ingresos"
                files={economyActivity?.invoiceValidations}
                onOpenFile={openFilesModal}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
