import React, { useState } from "react";
import { CreateNewProcedure } from "../../../../../services/Procedures";

const PAYMENT_METHOD = ["cash", "transfer"];
const PAYMENT_METHOD_LABELS = { cash: "Efectvo", transfer: "Transferencia" };
const TYPE = { REGULAR: "Regular", CLG: "Con CLG" };

export const CreateProcedure = ({ close, renter }) => {
  const [paymentMethod, setPaymentMethod] = useState("cash");
  const [type, setType] = useState("REGULAR");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState("");
  const create = async () => {
    setLoading("is-loading");
    await CreateNewProcedure({
      renterData: renter,
      paymentMethod,
      type,
      desc,
    });
    close();
  };
  return (
    <div className="notification is-white" style={{ width: "400px" }}>
      <h1 className="title is-4 is-marginless">Crear investigación</h1>
      <h2 className="subtitle is-5 is-marginless mb-4">
        Para: {renter.user.firstName} {renter.user.lastName}
      </h2>
      <label className="label">Método de pago</label>
      <div className="select is-fullwidth">
        <select
          value={paymentMethod}
          onChange={({ target: { value } }) => {
            setPaymentMethod(value);
          }}
        >
          {PAYMENT_METHOD.map((s) => (
            <option key={s} value={s}>
              {PAYMENT_METHOD_LABELS[s]}
            </option>
          ))}
        </select>
      </div>
      <label className="label mt-4">Tipo</label>
      <div className="select is-fullwidth">
        <select
          value={type}
          onChange={({ target: { value } }) => {
            setType(value);
          }}
        >
          {Object.keys(TYPE).map((k) => (
            <option key={k} value={k}>
              {TYPE[k]}
            </option>
          ))}
        </select>
      </div>
      <div className="field mt-4">
        <label className="label">Datos del pago</label>
        <div className="control">
          <textarea
            className="textarea"
            placeholder="Referencia, Clave de rastreo, etc."
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
          />
        </div>
      </div>
      <button
        className={`button is-primary is-fullwidth ${loading}`}
        onClick={create}
        disabled={loading === "is-loading"}
      >
        Crear investigación
      </button>
    </div>
  );
};
