import React, { useCallback, useContext, useEffect, useState } from "react";
import { Padding } from "../../../../components/Padding";
import { useParams, NavLink, Switch, Route, Redirect } from "react-router-dom";
import { GetProcedure } from "services/Partners";
import Moment from "react-moment";
import { GeneralData } from "./GeneralData";
import { InvestigationContext } from "components/ProcedureDetail";
import Dialog from "components/Dialog";
import { RegisterCallDetail } from "components/RegisterCallDetail";
import { FilesExplorerModal } from "components/FilesExplorerModal";
import { EconomyActivity } from "./EconomyActivity";
import { CreditLegalHistory } from "./CreditLegalHistory";
import { DestinationProperty } from "./DestinationProperty";
import { PersonalReferences } from "./PersonalReferences";
import { GuarantorData } from "./GuarantorData";
import { DropwDownMenu } from "components/DropDownMenu";
import { DownloadFilesZip } from "services/Procedures";
import { NewPropertyForm } from "../NewProperty";
import axios from "axios";
import { API_VERSION } from "services/Partners";
import { NotificationContext } from "services/NotificationService";
import { SelectProperty } from "../SelectProperty";

export const ProcedureDetail = () => {
  const { createNotification } = useContext(NotificationContext);

  const [procedure, setProcedure] = useState({});
  const [loading, setLoading] = useState(true);
  const [assignProperty, setAssignProperty] = useState(null);
  const [call, setCall] = useState(null);
  const [file, openFileModal] = useState(null);
  const [zipLoading, setZipLoading] = useState(false);
  const [showProperty, setShowProperty] = useState(false);
  let { id } = useParams();
  useEffect(() => {
    getProcedureData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProcedureData = useCallback(async () => {
    let { data } = await GetProcedure(id);
    setProcedure(data);
    setLoading(false);
  }, [id]);

  const openFilesModal = async (label, files) => {
    openFileModal({
      files: files,
      title: label,
    });
  };

  const downloadZip = async () => {
    setZipLoading(true);
    const { data } = await DownloadFilesZip(procedure?._id);
    window.open(data);
    setZipLoading(false);
  };

  const isBusiness = procedure?.renter?.isBusiness;

  const updateProcedureProperty = (property) => {
    setProcedure({
      ...procedure,
      property: property,
    });
  };

  const assignPropertyToProcedure = async (property) => {
    if (property) {
      await axios.post(
        `${API_VERSION}/partners/procedure/assign-property/${procedure._id}`,
        property
      );
      createNotification({
        message: "Inmueble asignado correctamente",
      });
      updateProcedureProperty(property);
    }
    setAssignProperty(null);
  };

  return (
    <InvestigationContext.Provider
      value={{
        renter: procedure?.renter,
        procedure: procedure,
        openCallHistoryItem: (call) => setCall(call),
        openFilesModal,
        isBusiness,
        loading,
      }}
    >
      <Padding padding="56px 50px">
        <div className="is-flex is-justify-content-space-between">
          <div>
            <h1 className="title is-4 is-marginless">
              {procedure?.renter?.generalData?.firstName}{" "}
              {procedure?.renter?.generalData?.secondName}{" "}
              {procedure?.renter?.generalData?.lastName1}{" "}
              {procedure?.renter?.generalData?.lastName2}
            </h1>
            <h5 className="title is-7 has-text-grey	 mt-2">
              Creada el <Moment format="LL">{procedure.createdAt}</Moment>
            </h5>
            <div className="is-flex my-2">
              <span className="tag is-info is-small is-light">
                Persona {isBusiness ? "moral" : "física"}
              </span>
              {procedure.property && (
                <span
                  className="tag is-info is-small ml-2 as-cursor"
                  onClick={() => setShowProperty(true)}
                >
                  {procedure.property?.name}
                </span>
              )}
            </div>
          </div>
          <div>
            <DropwDownMenu
              buttonText="Asociar a inmueble"
              buttonClass="is-link ml-2"
              alignment="is-right"
              icon={
                <span className="icon">
                  <i className="fas fa-home-alt"></i>
                </span>
              }
            >
              <a
                href="#!"
                className="dropdown-item"
                onClick={() => setAssignProperty("new")}
              >
                <span>Nuevo</span>
              </a>
              <a
                href="#!"
                className="dropdown-item"
                onClick={() => setAssignProperty("exist")}
              >
                <span>Existente</span>
              </a>
            </DropwDownMenu>
            <DropwDownMenu
              buttonText="Descargar"
              buttonClass="is-primary ml-2"
              alignment="is-right"
              icon={
                <span className="icon">
                  <i className="fas fa-cloud-download-alt"></i>
                </span>
              }
            >
              <NavLink
                to={`/panel/tramite/descargar/` + procedure?._id}
                target="_blank"
                rel="noopener noreferrer"
                className="dropdown-item"
              >
                <span>Descargar Resumen</span>
              </NavLink>
              <a href="#!" className="dropdown-item" onClick={downloadZip}>
                <span>
                  Descargar Investigación
                  {zipLoading && (
                    <>
                      <button className="button is-transparent is-small is-loading is-borderless"></button>
                    </>
                  )}
                </span>
              </a>
            </DropwDownMenu>
          </div>
        </div>
        <div className="tabs is-small">
          <ul>
            <li>
              <NavLink
                to={`/panel/tramites/${id}/informacion`}
                activeClassName="is-active"
              >
                <span className="icon is-small">
                  <i className="fas fa-user-tag" aria-hidden="true"></i>
                </span>
                <span>Inf. General</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/panel/tramites/${id}/actividad-economica`}
                activeClassName="is-active"
              >
                <span className="icon is-small">
                  <i className="fas fa-wallet" aria-hidden="true"></i>
                </span>
                <span>Actividad Económica</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/panel/tramites/${id}/historiales`}
                activeClassName="is-active"
              >
                <span className="icon is-small">
                  <i className="fas fa-gavel" aria-hidden="true"></i>
                </span>
                <span>Hist. Legal y Crediticio</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/panel/tramites/${id}/destino`}
                activeClassName="is-active"
              >
                <span className="icon is-small">
                  <i className="fas fa-home" aria-hidden="true"></i>
                </span>
                <span>Dest. del Inmueble</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/panel/tramites/${id}/referencias`}
                activeClassName="is-active"
              >
                <span className="icon is-small">
                  <i className="fas fa-user-friends" aria-hidden="true"></i>
                </span>
                <span>Referencias</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/panel/tramites/${id}/fiador`}
                activeClassName="is-active"
              >
                <span className="icon is-small">
                  <i className="fas fa-user-check" aria-hidden="true"></i>
                </span>
                <span>Fiador</span>
              </NavLink>
            </li>
          </ul>
        </div>
        <Switch>
          <Redirect
            exact
            from={`/panel/tramites/${id}`}
            to={`/panel/tramites/${id}/informacion`}
          />
          <Route
            path={`/panel/tramites/${id}/informacion`}
            component={GeneralData}
          />
          <Route
            path={`/panel/tramites/${id}/actividad-economica`}
            component={EconomyActivity}
          />
          <Route
            path={`/panel/tramites/${id}/historiales`}
            component={CreditLegalHistory}
          />
          <Route
            path={`/panel/tramites/${id}/destino`}
            component={DestinationProperty}
          />
          <Route
            path={`/panel/tramites/${id}/referencias`}
            component={PersonalReferences}
          />
          <Route
            path={`/panel/tramites/${id}/fiador`}
            component={GuarantorData}
          />
        </Switch>
        <Dialog isActive={call !== null} close={() => setCall(null)}>
          {call !== null && <RegisterCallDetail call={call} />}
        </Dialog>
        <Dialog isActive={file !== null} close={() => openFileModal(null)}>
          {file !== null && <FilesExplorerModal file={file} />}
        </Dialog>

        {assignProperty === "new" && (
          <Dialog
            isActive={assignProperty === "new"}
            close={() => setAssignProperty(null)}
          >
            <NewPropertyForm onClose={assignPropertyToProcedure} />
          </Dialog>
        )}
        {assignProperty === "exist" && (
          <Dialog
            isActive={assignProperty === "exist"}
            close={() => setAssignProperty(null)}
          >
            <SelectProperty onClose={assignPropertyToProcedure} />
          </Dialog>
        )}

        {showProperty && (
          <Dialog isActive={showProperty} close={() => setShowProperty(null)}>
            <NewPropertyForm
              onClose={(property) => {
                setShowProperty(false);
                updateProcedureProperty(property);
              }}
              property={procedure.property}
            />
          </Dialog>
        )}
      </Padding>
    </InvestigationContext.Provider>
  );
};

export default ProcedureDetail;
