import React, { useState } from "react";
import { faPen, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FIELD_NAMES } from "../utils";
import { Input } from "components/Form";
import { INVALID_ID_CHARS_REGEX, removeDiacritics } from "utils/strings";

const getItemStyle = (isDragging, draggableStyle, editing) => ({
  userSelect: "none",
  padding: 8 * 2,
  margin: `0 0 ${8}px 0`,

  borderWidth: "2px",
  borderStyle: "solid",
  borderColor: isDragging || editing ? "#66d7d1" : "transparent",
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const TextField = ({
  field,
  dragHandleProps,
  isDragging,
  providedStyle,
  innerRef,
  draggableProps,
  updateField,
}) => {
  const [editing, setEditing] = useState(false);
  const [fieldData, setFieldData] = useState({
    ...field,
  });
  return (
    <div
      ref={innerRef}
      className="notification is-white is-shadowless"
      {...draggableProps}
      style={getItemStyle(isDragging, providedStyle, editing)}
    >
      <div className="is-align-items-center is-flex is-justify-content-space-between">
        <div className="is-flex is-align-items-center">
          <p className="has-text-weight-bold is-size-6">
            {fieldData?.label} {fieldData?.id}
          </p>
          <span className="is-size-7 ml-2 has-text-link has-text-weight-bold">
            {FIELD_NAMES[fieldData?.type]}
          </span>
        </div>
        <div className="buttons">
          <button
            className="button is-small is-white"
            onClick={() => setEditing(!editing)}
          >
            <span className="icon">
              {editing ? (
                <FontAwesomeIcon icon={faXmark} />
              ) : (
                <FontAwesomeIcon icon={faPen} />
              )}
            </span>
          </button>
          <div
            className="button is-borderless is-transparent is-small"
            {...dragHandleProps}
          >
            <span className="icon">
              <i className="fas fa-grip-vertical"></i>
            </span>
          </div>
        </div>
      </div>
      {editing && (
        <>
          <div className="my-4">
            <Input
              type="text"
              label="Nombre del campo"
              placeholder="Ej. Nombre"
              onChange={(e) =>
                setFieldData({
                  ...fieldData,
                  label: e.target.value,
                  id: removeDiacritics(e.target.value.toLowerCase())
                    .trim()
                    .replaceAll(INVALID_ID_CHARS_REGEX, "")
                    .replaceAll(" ", "_"),
                })
              }
              value={fieldData?.label}
            />
            <Input
              type="text"
              label="Texto de ayuda"
              placeholder="Texto para indicar al usuario como llenar este campo"
              onChange={(e) =>
                setFieldData({
                  ...fieldData,
                  helpText: e.target.value,
                })
              }
              value={fieldData?.helpText}
            />
            <label className="checkbox is-flex is-align-items-center">
              <input
                className="mr-2"
                type="checkbox"
                checked={fieldData.required}
                onChange={(e) =>
                  setFieldData({
                    ...fieldData,
                    required: e.target.checked,
                  })
                }
              />
              Marcar como obligatorio
            </label>
          </div>
          <div className="is-flex is-justify-content-space-between">
            <button
              className="button is-danger is-light is-small"
              onClick={() => setEditing(!editing)}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faTrash} />
              </span>
            </button>
            <div className="buttons">
              <button
                className="button is-outlined is-small"
                onClick={() => setEditing(!editing)}
              >
                <span>Cancelar</span>
              </button>
              <button
                className="button is-link is-small"
                onClick={() => {
                  updateField(fieldData);
                  setEditing(!editing);
                }}
              >
                <span>Guardar</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
