import React from 'react'
import { ForgotPasswordForm } from '../../components/ForgotPasswordForm'
import { Padding } from '../../components/Padding'

export const ForgotPassword = (props) => {
  return (
    <Padding padding='100px' className="login">
      <div className="notification is-white" style={{
        maxWidth: '500px',
        margin: '0 auto',
        boxShadow: 'rgba(220, 220, 220, 0.27) 0px 3px 6px 8px',
      }}>
        <ForgotPasswordForm/>
      </div>
    </Padding>
  )
}
