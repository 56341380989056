import axios from 'axios';
import { API } from '../../api';

export const API_VERSION = API.server

export const GetProcedureCount = () => axios.get(`${API_VERSION}/admin/procedures/count`)
export const GetProcedureCountByStatus = (status) => axios.get(`${API_VERSION}/admin/procedures/count/by-status?status=${status}`)

export const UpdateRealEstate = (data) => axios.put(`${API_VERSION}/real-estate/`, data)
export const CreateRealEstate = (data) => axios.post(`${API_VERSION}/real-estate/`, data)
export const GetRealEstate = () => axios.get(`${API_VERSION}/real-estate/`)
export const GetRealEstateById = (id) => axios.get(`${API_VERSION}/real-estate/${id}`)

export const GetTags = () => axios.get(`${API_VERSION}/admin/tags`)
