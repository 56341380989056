import React, { useState,useEffect, } from 'react'
import { GetFileURL } from '../../services/Procedures'
import { LoaderItem } from '../Loader'

/*
 * FileViewer
 * Add description for your component
 */ 
export const FileViewer = ({ fileKey, openFile }) => {
  //const fileData = fileKey.split('.');
  // const type = fileData[fileData.length -1] !== 'pdf' ? 'image' : 'pdf';
  const [loading, setLoading] = useState(true)
  const [fileURL, setFileURL] = useState(null)

  useEffect( () => {
    async function getUrl() {
      let { data } = await GetFileURL({
        file: fileKey,
      });
      setFileURL(data);
      setLoading(false);
      console.log('Log at 👉  ~ file: index.jsx ~ line 18 ~ getUrl ~ data', data);
    }
    getUrl();
    return () => {
      // cleanup
    }
  }, [fileKey])
  
  return (
    <div>
      <nav className="navbar is-marginless is-transparent" style={{zIndex: 0}}>
        <div className="navbar-end">         
          <div className="buttons">
            <a href={fileURL} target="_blank" rel="noopener noreferrer" className="button is-primary  is-circle">
              <span className="icon">
                <i className="fas fa-external-link-alt"></i>
              </span>
            </a>
            <button className={`button is-circle is-light has-tooltip-left`} data-tooltip="Cerrar" onClick={() => openFile(null)}>
              <span className="icon">
                <i className="fa fa-times"></i>                
              </span>
            </button>
        </div>
        </div>     
      </nav>
      <LoaderItem isLoading={loading}>      
        {
           fileURL !== null && <iframe title="Archivo" src={fileURL} style={{width:'100%', height:'70vh',}}></iframe>
        }
      </LoaderItem>
    </div>
  )
}

export default FileViewer;

