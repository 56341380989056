import React from "react";
import { Panel } from "../../../../../components/Panel";
import { Redirect, Route, Switch } from "react-router-dom";
import { FormsList } from "./FormsList";
import { NewForm } from "./NewForm";

export const Forms = () => {
  return (
    <Panel>
      <Switch>
        <Redirect
          exact
          from="/panel/configuracion/formularios"
          to="/panel/configuracion/formularios/lista"
        />
        <Route
          path="/panel/configuracion/formularios/lista"
          component={FormsList}
        />
        <Route
          path="/panel/configuracion/formularios/nuevo"
          component={NewForm}
        />
      </Switch>
    </Panel>
  );
};
