import { CardHeader } from "components/Card";
import { DataItem, DataLabel, IconData } from "components/DataItem";
import { FileCard } from "components/FileCard";
import { InvestigationContext } from "components/ProcedureDetail";

import React, { useContext } from "react";
import { PendingSecion } from "../PendingSection";

export const GuarantorData = () => {
  const {
    loading,
    renter: { guarantorData, guarantorProperty } = {},
    openFilesModal,
  } = useContext(InvestigationContext);
  const isBusiness = guarantorData?.isBusiness;
  console.log(
    "Log at 👉  ~ file: index.jsx:12 ~ GuarantorData ~ guarantorData:",
    guarantorData
  );
  return loading ? (
    "Cargando"
  ) : (
    <>
      {(!guarantorProperty?.fulfillment || !guarantorData?.fulfillment) ? (
        <PendingSecion />
      ) : (
        <div>
          <h3 className="title is-5">Información del fiador</h3>
          <div className="columns is-multiline">
            <div className="column  is-9">
              <h4 className="title is-6 is-flex is-align-items-center">
                Datos Personales
              </h4>
              <div className="columns is-multiline is-align-items-stretch">
                <div className="column is-7-desktop is-12-tablet">
                  <div className="box">
                    <CardHeader>
                      <div className="icon orange">
                        <i className="fas fa-user"></i>
                      </div>
                      <div className="data">
                        <div>{guarantorData?.name}</div>
                        <div className="has-text-grey">
                          {guarantorData?.birthdate}
                        </div>
                      </div>
                    </CardHeader>
                    <br />
                    <DataItem
                      field="Nacionalidad"
                      data={guarantorData?.nationality}
                      titleCase
                    />
                    <DataItem field="CURP" data={guarantorData?.curp} />
                    <DataItem field="RFC" data={guarantorData?.rfc} />
                    <DataItem
                      field="Lugar de nacimiento"
                      data={guarantorData?.birthPlace}
                      titleCase
                    />
                    <div className="my-2" />
                    <DataLabel label="Contacto" />
                    <IconData>
                      <div className="icon blue">
                        <i className="fas fa-user"></i>
                      </div>
                      <div className="data">{guarantorData?.email}</div>
                    </IconData>
                    <IconData>
                      <div className="icon red">
                        <i className="fas fa-phone"></i>
                      </div>
                      <div className="data">{guarantorData?.phone}</div>
                    </IconData>
                    <div className="my-2" />
                    <DataLabel label="Inmueble" />
                    <IconData>
                      <div className="icon blue">
                        <i className="fas fa-home"></i>
                      </div>
                      <div className="data">{guarantorProperty?.address}</div>
                    </IconData>
                    <IconData>
                      <div className="icon purple">
                        <i className="fas fa-gavel"></i>
                      </div>
                      <div className="data">
                        {guarantorProperty?.assessment === "no"
                          ? "Sin gravamen"
                          : guarantorProperty?.assessmentType}
                      </div>
                    </IconData>
                  </div>
                </div>
                {isBusiness && (
                  <div className="column is-5-desktop is-12-tablet">
                    <div className="box">
                      <CardHeader>
                        <div className="icon purple">
                          <i className="fas fa-building"></i>
                        </div>
                        <div className="data">
                          <div>{guarantorData?.businessName}</div>
                          <div className="has-text-grey">
                            Creada el {guarantorData?.businessDate}
                          </div>
                        </div>
                      </CardHeader>
                      <br />
                      <DataItem
                        field="Nacionalidad"
                        data={guarantorData?.businessNationality}
                        titleCase
                      />
                      <DataItem field="RFC" data={guarantorData?.businessRFC} />
                      <DataItem
                        field="Dirección"
                        data={guarantorData?.businessAddress}
                        titleCase
                      />
                      <div className="my-2" />
                      <DataLabel label="Contacto" />
                      <IconData>
                        <div className="icon red">
                          <i className="fas fa-phone"></i>
                        </div>
                        <div className="data">
                          {guarantorData?.businessPhone}
                        </div>
                      </IconData>
                      <div className="my-2" />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="column is-3">
              <h4 className="title is-6">Documentos</h4>
              <FileCard
                label="INE"
                files={guarantorData?.ineFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Comp. de domicilio"
                files={guarantorData?.domFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="CURP"
                files={guarantorData?.curpFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Acta consitutiva"
                files={guarantorData?.actFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Cédula fiscal"
                files={guarantorData?.fiscalFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Poder del Representante"
                files={guarantorData?.powerFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Escrituras"
                files={guarantorData?.houseDeedsFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="CLG"
                files={guarantorProperty?.clgFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Boleta Registral"
                files={guarantorData?.registrationTicketFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Predial"
                files={guarantorData?.predialPaymentFile}
                onOpenFile={openFilesModal}
              />
              <h4 className="title is-6">Validaciones</h4>
              <FileCard
                label="Validación INE"
                files={guarantorData?.ineValidationFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Validación CURP"
                files={guarantorData?.curpValidationFile}
                onOpenFile={openFilesModal}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
