import { FormatCurrency } from "components/FormatCurrency";
import React from "react";
import styled from "styled-components";

const AmmountCardStyle = styled.div`
  .label,
  .ammount-verified {
    font-weight: bold;
    text-transform: uppercase;
  }
  .label {
    color: #fc7753;
  }
  .ammount {
    color: #1e3959;
    font-weight: bold;
    font-size: 1.6rem;
  }
  .ammount-verified {
    color: #1e3959;
    font-weight: bold;
    font-size: 1rem;
  }
`;

export const AmmountCard = ({
  label,
  verifiedLabel = "COMPROBADO",
  ammount = 0,
  ammountVerified = 0,
}) => {
  return (
    <AmmountCardStyle className="box">
      <div className="label">{label}</div>
      <div className="ammount">
        <FormatCurrency value={ammount} />
      </div>
      <div className="label is-size-7 has-text-success">{verifiedLabel}</div>
      <div className="ammount-verified">
        <FormatCurrency value={ammountVerified} />
      </div>
    </AmmountCardStyle>
  );
};
