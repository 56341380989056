import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Navbar } from "../../../components/Navbar";
import AuthContext from "../../../services/authService/context";
import { Procedures } from "./Procedures";
import { NotificationContext } from "../../../services/NotificationService";
import { NotificationContainer } from "../../../components/NotificationContainer";
import {
  ConfirmationDialog,
  ConfirmationDialogContext,
} from "../../../components/ConfirmationDialog";
import ProcedureDetail from "./ProcedureDetail";
import GenPdf from "pages/Admin/Panel/GenPdf";
import axios from "axios";

export const Panel = (props) => {
  const [notifications, setNotifications] = useState([]);
  const { isPartners } = useContext(AuthContext);
  const [confirmationDialogData, setConfirmationDialogData] = useState(null);

  const createNotification = (data, action = null) => {
    let color = data?.error ? "is-danger" : "is-success";
    let id = Date.now();
    let newNotifications = notifications;

    newNotifications.push({
      id,
      message: data?.message,
      color,
    });

    setNotifications([...newNotifications]);
  };

  useEffect(() => {
    if (notifications.length !== 0) {
      var clearNotification = setTimeout(() => {
        let newNotifications = notifications;
        newNotifications.pop();
        setNotifications([...newNotifications]);
      }, 3000);
    }

    return () => {
      if (clearNotification) clearTimeout(clearNotification);
    };
  }, [notifications]);

  const apiErrorInterceptor = (message) => {
    createNotification({
      title: "Error",
      message: message,
      color: "is-danger",
    });
  };

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        apiErrorInterceptor("Ha ocurrido un error en el servidor");
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <NotificationContext.Provider
      value={{
        createNotification,
      }}
    >
      <ConfirmationDialogContext.Provider
        value={{
          showConfirmationDialog: (data) => setConfirmationDialogData(data),
        }}
      >
        <Navbar shadow={true} role={isPartners ? 99 : 1} />

        <Switch>
          <Redirect exact from="/panel" to="/panel/tramites" />
          <Route path="/panel/tramites/:id" component={ProcedureDetail} />
          <Route exact path="/panel/tramites" component={Procedures} />
          <Route path="/panel/tramite/descargar/:id" component={GenPdf} />
        </Switch>

        {confirmationDialogData != null && (
          <ConfirmationDialog
            {...confirmationDialogData}
            close={() => setConfirmationDialogData(null)}
          />
        )}

        <NotificationContainer>
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`notification box has-text-white ${notification.color} has-text-weight-bold is-size-6 my-2`}
            >
              {notification.message}
            </div>
          ))}
        </NotificationContainer>
      </ConfirmationDialogContext.Provider>
    </NotificationContext.Provider>
  );
};

export default Panel;
