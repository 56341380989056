import React from "react";
import { PersonalReferenceItem } from "../../PersonalReferenceItem";
import { StageNavbar } from "../../StageNavbar";

export const PersonalReferences = ({
  personalReferences,
  isBusiness,
  updateReferenceData,
  isCollapsed = false,
  collapse,
}) => {
  return (
    <div>
      <StageNavbar>
        <p
          className="is-size-5 has-text-weight-bold as-cursor no-selectable"
          onClick={() => collapse(!isCollapsed)}
        >
          <span className="icon">
            {isCollapsed ? (
              <i className="fas fa-caret-right"></i>
            ) : (
              <i className="fas fa-caret-down"></i>
            )}
          </span>
          <span>Referencias</span>
        </p>
      </StageNavbar>
      <br />
      {!isCollapsed && (
        <div>
          {personalReferences.map((reference, index) => (
            <div key={index}>
              <PersonalReferenceItem
                reference={reference}
                updateReferenceData={updateReferenceData}
                item={`Referencia ${index + 1}`}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
