import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import AuthContext from "../../services/authService/context";

export const NavStyle = styled.nav`
  box-shadow: ${({ shadow }) =>
    shadow ? "0px 5px 4px 1px #dcdcdc45" : "none"};

  &.panel-menu {
    z-index: 9;
    background: #20555b;

    a.navbar-item {
      &:not(.button) {
        color: white !important;
      }
      border-radius: 0px !important;
      transition: all 0.1s ease;
      &.is-active,
      &:hover {
      }
    }
  }
`;

export const Navbar = (props) => {
  const { role } = props;
  const [collapse, setCollapse] = useState("");
  const authContext = useContext(AuthContext);

  let isIndex = window.location.pathname === "/";

  return (
    <NavStyle className="navbar" shadow={props.shadow}>
      <a className="navbar-item no-hoverable" href="#!">
        <img
          src="/assets/logo.png"
          alt="Invix, la mejor plataforma de investigación de inquilinos."
          style={{ height: "50px", maxHeight: "50px" }}
        />
      </a>
      <div
        className={`navbar-burger burger ${collapse}`}
        data-target="invixNavbar"
        onClick={(e) =>
          collapse === "is-active" ? setCollapse("") : setCollapse("is-active")
        }
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div id="invixNavbar" className={`navbar-menu ${collapse}`}>
        <div className="navbar-start">
          {role === 0 && (
            <NavLink
              className="navbar-item"
              activeClassName="is-active"
              to="/panel/dashboard"
            >
              Inicio
            </NavLink>
          )}

          <NavLink
            className="navbar-item"
            activeClassName="is-active"
            to="/panel/tramites"
          >
            Investigaciones
          </NavLink>

          {role === 0 && (
            <NavLink
              className="navbar-item is-hidden"
              activeClassName="is-active"
              to="/panel/inmobiliarias"
            >
              Inmobiliarias
            </NavLink>
          )}
        </div>

        <div className="navbar-end">
          {role === 0 && (
            <div className="navbar-item">
              <NavLink
                className="navbar-link has-text-weight-bold"
                to="/panel/configuracion/usuarios"
              >
                Configuración
              </NavLink>
            </div>
          )}

          <div className="navbar-item">
            {authContext.authStatus === 2 ? (
              <div className="field is-grouped">
                {role !== 99 && (
                  <NavLink className="navbar-item" to="/panel/mi-cuenta">
                    Mi cuenta
                  </NavLink>
                )}
                {isIndex ? (
                  <NavLink
                    className="button navbar-item is-primary is-small"
                    to="/panel/mi-cuenta"
                  >
                    Ir a Invix
                  </NavLink>
                ) : (
                  <NavLink
                    className="navbar-item has-text-danger"
                    to="/cerrar-sesion"
                  >
                    Salir
                  </NavLink>
                )}
              </div>
            ) : (
              <div className="field is-grouped">
                <p className="control">
                  <NavLink className="button is-light" to="/crear-cuenta">
                    <span>Comenzar</span>
                  </NavLink>
                </p>
                <p className="control">
                  <NavLink className="button is-primary" to="/acceder">
                    <span>Acceder</span>
                  </NavLink>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </NavStyle>
  );
};

export const ProceduresNavBar = (props) => {
  const [collapse, setCollapse] = useState("");

  return (
    <NavStyle className="navbar panel-menu" shadow={true}>
      <div
        className={`navbar-burger burger ${collapse}`}
        data-target="proceduresNavBar"
        onClick={(e) =>
          collapse === "is-active" ? setCollapse("") : setCollapse("is-active")
        }
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div id="proceduresNavBar" className={`navbar-menu ${collapse}`}>
        <div className="navbar-start">
          <NavLink
            to="/panel/mis-tramites"
            className="navbar-item"
            activeClassName="is-active"
          >
            Mis trámites
          </NavLink>
          <NavLink
            to="/panel/informacion"
            className="navbar-item"
            activeClassName="is-active"
          >
            Información
          </NavLink>
          <NavLink
            to="/panel/pagos"
            className="navbar-item"
            activeClassName="is-active"
          >
            Pagos
          </NavLink>
        </div>

        <div className="navbar-end">
          <div className="navbar-item">
            <p className="control">
              <NavLink
                to="/panel/nuevo-tramite"
                className="button is-white is-outlined"
                activeClassName="is-active"
              >
                <span>Nuevo trámite</span>
                <span className="icon">
                  <i className="fas fa-plus"></i>
                </span>
              </NavLink>
            </p>
          </div>
        </div>
      </div>
    </NavStyle>
  );
};
