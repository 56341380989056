/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AuthStatus } from './context'

export const PrivateRoute = ({ component: Component, authStatus, ...rest }) => {
  return(
  <Route
    {...rest}
    render={(props) => ((authStatus === AuthStatus.Authenticated)
      ? <Component {...props} />
      : <Redirect to={{ pathname: '/', state: { from: props.location } }} />)}
  />
)}

export default PrivateRoute
