import React, { useEffect, useState } from "react";
import {
  VerticalBarSeries,
  XAxis,
  YAxis,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  GradientDefs,
} from "react-vis";
import { getProceduresAges } from "services/Dashboard";

export const AgesChart = () => {
  const [procedures, setProcedures] = useState([]);
  const [hoveredPoint, setHoveredPoint] = useState();
  const [isHover, setHover] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await getProceduresAges();
      const agesIitems = data.map((procedureAge) => {
        return {
          x: `${procedureAge._id}`,
          y: procedureAge.count,
        };
      });
      setProcedures(agesIitems.sort((a, b) => a?._id - b?._id));
    })();
  }, []);

  return (
    <div>
      <FlexibleWidthXYPlot xType="ordinal" stackBy="y" height={300}>
        <GradientDefs>
          <linearGradient id="metric-gradient" x1="0" x2="1" y1="0" y2="1">
            <stop offset="0%" stopColor="#3bbec8" stopOpacity={0.9} />
            <stop offset="100%" stopColor="#66d7d1" stopOpacity={0.9} />
          </linearGradient>
        </GradientDefs>
        <HorizontalGridLines style={{ strokeWidth: 1, stroke: "#66d7d140" }} />
        <XAxis
          tickFormat={(value, i) => {
            return value;
          }}
        />
        <YAxis
          tickFormat={(value, i) => {
            return value;
          }}
        />

        <VerticalBarSeries
          barWidth={0.7}
          data={procedures}
          color={"url(#metric-gradient)"}
          onSeriesMouseOver={(hoveredLine) => {
            setHover(true);
            setHoveredPoint(hoveredLine);
          }}
          onSeriesMouseOut={(hoveredLine) => {
            setHover(false);
            setHoveredPoint(hoveredLine);
          }}
          onNearestXY={(coords, { index, ...rest }) => {
            const hoveredLine = procedures[index];
            setHoveredPoint({
              ...hoveredLine,
              ...coords,
            });
          }}
        />
      </FlexibleWidthXYPlot>
    </div>
  );
};
