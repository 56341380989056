import React from "react";
import {
  ProcedureCountNew,
  ProcedureCount,
  ProcedureCountFinished,
  ProcedureCountOnProcess,
  ProcedureCountWithIssues,
} from "../../../components/DashboardComponents/ProcedureCount";
import { Padding } from "../../../components/Padding";
import { ProceduresThisYear } from "./ProceduresThisYear";
import { UsagesChart } from "./UsagesChart";
import { AgesChart } from "./AgesChart";

export const Dashboard = () => {
  return (
    <Padding padding="16px 30px">
      <h1 className="title">Dashboard</h1>
      <hr />
      <div className="columns">
        <div className="column is-8">
          <div className="tile is-ancestor">
            <div className="tile is-parent">
              <div className="tile is-child notification is-white box">
                <ProcedureCountNew />
              </div>
            </div>
            <div className="tile is-parent">
              <div className="tile is-child notification is-white box">
                <ProcedureCountOnProcess />
              </div>
            </div>
            <div className="tile is-parent">
              <div className="tile is-child notification is-white box">
                <ProcedureCountWithIssues />
              </div>
            </div>
            <div className="tile is-parent">
              <div className="tile is-child notification is-white box">
                <ProcedureCountFinished />
              </div>
            </div>
            <div className="tile is-parent">
              <div className="tile is-child notification is-white box">
                <ProcedureCount />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="columns is-multiline">
        <div className="column is-12">
          <div className="notification is-white box">
            <h1 className="title is-size-5">Investigaciones por edad</h1>
            <AgesChart />
          </div>
        </div>
        <div className="column is-6">
          <div className="notification is-white box">
            <ProceduresThisYear />
          </div>
        </div>
        <div className="column is-6">
          <div className="notification is-white box">
            <h1 className="title is-size-5">Porpósito de los inmuebles</h1>
            <UsagesChart />
          </div>
        </div>
      </div>
    </Padding>
  );
};
