export const BLUE_COLORS = [
  "#66d7d1",
  "#3bbec8",
  "#00a5bd",
  "#008cb1",
  "#0072a1",
  "#005a8f",
  "#00417a",
  "#062962",
];

export const CHART_SCALE_COLORS = [
  "#66d7d1",
  "#7bdcde",
  "#91e1e9",
  "#a7e5f1",
  "#bdeaf7",
  "#d1eefa",
  "#e3f3fc",
  "#b7d0e2",
  "#8eadc8",
  "#698aaf",
  "#476996",
  "#28487c",
  "#062962",
];

export const USAGES_CAHART_COLORS = [
  "#66d7d1",
  "#ffb5a0",
  "#fbd3c7",
  "#fc7753",
  "#8fded9",
  "#b2e4e1",
  "#d2ebe9",
  "#ff9779",
];
