import axios from "axios";
import { API } from "../../api";
export const API_VERSION = API.server;

export const SendPassword = (data) =>
  axios.post(`${API_VERSION}/partners/create-password/`, data);

export const CheckPartner = (data) =>
  axios.post(`${API_VERSION}/partners/check-partner/`, data);

export const AuthPartner = (data) =>
  axios.post(`${API_VERSION}/partners/auth/`, data);

export const PartnerProcedures = () =>
  axios.get(`${API_VERSION}/partners/procedures/`);

export const GetProcedure = (id) =>
  axios.get(`${API_VERSION}/partners/procedures/` + id);

export const GenerateQrPartnersLink = (procedureId) => axios.get(`${API_VERSION}/partners/qr-link/${procedureId}`)
