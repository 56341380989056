import React from "react";
import styled from "styled-components";

export const DataItem = ({ field, data, titleCase = false }) => {
  return (
    <div>
      {field?.length > 0 && <>{field} : </>}
      <span
        className={`has-text-weight-bold ${titleCase ? "is-capitalized	" : ""}`}
      >
        {data}
      </span>
    </div>
  );
};

const DataLabelStyle = styled.div`
  border-bottom: 2px solid;
  width: fit-content;
  font-size: 0.8rem;
  color: #537af9 !important;
`;
export const DataLabel = ({ label }) => {
  return (
    <DataLabelStyle className="is-uppercase has-text-weight-bold">
      {label}
    </DataLabelStyle>
  );
};

export const IconData = styled.div`
  display: flex;
  .icon {
    &.blue {
      color: #537afc !important;
    }
    &.red {
      color: #ff415b !important;
    }
    &.purple {
      color: #9738BA !important;
    }
  }
  .data {
    padding: 0px 10px;
  }
`;
