import { CardHeader } from "components/Card";
import { DataItem, DataLabel, IconData } from "components/DataItem";
import { FileCard } from "components/FileCard";
import { InvestigationContext } from "components/ProcedureDetail";

import React, { useContext } from "react";
import { PendingSecion } from "../PendingSection";

export const GeneralData = () => {
  const {
    renter: { generalData } = {},
    openFilesModal,
    isBusiness,
    loading,
  } = useContext(InvestigationContext);

  return loading ? (
    "Cargando"
  ) : (
    <>
      {!generalData?.fulfillment ? (
        <PendingSecion />
      ) : (
        <div>
          <h3 className="title is-5">Información General</h3>
          <div className="columns is-multiline">
            <div className="column is-9">
              <h4 className="title is-6 is-flex is-align-items-center">
                Datos Personales
              </h4>
              <div className="columns is-multiline is-align-items-stretch">
                <div className="column is-6-desktop is-12-tablet">
                  <div className="box">
                    <CardHeader>
                      <div className="icon orange">
                        <i className="fas fa-user"></i>
                      </div>
                      <div className="data">
                        <div>
                          {generalData?.firstName} {generalData?.secondName}{" "}
                          {generalData?.lastName1} {generalData?.lastName2}
                        </div>
                        <div className="has-text-grey	">
                          {generalData?.birthdate}
                        </div>
                      </div>
                    </CardHeader>
                    <br />
                    <DataItem field="CURP" data={generalData?.curp} />
                    <DataItem field="RFC" data={generalData?.rfc} titleCase />
                    <DataItem
                      field="Nació en"
                      data={generalData?.birthPlace}
                      titleCase
                    />
                    <DataItem
                      field="Estado civil"
                      data={generalData?.maritialStatus}
                      titleCase
                    />
                    <div className="my-2" />
                    {generalData?.maritialStatus !== "soltero" && (
                      <>
                        <DataLabel label="Cónyuge" />
                        <IconData>
                          <div className="icon blue">
                            <i className="fas fa-user"></i>
                          </div>
                          <div className="data">{generalData?.spouseName}</div>
                        </IconData>
                        <IconData>
                          <div className="icon red">
                            <i className="fas fa-phone"></i>
                          </div>
                          <div className="data">{generalData?.spousePhone}</div>
                        </IconData>
                      </>
                    )}
                  </div>
                </div>
                <div className="column is-6-desktop is-12-tablet">
                  <div className="box h-full">
                    <CardHeader>
                      <div className="icon blue">
                        <i className="fas fa-home"></i>
                      </div>
                      <div className="data">
                        <div>Domicilio Actual</div>
                        <div className="has-text-grey	">
                          <span className="is-capitalized">
                            {generalData?.houseStatus}
                          </span>{" "}
                          {generalData?.houseStatus === "rentado" && (
                            <>por ${generalData?.rentAmmount}</>
                          )}
                        </div>
                      </div>
                    </CardHeader>
                    <br />
                    <DataItem
                      field="Dirección"
                      data={`${generalData?.street} ${generalData?.number} ${generalData?.suburb} ${generalData?.postalCode} ${generalData?.city} ${generalData?.state}`}
                    />
                    <div className="my-4" />
                    {generalData?.maritialStatus !== "soltero" && (
                      <>
                        <DataLabel label="Dueño Actual" />
                        <IconData>
                          <div className="icon blue">
                            <i className="fas fa-user"></i>
                          </div>
                          <div className="data">{generalData?.houseOwner}</div>
                        </IconData>
                        <IconData>
                          <div className="icon red">
                            <i className="fas fa-phone"></i>
                          </div>
                          <div className="data">
                            {generalData?.houseContact}
                          </div>
                        </IconData>
                      </>
                    )}
                  </div>
                </div>
                {isBusiness && (
                  <div className="column is-6-desktop is-12-tablet">
                    <div className="box">
                      <CardHeader>
                        <div className="icon purple">
                          <i className="fas fa-building"></i>
                        </div>
                        <div className="data">
                          <div>{generalData?.businessName}</div>
                          <div className="has-text-grey	"></div>
                        </div>
                      </CardHeader>
                      <br />
                      <DataItem
                        field="Empresa"
                        data={generalData?.businessNationality}
                        titleCase
                      />
                      <DataItem
                        field="RFC"
                        data={generalData?.businessRfc}
                        titleCase
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="column is-3">
              <h4 className="title is-6">Documentos</h4>
              <FileCard
                label="INE"
                files={generalData?.ineFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Comp. de domicilio"
                files={generalData?.domFile}
                onOpenFile={openFilesModal}
              />
              {isBusiness && (
                <>
                  <FileCard
                    label="Acta(s) consitutivas"
                    files={generalData?.actFile}
                    onOpenFile={openFilesModal}
                  />
                  <FileCard
                    label="Poder del respresentante legal"
                    files={generalData?.powerFile}
                    onOpenFile={openFilesModal}
                  />
                  <FileCard
                    label="Cédula fiscal"
                    files={generalData?.fiscalFile}
                    onOpenFile={openFilesModal}
                  />
                </>
              )}
              <h4 className="title is-6">Validaciones</h4>
              <FileCard
                label="Validación INE"
                files={generalData?.ineValidationFile}
                onOpenFile={openFilesModal}
              />
              <FileCard
                label="Validación CURP"
                files={generalData?.curpValidationFile}
                onOpenFile={openFilesModal}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
