import React, { useRef, useState, useEffect, useContext } from "react";
import axios from "axios";
import { Input } from "components/Form";
import { statesNames } from "constants/states";
import { useFormik } from "formik";
import { API_VERSION } from "services/Partners";
import * as Yup from "yup";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { NotificationContext } from "services/NotificationService";

mapboxgl.accessToken =
  "pk.eyJ1IjoibWFudWVsOTYwNzIxIiwiYSI6ImNsenJ4eTVybzF5NTYyam9jeWpjMjFuZXkifQ.Pf6B9wwD0YjFE-yAwY6coQ";

export const NewPropertyForm = ({ property, onClose }) => {
  const { createNotification } = useContext(NotificationContext);
  const [loading, setLoading] = useState("");
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = new mapboxgl.Marker().setLngLat([
    property?.location?.coordinates[0] ?? 0,
    property?.location?.coordinates[1] ?? 0,
  ]);

  const [lng, setLng] = useState(-103.3352771);
  const [lat, setLat] = useState(20.6646446);
  const [coordinates, setCoordinates] = useState({});
  const [zoom, setZoom] = useState(9);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/manuel960721/clzry77gk00ix01pch24jeb6m",
      center: [lng, lat],
      zoom: zoom,
    });

    marker.addTo(map.current);

    map.current.on("click", (event) => {
      const coordinates = event.lngLat.wrap();
      setCoordinates({
        type: "Point",
        coordinates: [coordinates.lng, coordinates.lat],
      });
      marker.setLngLat([coordinates.lng, coordinates.lat]);
    });
  });

  const formik = useFormik({
    validationSchema: Yup.object({
      name: Yup.string().required("Campo requerido"),
      address: Yup.string().required("Campo requerido"),
      cp: Yup.string().required("Campo requerido"),
      colony: Yup.string().required("Campo requerido"),
      city: Yup.string().required("Campo requerido"),
      state: Yup.string().required("Campo requerido"),
      rentAmount: Yup.number().required("Campo requerido"),
    }),
    initialValues: {
      name: property?.name ?? "",
      address: property?.address ?? "",
      rentAmount: property?.rentAmount ?? "0",
      cp: property?.cp ?? "",
      colony: property?.colony ?? "",
      city: property?.city ?? "",
      state: property?.state ?? "",
    },
    onSubmit: (values) => {
      createUpdateProperty(values);
    },
  });

  const createUpdateProperty = async (values) => {
    setLoading("is-loading");
    if (property) {
      const { data } = await axios.put(
        `${API_VERSION}/partners/property/${property._id}`,
        {
          ...values,
          location: coordinates,
        }
      );
      setLoading("");
      createNotification({
        message: "Propiedad actualizada correctamente",
      });
      onClose(data);
    } else {
      const { data } = await axios.post(`${API_VERSION}/partners/property/`, {
        ...values,
        location: coordinates,
      });
      setLoading("");
      createNotification({
        message: "Propiedad actualizada correctamente",
      });
      onClose(data);
    }

    return;
  };

  return (
    <div className="notification overflow-auto" style={{ maxWidth: "800px" }}>
      <h1 className="title is-5">Agregar o editar propiedad</h1>
      <form onSubmit={formik.handleSubmit} className="columns is-multiline">
        <div className="field column is-6">
          <label className="label">Nombre</label>
          <div className="control">
            <Input
              className="input"
              type="text"
              placeholder="Nombre de la propiedad"
              name="name"
              helper="Forma fácil de identificar la propiedad"
              onChange={formik.handleChange}
              value={formik.values.name}
              error={formik.errors.address}
              showError={formik.errors.address}
            />
          </div>
        </div>
        <div className="field column is-6">
          <label className="label">Dirección</label>
          <div className="control">
            <Input
              className="input"
              type="text"
              name="address"
              helper="Calle y número"
              onChange={formik.handleChange}
              value={formik.values.address}
              error={formik.errors.address}
              showError={formik.errors.address}
            />
          </div>
        </div>
        <div className="field column is-4">
          <label className="label">Monto de renta</label>
          <div className="control">
            <Input
              id="rentAmount"
              className="input"
              type="number"
              name="rentAmount"
              onChange={formik.handleChange}
              value={formik.values.rentAmount}
              error={formik.errors.rentAmount}
              showError={formik.errors.rentAmount}
            />
          </div>
        </div>
        <div className="field column is-4">
          <label className="label">Código postal</label>
          <div className="control">
            <Input
              className="input"
              type="text"
              name="cp"
              onChange={formik.handleChange}
              value={formik.values.cp}
              error={formik.errors.cp}
              showError={formik.errors.cp}
            />
          </div>
        </div>
        <div className="field column is-4">
          <label className="label">Colonia</label>
          <div className="control">
            <Input
              className="input"
              type="text"
              name="colony"
              onChange={formik.handleChange}
              value={formik.values.colony}
              error={formik.errors.colony}
              showError={formik.errors.colony}
            />
          </div>
        </div>
        <div className="field column is-6">
          <label className="label">Ciudad o municipio</label>
          <div className="control">
            <Input
              className="input"
              type="text"
              name="city"
              onChange={formik.handleChange}
              value={formik.values.city}
              error={formik.errors.city}
              showError={formik.errors.city}
            />
          </div>
        </div>
        <div className="field column is-6">
          <label className="label">Estado</label>
          <div className="control">
            <div className="select is-fullwidth">
              <select
                id="state"
                value={formik.values.state}
                error={formik.errors.state}
                showError={formik.errors.state}
                onChange={formik.handleChange}
              >
                <option value="" disabled>
                  Selecciona un estado
                </option>
                {statesNames.map((s) => (
                  <option key={s} value={s}>
                    {s.toUpperCase()}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <p className="help is-danger">{formik.errors.state}</p>
        </div>

        <h1 className="title is-size-6 mt-2">
          Has clic en el mapa para marcar la ubicación exacta
        </h1>

        <div
          ref={mapContainer}
          className="map-container "
          style={{ height: "300px", width: "100%" }}
        />

        <div className="column is-full buttons mt-6 is-flex is-justify-content-end">
          <button className="button" type="button" onClick={() => onClose()}>
            Cancelar
          </button>
          <button className={`button is-primary ${loading}`} type="submit">
            {property ? "Actualizar" : "Crear"}
          </button>
        </div>
      </form>
    </div>
  );
};
