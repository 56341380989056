import React from 'react'

export const FullScreenLoader = (props) => {
  return (
    <div>
      Cargando...
    </div>
  )
}


export const LoaderItem = ({ isLoading, children: Component }) =>  isLoading ? 'Cargando...' : Component