import axios from 'axios';
import { API } from '../../api';

const API_VERSION = API.server

export const AuthUser = (data) => {
  return axios.post(`${API_VERSION}/staff/auth`, data)
}

export const CreateStaff = (data) => axios.post(`${API_VERSION}/staff`, data);
export const UpdateStaff = (data, id) => axios.put(`${API_VERSION}/staff/${id}`, data);

/**
 * Log out
 */
export const Logout = () => {
  delete axios.defaults.headers.common.Authorization
  localStorage.clear();
}

/**
 * @param {String} token
 * Set auth header to all requests
 */
export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

export const setRedirectionOnExpire = () => {
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error === 'Error: Request failed with status code 401') {
      localStorage.clear();
      window.location.href = '/?login=expired'
    }
    return Promise.reject(error);
  });
}

/**
 * @param {String} token
 * Save token
 */
export const setToken = (token) => {
  localStorage.setItem('token', token)
  setAuthToken(token);
}

/**
 * @param {JSON} user
 * Ssave the current user on local storage
 */
export const setCurrentUser = user => {
  localStorage.setItem('user', JSON.stringify(user))
}

export const GetStaff = () => axios.get(`${API_VERSION}/staff`)

export const ChangePassword = (data) => axios.post(`${API_VERSION}/staff/change-password`, data);
export const ResetPassword = (data) => axios.post(`${API_VERSION}/staff/reset-password`, data);