import React, { useContext, useEffect, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Navbar } from "../../../components/Navbar";
import { ProcedureDetail } from "../../../components/ProcedureDetail";
import AuthContext from "../../../services/authService/context";
import { Configuration } from "./Configuration";
import { GenPdf } from "./GenPdf";
import { Dashboard } from "../Dashboard";
import { Procedures } from "./Procedures";
import { NotificationContext } from "../../../services/NotificationService";
import { NotificationContainer } from "../../../components/NotificationContainer";
import {
  ConfirmationDialog,
  ConfirmationDialogContext,
} from "../../../components/ConfirmationDialog";
import Account from "../Account";

export const Panel = (props) => {
  const [notifications, setNotifications] = useState([]);
  const {
    currentUser: { role },
  } = useContext(AuthContext);
  const [confirmationDialogData, setConfirmationDialogData] = useState(null);

  const RedirectUser = () =>
    role === 0 ? "/panel/dashboard" : "/panel/tramites/inicio";

  const createNotification = (data, action = null) => {
    let color = data?.error ? "is-danger" : "is-success";
    let id = Date.now();
    let newNotifications = notifications;

    newNotifications.push({
      id,
      message: data?.message,
      color,
    });

    setNotifications([...newNotifications]);
  };

  useEffect(() => {
    if (notifications.length !== 0) {
      var clearNotification = setTimeout(() => {
        let newNotifications = notifications;
        newNotifications.pop();
        setNotifications([...newNotifications]);
      }, 3000);
    }

    return () => {
      if (clearNotification) clearTimeout(clearNotification);
    };
  }, [notifications]);

  return (
    <NotificationContext.Provider
      value={{
        createNotification,
      }}
    >
      <ConfirmationDialogContext.Provider
        value={{
          showConfirmationDialog: (data) => setConfirmationDialogData(data),
        }}
      >
        <Navbar shadow={true} role={role} />

        <Switch>
          <Redirect exact from="/panel" to={RedirectUser()} />
          {role === 0 ? (
            <>
              <Route path="/panel/dashboard" component={Dashboard} />
              <Route exact path="/panel/tramites" component={Procedures} />
              <Route
                exact
                path="/panel/tramites/:id"
                component={ProcedureDetail}
              />
              <Route path="/panel/configuracion" component={Configuration} />
              <Route
                path="/panel/tramite/detalle/:id"
                component={ProcedureDetail}
              />
              <Route path="/panel/tramite/descargar/:id" component={GenPdf} />
              <Route path="/panel/mi-cuenta" component={Account} />
            </>
          ) : (
            <>
              <Route path="/panel/tramites" component={Procedures} />
              <Route path="/panel/tramite/descargar/:id" component={GenPdf} />
              <Route
                path="/panel/tramite/detalle/:id"
                component={ProcedureDetail}
              />
              <Route path="/panel/mi-cuenta" component={Account} />
            </>
          )}
        </Switch>

        {confirmationDialogData != null && (
          <ConfirmationDialog
            {...confirmationDialogData}
            close={() => setConfirmationDialogData(null)}
          />
        )}

        <NotificationContainer>
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`notification box has-text-white ${notification.color} has-text-weight-bold is-size-6`}
            >
              {notification.message}
            </div>
          ))}
        </NotificationContainer>
      </ConfirmationDialogContext.Provider>
    </NotificationContext.Provider>
  );
};
