import React from 'react'
import { InfoItem } from '../InfoItem';

export const RegisterCallDetail = ({ call }) => {

  var sec_num = parseInt(call.duration);
  var hours   = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
  var seconds = sec_num - (hours * 3600) - (minutes * 60);

  if (hours   < 10) {hours   = "0"+hours;}
  if (minutes < 10) {minutes = "0"+minutes;}
  if (seconds < 10) {seconds = "0"+seconds;}
  return (
    <div className="notification" >
      <p className="title is-3 has-text-centered">
        Detalle de la llamada
      </p>
        <InfoItem field="Duración de la llamada" value={`${hours}:${minutes}:${seconds}`} labelSize="200px"/>
        <InfoItem field="¿Contestó?" value={call.answered? 'Si':'No'} labelSize="200px"/>
        <InfoItem field="Notas" value={call.notes} labelSize="200px"/>
        <InfoItem field="¿Conoce al referido?" value={call.known} labelSize="200px"/>
        <InfoItem field="Tiempo de conocerle" value={call.meetTime} labelSize="200px"/>
        <InfoItem field="Relación con el referido" value={call.relationship} labelSize="200px"/>
        <InfoItem field="¿Lo considera una persona responsable?" value={call.isResponsible} labelSize="200px"/>
        <InfoItem field="¿Sabe cuál es su activad laboral?" value={call.jobActivity} labelSize="200px"/>
        <InfoItem field="¿Lo considera solvente económicamente ?" value={call.financialSolvency} labelSize="200px"/>
        <InfoItem field="¿Lo recomienda como inquilino ?" value={call.recomended} labelSize="200px"/>
    </div> 
  )
}
