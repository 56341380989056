import React from "react";
import { ProcedureListItem } from "./ProcedureListItem";

export const ProceduresList = ({ procedures, createNewProcedure }) => {
  return (
    <>
      <div className="columns">
        <div className="column is-2 has-text-weight-medium secondary-text is-size-7">
          Fecha
        </div>
        <div className="column is-4 has-text-weight-medium secondary-text is-size-7">
          Nombre
        </div>
        <div className="column is-1 has-text-weight-medium secondary-text is-size-7">
          Tipo
        </div>
        <div className="column is-2 has-text-weight-medium secondary-text is-size-7">
          Estatus
        </div>
        <div className="column is-2 has-text-weight-medium secondary-text is-size-7">
          Etiquetas
        </div>
        <div className="column is-1 has-text-weight-medium secondary-text is-size-7">
          Acciones
        </div>
      </div>
      {procedures?.map((procedure) => (
        <ProcedureListItem
          key={procedure?._id}
          procedure={procedure}
          createNewProcedure={createNewProcedure}
        />
      ))}
    </>
  );
};
