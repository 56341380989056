export const PROCEDURE_TYPES = {
  REGULAR: "Regular",
  CLG: "CLG",
};

export const PROPERY_USAGES = [
  "Casa habitación",
  "Comercial",
  "Oficina",
  "Industrial",
  "Bodega",
  "Agricola",
  "Mixto",
  "Otro",
];

export const PROPERY_USAGES_ICONS = [
  "fa-home",
  "fa-store",
  "fa-building",
  "fa-industry",
  "fa-warehouse",
  "fa-tractor",
  "fa-tasks",
  "fa-question",
];

export const STATUS = {
  "-1": {
    label: "SIN CREAR",
    classes: "tag is-danger is-pointer",
  },
  0: {
    label: "CREADA",
    classes: "tag is-link is-pointer",
  },
  1: {
    label: "EN PROCESO",
    classes: "tag is-warning is-pointer",
  },
  2: {
    label: "CON OBSERVACIONES",
    classes: "tag is-danger is-pointer",
  },
  3: {
    label: "COMPLETADA",
    classes: "tag is-success is-pointer",
  },
  4: {
    label: "RECHAZADA",
    classes: "tag is-danger is-pointer",
  },
};
