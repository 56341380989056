import axios from "axios";
import { API } from "../../api";

export const API_VERSION = API.server;

export const getProceduresThisYear = (year) =>
  axios.get(`${API_VERSION}/dashboard/procedures/${year}`);

export const getProceduresAges = () =>
  axios.get(`${API_VERSION}/dashboard/user-data/ages`);

export const getProceduresUsages = () =>
  axios.get(`${API_VERSION}/dashboard/procedure-usages`);
