export const reorderItems = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const FIELD_TYPES = {
  TEXT: "text",
  NUMBER: "number",
};

export const FIELD_NAMES = {
  text: "TEXTO",
  number: "NÚMERO",
  select: "SELECCIÓN",
  radio: "SELECCIÓN MULTI",
  date: "FECHA",
  file: "ARCHIVOS",
};

export const FIELDS_TEMPLATES = {
  text: {
    type: "text",
    id: "campo_de_texto",
    label: "Campo de texto",
    placeholder: "Campo de texto",
    helpText: "",
    required: false,
    showIf: "",
    hideIf: "",
  },
  number: {
    type: "number",
    id: "campo_de_numerico",
    label: "Campo númerico",
    placeholder: "Campo de númerico",
    min: 0,
    max: 0,
    helpText: "",
    required: false,
    showIf: "",
    hideIf: "",
  },
};
