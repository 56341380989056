import { createContext } from 'react';

export const NotificationContext = createContext(null);

export const NotificationNames = {
  informationCompleted: 'informationCompletedNotification'
}

export const DisableNotification = (notificationName) => localStorage.setItem(notificationName, false)

export const GetNotificationConfig = (notificationName) => JSON.parse(localStorage.getItem(notificationName))
